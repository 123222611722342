import { FC, Fragment, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequestState } from 'constants/APIState'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import Loading from 'components/Loading/Loading'
import ProductListing from './ProductListing'
import { ShootingCategory } from 'types/ProductCategory'
import scrollIntoViewOnRefChange from 'lib/scrollIntoViewOnRefChange'
import styles from './ProductSelection.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useProductConfigurationContext } from 'contexts/ProductConfigurationContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useUserContext } from 'contexts/UserContext'

const ProductSelection: FC = ({
  children,
}) => {
  const { t } = useTranslation(['order', 'onoffice'])
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const { selectedProductSegmentKey, selectProductSegment, selectedProductTypes, toggleProductType, availableProductTypes, productSelection } = useProductSelectionContext()
  const { productConfigurationLoaded, productConfiguration, productConfigurationAPIRequestState, availableProductSegments } = useProductConfigurationContext()
  const selectedSegmentIndex = useMemo(() => availableProductSegments.findIndex(segment => segment === selectedProductSegmentKey), [availableProductSegments, selectedProductSegmentKey])
  const onRefChange = useCallback(scrollIntoViewOnRefChange, [])

  const whenProductConfigurationNotLoaded = useMemo(() => {
    switch (productConfigurationAPIRequestState) {
      case APIRequestState.RUNNING:
        return (
          <Loading>
            <span>{t('onoffice:loading_state_RUNNING', { subject: t('onoffice:selection_product') })}</span>
          </Loading>
        )
      case APIRequestState.ERROR:
        return (
          <AlertMessage>
            <span>{t('onoffice:loading_state_ERROR', { subject: t('onoffice:selection_product') })}</span>
          </AlertMessage>
        )
      case APIRequestState.BEFORE_START:
      case APIRequestState.COMPLETE:
      default:
        return <Fragment></Fragment>
    }
  }, [t, productConfigurationAPIRequestState])

  if (!productConfigurationLoaded || !productConfiguration) return (
    <Fragment>
      {whenProductConfigurationNotLoaded}
    </Fragment>
  )

  if (!userProfile) return (
    <AlertMessage fullPage>
      <h1>{t('onoffice:missing_user')}</h1>
    </AlertMessage>
  )

  if (!platformPlace || !addressIsValid) return <Fragment></Fragment>

  return (
    <div className={styles.ProductSelection}>
      <hr />
      <Fragment>
        <h2>2. {t('step_product.title')}</h2>
        <p>{t('step_product.text', { segment: t(`segment_title:${ShootingCategory.REAL_ESTATE}`).toLocaleLowerCase() })}</p>
      </Fragment>
      {availableProductSegments.length === 0 ?
        <h2 className="gray-text empty-title">{t('step_product.none_for_country')}</h2>
        :
        <Fragment>
          <section className={styles.ProductSegment}>
            <h3>{t(`segment_title:${ShootingCategory.REAL_ESTATE}`)}</h3>
            <span className={styles.SegmentLabels}>
              <span>
                <Trans t={t} i18nKey={`segment:${availableProductSegments[0]}`}>
                  <sup></sup>
                </Trans>
              </span>
              <span>
                <Trans t={t} i18nKey={`segment:${availableProductSegments[availableProductSegments.length - 1]}`}>
                  <sup></sup>
                </Trans>
              </span>
            </span>
            <input
              type="range"
              name="range"
              min={-1}
              max={availableProductSegments.length - 1}
              step={1}
              value={selectedSegmentIndex}
              onChange={e => {
                const index = parseInt(e.target.value)
                if (typeof availableProductSegments[index] === 'undefined') return selectProductSegment(undefined)
                const segment = availableProductSegments[index]
                selectProductSegment(segment)
              }}
            />
            <strong className={styles.SegmentNumber}>
              {selectedProductSegmentKey ?
                <Trans t={t} i18nKey={`segment:${selectedProductSegmentKey}`}>
                  <sup></sup>
                </Trans>
                :
                <span className={styles.Red}>
                  {t('step_product.none_selected')}
                </span>
              }
            </strong>
          </section>
          {!!selectedProductSegmentKey &&
            <div ref={onRefChange}>
              {availableProductTypes.length === 0 ?
                <h2 className="gray-text empty-title">{t('step_product.none_for_country')}</h2>
                :
                <section className={styles.ProductType}>
                  <h3>{t('step_product.product_types')}</h3>
                  <div className={styles.ProductTypeWrap}>
                    {availableProductTypes.map(productTypeKey => {
                      return (
                        <div key={productTypeKey} className={styles.ProductTypeIndividual}>
                          <label
                            className="checkbox square"
                            htmlFor={`product_type_${productTypeKey}_check`}
                          >
                            <input
                              type="checkbox"
                              name={`product_type_${productTypeKey}_check`}
                              id={`product_type_${productTypeKey}_check`}
                              checked={selectedProductTypes.has(productTypeKey)}
                              onChange={() => {
                                toggleProductType(productTypeKey)
                              }}
                            />
                            <span className="checkmark"></span>
                            <span className="label-after">{t(`product_type:${productTypeKey}`)}</span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </section>
              }
              {selectedProductTypes.size > 0 && !!productSelection &&
                <div ref={onRefChange}>
                  <ProductListing />
                </div>
              }
            </div>
          }
        </Fragment>
      }
      {children}
    </div>
  )
}

export default ProductSelection