import * as t from 'io-ts'

export const CustomerDTO = t.type({
  apiClaim: t.string,
  customerName: t.string,
  customerWebId: t.string,
  userId: t.string,
  company: t.string,
  country: t.string,
})
export type CustomerDTO = t.TypeOf<typeof CustomerDTO>

export const UserProfileDTO = t.type({
  id: t.string,
  name: t.string,
  email: t.string,
  phone: t.string,
  country: t.string,
  language: t.string,
  defaultTimezone: t.string,
  organizationId: t.union([t.string, t.null]),
})
export type UserProfileDTO = t.TypeOf<typeof UserProfileDTO>