import * as t from 'io-ts'

/** Product Segment enum - Describes product segment (all, area, duration, etc...) */
export enum ProductSegment {
  // All
  all = 'all',

  // Area
  m0000_0099 = 'm0000_0099',
  m0100_0199 = 'm0100_0199',
  m0200_0299 = 'm0200_0299',
  m0300_0399 = 'm0300_0399',
  m0400_0499 = 'm0400_0499',
  m0500_0599 = 'm0500_0599',
  m0600_0699 = 'm0600_0699',
  m0700_0799 = 'm0700_0799',
  m0800_0899 = 'm0800_0899',
  m0900_0999 = 'm0900_0999',
  m1000_p = 'm1000_p',

  // Duration
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
  h8 = 'h8',
  h8_p = 'h8_p',
}
export const ProductSegmentType = t.keyof(ProductSegment)
export type ProductSegmentType = t.TypeOf<typeof ProductSegmentType>