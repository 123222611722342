import { baseLocalAPIURL, baseRemoteAPIURL } from './../constants/URLs'

import { Endpoints } from './../constants/Endpoints'

/** Builds remote API URL for given endpoint */
export const getRemoteAPIURL = (endpoint: Endpoints) => {
  return `${baseRemoteAPIURL}${endpoint}`
}

/** Builds local API URL for given endpoint */
export const getLocalAPIURL = (endpoint: Endpoints) => {
  return `${baseLocalAPIURL}${endpoint}`
}