import React, { FC, Fragment, useMemo } from 'react'

import { APIRequestState } from 'constants/APIState'
import AddressSelection from '../AddressSelection/AddressSelection'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import InstructionSelection from '../InstructionSelection/InstructionSelection'
import Layout from 'components/Layout/Layout'
import Loading from 'components/Loading/Loading'
import OrderPlacement from '../OrderPlacement/OrderPlacement'
import ProductSelection from '../ProductSelection/ProductSelection'
import Summary from '../Summary/Summary'
import styles from './PurchaseFlow.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInitialQueryContext } from 'contexts/InitialQueryContext'
import { useInstructionSelectionContext } from 'contexts/InstructionSelectionContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useTranslation } from 'react-i18next'
import { useUserContext } from 'contexts/UserContext'

const PurchaseFlow: FC = ({
  children,
}) => {
  const { t } = useTranslation('onoffice')
  const { initialQueryParams, invalidInitialQueryParamsReport } = useInitialQueryContext()
  const { userProfile, userProfileAPIRequestState } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const { productSelectionList } = useProductSelectionContext()
  const { allRequiredInstructionsFilled } = useInstructionSelectionContext()

  const whenUserProfileNotLoaded = useMemo(() => {
    switch (userProfileAPIRequestState) {
      case APIRequestState.BEFORE_START:
        return (
          <Loading fullPage>
            <span>{t('loading_state_BEFORE_START', { subject: t('user_profile') })}</span>
          </Loading>
        )
      case APIRequestState.RUNNING:
        return (
          <Loading fullPage>
            <span>{t('loading_state_RUNNING', { subject: t('user_profile') })}</span>
          </Loading>
        )
      case APIRequestState.ERROR:
        return (
          <AlertMessage fullPage>
            <span>{t('loading_state_ERROR', { subject: t('user_profile') })}</span>
          </AlertMessage>
        )
      case APIRequestState.COMPLETE:
      default:
        return <Fragment></Fragment>
    }
  }, [t, userProfileAPIRequestState])

  return (
    <Fragment>
      {!initialQueryParams ?
        <AlertMessage fullPage>
          <h1>{t('invalid_params')}</h1>
          {invalidInitialQueryParamsReport?.map(line => (
            <Fragment key={line}>
              <br />
              <p>{line}</p>
            </Fragment>
          ))}
        </AlertMessage>
        :
        <Fragment>
          {!userProfile ?
            <Fragment>
              {whenUserProfileNotLoaded}
            </Fragment>
            :
            <Layout>
              <div className={styles.PurchaseFlow}>
                <AddressSelection />
                {!!(platformPlace && addressIsValid) &&
                  <Fragment>
                    <ProductSelection />
                    {productSelectionList.length > 0 &&
                      <Fragment>
                        <InstructionSelection />
                        {allRequiredInstructionsFilled &&
                          <Fragment>
                            <Summary />
                            <OrderPlacement />
                            {children}
                          </Fragment>
                        }
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>
            </Layout>
          }
        </Fragment>
      }
    </Fragment>
  )
}

export default PurchaseFlow