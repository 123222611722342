import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { InstructionConfigurationDTO, InstructionConfigurationRequestBody } from 'types/InstructionConfiguration'

import { APIRequestState } from 'constants/APIState'
import { Currency } from 'constants/Currency'
import { Endpoints } from 'constants/Endpoints'
import { InstructionType } from 'types/InstructionType'
import { ShootingCategory } from 'types/ProductCategory'
import axios from 'axios'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from 'lib/API'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useUserContext } from 'contexts/UserContext'

/** Type describing the shape of context value */
export interface InstructionConfigurationContextType {
  /** Indicates the state of instruction configuration request */
  instructionConfigurationAPIRequestState: APIRequestState
  /** Contains the whole platform instruction configuration DTO */
  instructionConfiguration: InstructionConfigurationDTO | undefined
  /** Indicates if instruction configuration is being loaded */
  instructionConfigurationLoading: boolean
  /** Indicates if instruction configuration was successfully loaded */
  instructionConfigurationLoaded: boolean
  /** Indicates if instruction configuration failed to load */
  instructionConfigurationFailed: boolean
  /** List of all available instruction segments */
  availableInstructionTypes: InstructionType[]
  /** Currency of the products */
  currency?: Currency
  /** Timezone of the location */
  locationTimezone?: string
}

/** The default context value */
export const defaultInstructionConfigurationContext: InstructionConfigurationContextType = {
  instructionConfigurationAPIRequestState: APIRequestState.BEFORE_START,
  instructionConfiguration: undefined,
  instructionConfigurationLoading: false,
  instructionConfigurationLoaded: false,
  instructionConfigurationFailed: false,
  availableInstructionTypes: [],
}

/** Context containing all instruction configuration related data */
export const InstructionConfigurationContext = createContext<InstructionConfigurationContextType>(defaultInstructionConfigurationContext)

/** Hook for instruction configuration context */
export const useInstructionConfigurationContext = (): InstructionConfigurationContextType => useContext(InstructionConfigurationContext)

/** Provider for InstructionConfigurationContext which calls instruction configuration endpoint upon mounting */
export const InstructionConfigurationContextProvider: FC = ({
  children,
}) => {
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const [instructionConfigurationAPIRequestState, setInstructionConfigurationAPIRequestState] = useState<InstructionConfigurationContextType['instructionConfigurationAPIRequestState']>(APIRequestState.BEFORE_START)
  const [instructionConfiguration, setInstructionConfiguration] = useState<InstructionConfigurationContextType['instructionConfiguration']>(undefined)

  const instructionConfigurationLoading = useMemo(() => instructionConfigurationAPIRequestState === APIRequestState.RUNNING, [instructionConfigurationAPIRequestState])
  const instructionConfigurationLoaded = useMemo(() => instructionConfigurationAPIRequestState === APIRequestState.COMPLETE && !!instructionConfiguration, [instructionConfigurationAPIRequestState, instructionConfiguration])
  const instructionConfigurationFailed = useMemo(() => instructionConfigurationAPIRequestState === APIRequestState.ERROR, [instructionConfigurationAPIRequestState])
  const availableInstructionTypes = useMemo(() => instructionConfigurationLoaded && instructionConfiguration ? Object.values(instructionConfiguration.instructionTypes).map(type => InstructionType[type.key]).sort() : [], [instructionConfigurationLoaded, instructionConfiguration])

  /** Fetch instruction configuration */
  useEffect(() => {
    const fetchInstructionConfiguration = async () => {
      if (!userProfile) return
      if (!platformPlace) return
      if (!addressIsValid) return

      setInstructionConfigurationAPIRequestState(APIRequestState.BEFORE_START)

      const { email } = userProfile
      const body: InstructionConfigurationRequestBody = {
        email,
        countryCode: platformPlace.countryCode,
        category: ShootingCategory.REAL_ESTATE,
        coordinates: platformPlace.coordinate,
      }

      // Validate type of request body
      const decodedBody = InstructionConfigurationRequestBody.decode(body)
      pipe(
        decodedBody,
        fold(
          errors => {
            const report = reporter.report(decodedBody)
            console.error(report, errors)
            setInstructionConfiguration(undefined)
            setInstructionConfigurationAPIRequestState(APIRequestState.ERROR)
          },
          // Call API with validated request body
          async validatedBody => {
            try {
              setInstructionConfigurationAPIRequestState(APIRequestState.RUNNING)
              const URL = getRemoteAPIURL(Endpoints.ORDER_CONFIGURATION_INSTRUCTION)
              const response = await axios.post<InstructionConfigurationDTO>(URL, validatedBody)

              /** Validate type of response data */
              const decodedResponseData = InstructionConfigurationDTO.decode(response.data)
              pipe(
                decodedResponseData,
                fold(
                  errors => {
                    const report = reporter.report(decodedResponseData)
                    console.error(report, errors)
                    setInstructionConfiguration(undefined)
                    setInstructionConfigurationAPIRequestState(APIRequestState.ERROR)
                  },
                  // Set result
                  validatedResponseData => {
                    setInstructionConfiguration(validatedResponseData)
                    setInstructionConfigurationAPIRequestState(APIRequestState.COMPLETE)
                  }
                )
              )
            } catch (error) {
              console.error(error)
              setInstructionConfiguration(undefined)
              setInstructionConfigurationAPIRequestState(APIRequestState.ERROR)
            }
          }
        )
      )
    }
    fetchInstructionConfiguration()
  }, [userProfile, platformPlace, addressIsValid])

  const currency = useMemo(() => instructionConfiguration ? Currency[instructionConfiguration.currency] : undefined, [instructionConfiguration])
  const locationTimezone = useMemo(() => instructionConfiguration?.timezone, [instructionConfiguration])

  return (
    <InstructionConfigurationContext.Provider
      value={{
        instructionConfigurationAPIRequestState,
        instructionConfiguration,
        instructionConfigurationLoading,
        instructionConfigurationLoaded,
        instructionConfigurationFailed,
        availableInstructionTypes,
        currency,
        locationTimezone,
      }}
    >
      {children}
    </InstructionConfigurationContext.Provider>
  )
}