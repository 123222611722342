import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'

import { Endpoints } from 'constants/Endpoints'
import { Language } from 'constants/Language'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import I18NextHttpBackend from 'i18next-http-backend'
import I18NextMultiloadBackendAdapter from 'i18next-multiload-backend-adapter'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { initReactI18next } from 'react-i18next'
import { getRemoteAPIURL } from './API'
import { unflattenObject } from './JSONUtils'
import { coerceLanguage } from './coerceLanguage'

/** Copy moment locale configs to moment-timezone */
for (const locale of moment.locales().filter(locale => !momentTZ.locales().includes(locale))) {
  momentTZ.defineLocale(locale, (moment.localeData(locale) as any)._config)
}

/** i18n initialization object */
export default i18n

/** Whether the i18n library was already initialized */
export let i18nAlreadyInitialized = false

/** Initialize i18n library */
export const initI18n = async () => {
  await i18n
    .use(LanguageDetector) // detect user language
    .use(I18NextHttpBackend) // loads resources from the api
    .use(I18NextMultiloadBackendAdapter) // allows backend multiloading
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      whitelist: Object.values(Language).map(lang => lang.toLowerCase()),
      nonExplicitWhitelist: true,
      fallbackLng: ['en', 'fr', 'de', 'es', 'nl', 'it'],
      // debug: true,
      // keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false // react already safes from xss
      },

      react: {
        useSuspense: false,
      },

      detection: {
        lookupQuerystring: 'lang',
        caches: ['localStorage'],
      },

      load: 'languageOnly',
      backend: {
        backend: I18NextHttpBackend,
        allowMultiLoading: true,
        backendOption: {
          loadPath: () => `${getRemoteAPIURL(Endpoints.TRANSLATIONS_ALL)}?language=${coerceLanguage(i18n.language)}`, // Must be function so the i18n language is already set by Detector middleware when executing it
          parse: (data: string) => {
            const result = JSON.parse(data)
            for (const key in result) result[key] = unflattenObject(result[key])
            return result
          },
          allowMultiLoading: true,
        }
      } as any, // backend options => types are incompatible between versions, needs to be adjusted in the future
    })
  i18nAlreadyInitialized = true
}