import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { GoogleAPIPlace, Place } from 'types/AddressSelection'

import cloneDeep from 'lodash/cloneDeep'
import { isAddressValid } from 'lib/addressValidator'

/** Type describing the shape of context value */
export interface AddressSelectionContextType {
  setPlace: (place: GoogleAPIPlace | null) => void
  googlePlace: GoogleAPIPlace | null,
  platformPlace?: Place
  addressIsValid: boolean
  resetAddressSelection: () => void
}

/** The default context value */
export const defaultAddressSelectionContext: AddressSelectionContextType = {
  setPlace: () => { throw new Error('setPlace is undefined') },
  googlePlace: null,
  platformPlace: undefined,
  addressIsValid: false,
  resetAddressSelection: () => { throw new Error('resetAddressSelection is undefined') },
}

/** Context containing all address selection related data */
export const AddressSelectionContext = createContext<AddressSelectionContextType>(defaultAddressSelectionContext)

/** Hook for address selection context */
export const useAddressSelectionContext = (): AddressSelectionContextType => useContext(AddressSelectionContext)

/** Provider for AddressSelectionContext */
export const AddressSelectionContextProvider: FC = ({
  children,
}) => {
  const [googlePlace, setGooglePlace] = useState<AddressSelectionContextType['googlePlace']>(null)
  const [platformPlace, setPlatformPlace] = useState<AddressSelectionContextType['platformPlace']>(undefined)
  const addressIsValid = useMemo(() => isAddressValid(googlePlace), [googlePlace])

  /** creates the country code string from google place object */
  const getCountryCodeFromGooglePlace = useCallback((googlePlaceObject: GoogleAPIPlace | null) => {
    if (!googlePlaceObject) return ''
    for (const component of googlePlaceObject.address_components) {
      for (const type of component.types) {
        if (type === 'country') return component.short_name
      }
    }
    return ''
  }, [])

  /** creates the redux store place object from google place object */
  const createPlaceFromGooglePlace = useCallback((googlePlaceObject: GoogleAPIPlace | null) => {
    if (!googlePlaceObject) return undefined
    const place: Place = {
      address: googlePlaceObject.formatted_address,
      coordinate: {
        lat: googlePlaceObject.geometry.location.lat,
        lng: googlePlaceObject.geometry.location.lng,
      },
      countryCode: getCountryCodeFromGooglePlace(googlePlaceObject)
    }
    return place
  }, [getCountryCodeFromGooglePlace])

  const setPlace = useCallback((place: GoogleAPIPlace | null) => {
    const clonedGooglePlace = cloneDeep(place)
    setGooglePlace(clonedGooglePlace)
    setPlatformPlace(cloneDeep(createPlaceFromGooglePlace(clonedGooglePlace)))
  }, [createPlaceFromGooglePlace])

  const resetAddressSelection = useCallback(() => {
    setGooglePlace(null)
    setPlatformPlace(undefined)
  }, [])

  return (
    <AddressSelectionContext.Provider
      value={{
        setPlace,
        googlePlace,
        platformPlace,
        addressIsValid,
        resetAddressSelection,
      }}
    >
      {children}
    </AddressSelectionContext.Provider>
  )
}