import 'intl-tel-input/build/css/intlTelInput.css'

import { DetailedHTMLProps, Fragment, InputHTMLAttributes, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'

import Axios from 'axios'
import iti from 'intl-tel-input'
import styles from './TelephoneInput.module.sass'
import { useTranslation } from 'react-i18next'
import utilsScript from 'intl-tel-input/build/js/utils.js'

/**
 * @interface Props Input properties
 */
export interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /** The additional classes to append */
  className?: string
  /** intl-tel-input options object */
  options?: iti.Options
  /** Reference object pointing at initialized iti plugin */
  itiRef?: MutableRefObject<iti.Plugin | undefined>
  /** onValueChange is called within 3 events (onChange, onFocus, onBlur) */
  onValueChange?: (formattedValue: string, validation: {
    isValid: boolean,
    errorCode: intlTelInputUtils.validationError,
    validationError: string
  }) => void
  /** Set the initial country, when 'auto' is set, geoLookup occurs */
  initialCountry?: string
  /** Invalid telephone numbers will be indicated by red border */
  withInvalidIndicator?: boolean
  /** Invalid telephone numbers will be indicated by error message */
  withInvalidErrorMessages?: boolean
}

const geoIpLookup = (callback: (countryCode: string) => void) => {
  Axios.get('https://ipinfo.io', {
    headers: {
      Accept: 'application/json',
    },
  }).then(response => {
    callback(response?.data?.country || '')
  }).catch(() => {
    callback('')
  })
}

interface InputState {
  value: string
  isValid: boolean
  errorCode: number
  errorMessage: string
}

/**
 * @component Graphical component containing a logo image
 * @example
 * <TelephoneInput itiRef={itiRef} onValueChange={(formattedValue, validation) => { console.log(`formattedValue: ${formattedValue}, isValid: ${validation.isValid}, errorCode: ${validation.errorCode}, error: ${validation.validationError}`) }} />
 */
const TelephoneInput: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const itiPlugin = useRef<iti.Plugin | undefined>(undefined)
  const { t } = useTranslation('telephone_input')
  const [state, setState] = useState<InputState>({
    value: props.value?.toString() || props.defaultValue?.toString() || '',
    isValid: itiPlugin.current?.isValidNumber() || false,
    errorCode: itiPlugin.current?.getValidationError() || 0,
    errorMessage: t(`errors.${itiPlugin.current?.getValidationError()}`, t(`errors.${4}`, '')) || '',
  })

  useLayoutEffect(() => {
    if (!inputRef.current) return
    if (props.itiRef?.current) return
    if (itiPlugin.current) return
    itiPlugin.current = iti(inputRef.current, {
      utilsScript: utilsScript,
      initialCountry: props.initialCountry,
      geoIpLookup: props.initialCountry === 'auto' ? geoIpLookup : undefined
    })
    if (props.itiRef?.current) props.itiRef.current = itiPlugin.current
    itiPlugin.current.promise.finally(() => {
      setState({
        value: state.value || props.value?.toString() || props.defaultValue?.toString() || '',
        isValid: itiPlugin.current?.isValidNumber() || false,
        errorCode: itiPlugin.current?.getValidationError() || 0,
        errorMessage: t(`errors.${itiPlugin.current?.getValidationError()}`, t(`errors.${4}`, '')) || '',
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, props.itiRef, props.initialCountry])

  // Propagate country change from props
  useLayoutEffect(() => {
    if (!itiPlugin.current) return
    if (!props.initialCountry) return
    if (props.initialCountry === 'auto') {
      return geoIpLookup(countryCode => {
        if (!itiPlugin.current) return
        itiPlugin.current.setCountry(countryCode)
      })
    }
    itiPlugin.current.setCountry(props.initialCountry)
  }, [props.initialCountry])

  const propagateValueChange = () => {
    if (props.onValueChange && itiPlugin.current) {
      const value = itiPlugin.current.getNumber()
      const isValid = itiPlugin.current.isValidNumber()
      const errorCode = itiPlugin.current.getValidationError()
      const validationError = t(`errors.${errorCode}`, t(`errors.${4}`, ''))
      setState({
        value,
        isValid,
        errorCode,
        errorMessage: validationError,
      })
      props.onValueChange(value, {
        isValid,
        errorCode,
        validationError,
      })
    }
  }

  // Initial render validation
  useEffect(() => {
    window.setTimeout(() => {
      propagateValueChange()
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const passedProps = { ...props }
  delete passedProps.options
  delete passedProps.itiRef
  delete passedProps.onValueChange
  delete passedProps.initialCountry
  delete passedProps.withInvalidIndicator
  delete passedProps.withInvalidErrorMessages

  const errorClassname = (props.withInvalidIndicator && state.isValid === false) ? 'error-input' : ''
  const errorMessage = (props.withInvalidErrorMessages && state.isValid === false) ? state.errorMessage : ''

  return (
    <Fragment>
      <input
        {...passedProps}
        type={props.type || 'tel'}
        className={`${styles.TelephoneInput} ${errorClassname} ${props.className || ''}`.trim()}
        ref={inputRef}
        onFocus={e => {
          if (props.onFocus) props.onFocus(e)
          propagateValueChange()
        }}
        onChange={e => {
          e.target.value = e.target.value.replace(/[^0123456789\s()+-]/gmi, '').replace(/(.{1})(\++)/gmi, '$1')
          if (itiPlugin.current) itiPlugin.current.setNumber(e.target.value)
          if (props.onChange) props.onChange(e)
          propagateValueChange()
        }}
        onBlur={e => {
          e.target.value = e.target.value.replace(/\s+/gmi, ' ').trim()
          if (props.onBlur) props.onBlur(e)
          propagateValueChange()
        }}
      />
      {!!errorMessage &&
        <span className="error-message">{errorMessage}</span>
      }
    </Fragment>
  )
}

export default TelephoneInput