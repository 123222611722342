import * as Sentry from '@sentry/nextjs'

import { CustomerDTO, UserProfileDTO } from 'types/User'
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { APIRequestState } from 'constants/APIState'
import { Endpoints } from 'constants/Endpoints'
import axios from 'axios'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from 'lib/API'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useInitialQueryContext } from 'contexts/InitialQueryContext'

/** Type describing the shape of context value */
export interface UserContextType {
  /** Indicates the state of user profile request */
  userProfileAPIRequestState: APIRequestState
  /** Contains the whole platform user profile DTO */
  userProfile: UserProfileDTO | undefined
  /** Indicates if user profile was successfully loaded */
  userProfileLoaded: boolean
  /** Validated customerDTO taken from query parameters */
  customer: CustomerDTO | undefined
}

/** The default context value */
export const defaultUserContext: UserContextType = {
  userProfileAPIRequestState: APIRequestState.BEFORE_START,
  userProfile: undefined,
  userProfileLoaded: false,
  customer: undefined,
}

/** Context containing all user related data */
export const UserContext = createContext<UserContextType>(defaultUserContext)

/** Hook for user context */
export const useUserContext = (): UserContextType => useContext(UserContext)

/** Provider for UserContext which calls user profile endpoint upon mounting */
export const UserContextProvider: FC = ({
  children,
}) => {
  const { initialQueryParams } = useInitialQueryContext()
  const [userProfileAPIRequestState, setUserProfileAPIRequestState] = useState<UserContextType['userProfileAPIRequestState']>(APIRequestState.BEFORE_START)
  const [userProfile, setUserProfile] = useState<UserContextType['userProfile']>(undefined)
  const [customer, setCustomer] = useState<UserContextType['customer']>(undefined)

  const userProfileLoaded = useMemo(() => userProfileAPIRequestState === APIRequestState.COMPLETE && !!userProfile, [userProfileAPIRequestState, userProfile])

  /** Fetch user profile */
  useEffect(() => {
    const fetchUserProfle = async () => {
      if (!initialQueryParams) return
      const { apiClaim, customerName, customerWebId, userId, company, country } = initialQueryParams
      const body: CustomerDTO = {
        apiClaim,
        customerName,
        customerWebId,
        userId,
        company,
        country,
      }

      // Validate type of request body
      const decodedBody = CustomerDTO.decode(body)
      pipe(
        decodedBody,
        fold(
          errors => {
            const report = reporter.report(decodedBody)
            console.error(report, errors)
            setUserProfileAPIRequestState(APIRequestState.ERROR)
          },
          // Call API with validated request body
          async validatedBody => {
            setCustomer(validatedBody)
            try {
              setUserProfileAPIRequestState(APIRequestState.RUNNING)
              const URL = getRemoteAPIURL(Endpoints.USER_PROFILE)
              const response = await axios.post<UserProfileDTO>(URL, validatedBody)

              /** Validate type of response data */
              const decodedResponseData = UserProfileDTO.decode(response.data)
              pipe(
                decodedResponseData,
                fold(
                  errors => {
                    const report = reporter.report(decodedResponseData)
                    console.error(report, errors)
                    setUserProfileAPIRequestState(APIRequestState.ERROR)
                  },
                  // Set result
                  validatedResponseData => {
                    setUserProfile(validatedResponseData)
                    setUserProfileAPIRequestState(APIRequestState.COMPLETE)

                    // Track Sentry user
                    const { id, email } = validatedResponseData
                    if (id && email) {
                      Sentry.setUser({
                        id: id,
                        email: email,
                      })
                    }
                  }
                )
              )
            } catch (error) {
              console.error(error)
              setUserProfileAPIRequestState(APIRequestState.ERROR)
            }
          }
        )
      )
    }
    fetchUserProfle()
  }, [initialQueryParams])

  return (
    <UserContext.Provider
      value={{
        userProfileAPIRequestState,
        userProfile,
        userProfileLoaded,
        customer,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}