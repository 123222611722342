import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CheckCard from 'components/CheckCard/CheckCard'
import EmptyResultCards from 'components/EmptyResultCards/EmptyResultCards'
import { ProductKindDTO } from 'types/ProductConfiguration'
import { ProductOptionType } from 'types/ProductOptionType'
import { ProductSegment } from 'types/ProductSegment'
import { ProductType } from 'types/ProductType'
import { ShootingCategory } from 'types/ProductCategory'
import { bigFromFee } from 'lib/decimals'
import cloneDeep from 'lodash/cloneDeep'
import { formatPrice } from 'lib/priceFormatter'
import { getTimeDurationStringFromMinutesAndHours } from 'lib/timeUtils'
import styles from './ProductSelection.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useProductConfigurationContext } from 'contexts/ProductConfigurationContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useUserContext } from 'contexts/UserContext'
import { valueAfterDiscount } from 'lib/priceCalculations'

const productComparison = (prodA: ProductKindDTO, prodB: ProductKindDTO) => bigFromFee(prodA.feePrice).minus(bigFromFee(prodB.feePrice)).toNumber()

/**
 * @component Component which lists all products in purchase flow
 * @example
 * <ProductListing />
 */
const ProductListing: FC<{
  /** The additional classes to append */
  className?: string
  /** Whether to display type heading element */
  displayTypeHeading?: boolean
  /** Whether to display only selected types, products and options */
  onlySelected?: boolean
  /** Whether to disabled selecting/deselecting */
  disabledSelection?: boolean
  /** Whether the product listing is disabled and grayed out */
  disabled?: boolean
  /** Whether the product listing is faded out */
  faded?: boolean
}> = ({
  displayTypeHeading = true,
  onlySelected = false,
  disabledSelection = false,
  disabled = false,
  faded = false,
}) => {
    const { userProfile } = useUserContext()
    const { platformPlace, addressIsValid } = useAddressSelectionContext()
    const { productConfiguration } = useProductConfigurationContext()
    const { productSelection, selectedProductSegment, selectedProductSegmentKey, selectedProductTypes, setProducts } = useProductSelectionContext()
    const { currency, discount } = useProductConfigurationContext()
    const { t } = useTranslation(['order', 'product', 'product_type', 'product_type_description', 'segment', 'product_kind_description'])

    if (!userProfile || !platformPlace || !addressIsValid || !productConfiguration) return null

    if (!productSelection || !selectedProductSegment || !selectedProductSegmentKey || !currency || !discount) return (
      <div className="center">
        <EmptyResultCards />
      </div>
    )

    return (
      <Fragment>
        {Object
          .entries(selectedProductSegment.productTypes || {})
          .filter(([, type]) => {
            if (!type) return true
            if (onlySelected && selectedProductTypes.has(ProductType[type.key])) {
              for (const product of type.products) if (product.selected) return true
              return false
            }
            return !onlySelected
          })
          .map(([typeKey, type]) => {
            return (
              <Fragment key={typeKey}>
                {displayTypeHeading &&
                  <Fragment>
                    {selectedProductTypes.has(ProductType[type.key]) &&
                      <h3 className={`${styles.typeHeading} ${faded ? 'faded' : ''}`}>
                        <Fragment>
                          {t(`product_type:${typeKey}`)}
                          {selectedProductSegmentKey && selectedProductSegmentKey !== ProductSegment.all &&
                            <Fragment>
                              {' ('}
                              <Trans t={t} i18nKey={`segment:${selectedProductSegmentKey}`}>
                                <sup></sup>
                              </Trans>
                              {')'}
                            </Fragment>
                          }
                        </Fragment>
                      </h3>
                    }
                  </Fragment>
                }
                {type.products
                  .filter(product => (onlySelected && product.selected) || !onlySelected)
                  .sort(productComparison)
                  .map((product) => {
                    const productPriceAfterDiscount = valueAfterDiscount(product.feePrice, discount)
                    return (
                      <Fragment key={product.id}>
                        {selectedProductTypes.has(ProductType[type.key]) &&
                          <CheckCard
                            className={`${styles.checkcard} ${faded ? 'faded' : ''}`}
                            checkbox={disabledSelection ? undefined : 'square'}
                            onCheck={disabledSelection ? () => { return } : checked => {
                              const newProductsState = cloneDeep(productSelection)
                              const products = newProductsState[selectedProductSegmentKey]?.productTypes?.[typeKey]?.products
                              if (!products) throw new Error(`Products are missing for segment: ${selectedProductSegmentKey} and product type: ${typeKey}`)
                              for (const k in products) {
                                if (products[k].id === product.id) products[k].selected = checked
                              }
                              setProducts(newProductsState)
                            }}
                            checked={!!product.selected}
                            innerClickable={!disabledSelection}
                            disabled={disabled}
                            right={(
                              <Fragment>
                                {discount.gt(0) &&
                                  <span className="line-through gray-text">{formatPrice(product.feePrice, currency)}</span>
                                }
                                <strong>{formatPrice(productPriceAfterDiscount, currency)}</strong>
                              </Fragment>
                            )}
                            expandable={onlySelected && !product.options.reduce((accumulator, option) => accumulator || !!option.value, false) ? undefined : product.options.length === 0 ? undefined : (
                              <Fragment>
                                {product.options
                                  .filter(option => (onlySelected && option.value > 0) || !onlySelected)
                                  .sort((optionA, optionB) => bigFromFee(optionA.feePrice).minus(bigFromFee(optionB.feePrice)).toNumber())
                                  .map((option) => {
                                    const title = t(`product:p_${option.id}`)
                                    // if (option.type === OptionType.BOOLEAN) title += ` - ${formatPrice(option.price, currency)}`
                                    const optionComposedKey = `${selectedProductSegmentKey}_${typeKey}_${product.id}_${option.id}`
                                    const optionPriceAfterDiscount = valueAfterDiscount(option.feePrice, discount)
                                    return (
                                      <Fragment key={option.id}>
                                        <strong>
                                          {title}
                                          {' - '}
                                          {formatPrice(optionPriceAfterDiscount, currency)}
                                          {discount.gt(0) &&
                                            <span className="original-price">
                                              {'('}
                                              <span className="line-through">
                                                {formatPrice(option.feePrice, currency)}
                                              </span>
                                              {')'}
                                            </span>
                                          }
                                        </strong>
                                        {!!option.duration &&
                                          <span>{t('step_product.max_duration', { duration: getTimeDurationStringFromMinutesAndHours((option.value || 1) * option.duration) })}</span>
                                        }
                                        {/* <span>TODO: Option Description: {option.id}</span> */}
                                        {option.type === ProductOptionType.INTEGER &&
                                          <Fragment>
                                            {/* <label htmlFor={optionComposedKey}>TODO: Number Input Label - ({formatPrice(option.price, currency)} {t('step_product.per_one')})</label> */}
                                            <input
                                              type="number"
                                              step={1}
                                              min={0}
                                              max={100}
                                              name={optionComposedKey}
                                              id={optionComposedKey}
                                              // placeholder={'TODO: Number Input Placeholder'}
                                              value={option.value.toString()}
                                              onChange={e => {
                                                const newProductsState = cloneDeep(productSelection)
                                                const products = newProductsState[selectedProductSegmentKey]?.productTypes?.[typeKey]?.products
                                                if (!products) throw new Error(`Products are missing for segment: ${selectedProductSegmentKey} and product type: ${typeKey}`)
                                                for (const k in products) {
                                                  if (products[k].id === product.id) {
                                                    for (const o in products[k].options) {
                                                      if (products[k].options[o].id === option.id) {
                                                        products[k].options[o].value = Math.min(100, Math.max(0, parseInt(e.target.value.replace(/(-+.+)|(^0+)|(\D)/gmi, ''), 10) || 0))
                                                      }
                                                    }
                                                  }
                                                }
                                                setProducts(newProductsState)
                                              }}
                                              disabled={disabledSelection}
                                              onWheel={e => e.currentTarget.blur()}
                                            />
                                          </Fragment>
                                        }
                                        {option.type === ProductOptionType.BOOLEAN &&
                                          <Fragment>
                                            <div className="checkbox-wrap">
                                              <label className={`checkbox square ${disabledSelection ? 'disabled' : ''}`.trim()} htmlFor={optionComposedKey}>
                                                <input
                                                  type="checkbox"
                                                  name={optionComposedKey}
                                                  id={optionComposedKey}
                                                  checked={!!option.value}
                                                  onChange={e => {
                                                    const newProductsState = cloneDeep(productSelection)
                                                    const products = newProductsState[selectedProductSegmentKey]?.productTypes?.[typeKey]?.products
                                                    if (!products) throw new Error(`Products are missing for segment: ${selectedProductSegmentKey} and product type: ${typeKey}`)
                                                    for (const k in products) {
                                                      if (products[k].id === product.id) {
                                                        for (const o in products[k].options) {
                                                          if (products[k].options[o].id === option.id) {
                                                            products[k].options[o].value = e.target.checked ? 1 : 0
                                                          }
                                                        }
                                                      }
                                                    }
                                                    setProducts(newProductsState)
                                                  }}
                                                  disabled={disabledSelection}
                                                />
                                                <span className="checkmark"></span>
                                                <span className="label-after">{t('step_product.include')}</span>
                                              </label>
                                            </div>
                                          </Fragment>
                                        }
                                      </Fragment>
                                    )
                                  })}
                              </Fragment>
                            )}
                          >
                            <strong className="title">{t(`product:p_${product.id}`)}</strong>
                            {product.shootingDuration &&
                              <p>{t('step_product.max_duration', { duration: getTimeDurationStringFromMinutesAndHours(product.shootingDuration) })}</p>
                            }
                            {!!t(`product_kind_description:${product.kind}`, '') &&
                              <p >
                                <Trans t={t} i18nKey={`product_kind_description:${product.kind}`} defaults={''}>
                                  <span className="newline"></span>
                                </Trans>
                              </p>
                            }
                            {!!t(`product_type_description:${type.key}`, '') &&
                              <p>
                                <Trans t={t} i18nKey={`product_type_description:${type.key}`} defaults={''}>
                                  <span className="newline"></span>
                                </Trans>
                              </p>
                            }
                            {!!t(`step_product.category_${ShootingCategory.REAL_ESTATE}_type_${typeKey}`, '') &&
                              <p>
                                <Trans t={t} i18nKey={`step_product.category_${ShootingCategory.REAL_ESTATE}_type_${typeKey}`} defaults={''}>
                                  <span className="newline"></span>
                                </Trans>
                              </p>
                            }
                          </CheckCard>
                        }
                      </Fragment>
                    )
                  })}
              </Fragment>
            )
          })}
      </Fragment>
    )
  }

export default ProductListing