import { FC } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { IconType } from 'react-icons'
import styles from './AlertMessage.module.sass'

const AlertMessage: FC<{
  /** Whether the alert message is display across the whole webpage covering everything underneath */
  fullPage?: boolean
  /** Whether the fullpage cover is opaque or transparent*/
  opaque?: boolean
  /** The icon that will be displayed */
  IconElement?: IconType
}> = ({
  fullPage = false,
  opaque = false,
  IconElement = FaExclamationTriangle,
  children,
}) => {
    return (
      <div className={`${styles.AlertMessage} ${fullPage ? `${styles.FullPage} ${opaque ? styles.Opaque : ''}` : ''}`}>
        <div className={`${styles.AlertMessageContent} ${fullPage ? styles.FullPage : ''}`}>
          <IconElement className={styles.Icon} />
          {children}
        </div>
      </div>
    )
  }

export default AlertMessage