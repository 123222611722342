import * as t from 'io-ts'

import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api'
import React, { Component, Fragment } from 'react'

import Autocomplete from 'react-google-autocomplete'
import { Coordinates } from 'types/Coordinates'
import { GOOGLE_API_KEY } from 'constants/GoogleAPI'
import Geocode from 'react-geocode'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { Translation } from 'react-i18next'
import styles from './AddressMap.module.sass'

Geocode.setApiKey(GOOGLE_API_KEY)
// Geocode.enableDebug()

/** Browser geolocation object */
export interface GeolocationPosition {
  coords: {
    latitude: number
    longitude: number
    accuracy: number
  }
  timestamp: number
}

/** Google map marker event */
export interface MarkerEvent {
  latLng: {
    lat: () => number
    lng: () => number
  }
  pixel: {
    x: number
    y: number
  }
  tb?: any
  Za?: any
}

export interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

export interface AutocompletePlace {
  place_id: string
  formatted_address: string
  geometry: {
    location: {
      lat: () => number
      lng: () => number
    }
  }
  html_attributions: any[]
  // utc_offset: any
  address_components: AddressComponent[]
}

export const ReactGeocodePoint = Coordinates
export type ReactGeocodePoint = t.TypeOf<typeof ReactGeocodePoint>

export interface ReactGeocodePlusCode {
  compound_code: string
  global_code: string
}

export const ReactGeocodeAddressComponent = t.type({
  long_name: t.string,
  short_name: t.string,
  types: t.array(t.string),
})
export type ReactGeocodeAddressComponent = t.TypeOf<typeof ReactGeocodeAddressComponent>

export const ReactGeocodePlace = t.type({
  place_id: t.string,
  formatted_address: t.string,
  address_components: t.array(ReactGeocodeAddressComponent),
  geometry: t.intersection([
    t.type({
      location: ReactGeocodePoint,
    }),
    t.partial({
      location_type: t.string,
      bounds: t.type({
        northeast: ReactGeocodePoint,
        southwest: ReactGeocodePoint,
      }),
      viewport: t.type({
        northeast: ReactGeocodePoint,
        southwest: ReactGeocodePoint,
      }),
    }),
  ]),
})
export type ReactGeocodePlace = t.TypeOf<typeof ReactGeocodePlace>

export interface ReactGeocodeResponse {
  status: string
  plus_code: ReactGeocodePlusCode
  results: ReactGeocodePlace[]
}

export interface Props {
  /** The additional classes to append */
  className?: string
  /** Center point of the map and initial marker position if not specified */
  center?: ReactGeocodePoint
  /** Initial marker position */
  initialMarker?: ReactGeocodePoint
  /** initial map zoom */
  zoom?: number
  /** mac container height */
  height: number | string
  /** The initial address input string */
  initialAddress?: string
  /** error text to display beside the input */
  error?: string
  /** Action called when address changes */
  handleChange: (place: ReactGeocodePlace | null, isInitialLookup: boolean) => void
  /** Handle autocomplete input change */
  onInputChange?: ((event: React.FormEvent<HTMLInputElement>) => void) | undefined
  /** Position of the input, either above or below the map container, defaults to below */
  inputPosition?: 'below' | 'above'
  /** Whether the map should try to obtain current location of the user */
  geolocation?: boolean
  /** How long it takes for new props to populate the state */
  timeoutAfterPropsChange?: number
  /** Language used to initialize the google script */
  language?: string
  /** Country code used to specify where to search for addresses */
  countryCode?: string
  /** A string or an array of countries such es ['CH, AT, DE'] to restrict the search to */
  restrictToCountries?: string | string[]
}

interface State {
  [key: string]: any
  /** Address string */
  address: string
  /** Google Maps API place object */
  place_object: ReactGeocodePlace | null
  /** Map center */
  mapCenter: ReactGeocodePoint
  /** Marker position on the map */
  markerPosition: ReactGeocodePoint | null
  /** Map zoom */
  zoom: number
}

/**
 * Component for address selection
 *
 * @class AddressMap
 * @extends {Component<Props, State>}
 * @example
 * <AddressMap
 *  center={{lat: 46.1993826, lng: 6.135054}}
 *  zoom={6}
 *  height={'400px'}
 *  handleChange={address => {console.log(address)}}
 * />
 */
class AddressMap extends Component<Props, State> {

  static initialZoom = 4
  static zoomedIn = 16
  static initialCenter: ReactGeocodePoint = { lat: 46.4601654, lng: 7.5588792 }

  constructor(props: Props) {
    super(props)
    const state: State = {
      address: this.props.initialAddress || '',
      place_object: null,
      mapCenter: AddressMap.initialCenter,
      markerPosition: null,
      zoom: this.props.zoom || AddressMap.initialZoom,
    }
    if (this.props.center) {
      state.mapCenter = {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      }
    }
    if (this.props.initialMarker) {
      state.markerPosition = {
        lat: this.props.initialMarker.lat,
        lng: this.props.initialMarker.lng
      }
    }
    this.state = state
  }

  /** Handle change in autocomplete text input */
  handleAutocompleteChange = (event: any) => {
    this.setState({
      address: event.target.value
    })
  }


  /** set initial google place from passed props */
  componentDidMount() {
    if (this.props.language) Geocode.setLanguage(this.props.language)
    if (this.props.countryCode) Geocode.setRegion(this.props.countryCode)
    this.setInitialPlace()
  }

  /** Set initial place either from the the initial address string or geolocation lookup */
  setInitialPlace() {
    if (this.props.initialAddress) {
      const tryLookupAddress = async () => {
        try {
          const place = await this.lookupPlaceFromAddress(this.props.initialAddress || '')
          this.setStateFromPlace(place, true)
        } catch (error) {
          console.error(error)
          this.setStateFromPlace(null, true)
        }
      }
      tryLookupAddress()
    }

    if (this.props.geolocation) {
      this.lookupFromCurrentPosition()
    }

    // const tryLookupPosition = async () => {
    //   try {
    //     const response = await this.lookupPlaceLatLng(this.state.mapCenter.lat, this.state.mapCenter.lng)
    //     this.setStateFromPlace(response, true)
    //   } catch (error) {
    //     console.error(error)
    //     this.setStateFromPlace(null, true)
    //   }
    // }
    // tryLookupPosition()
  }

  /** Geocode API lokup place from lat, lon geoposition */
  lookupPlaceLatLng = async (lat: number, lng: number) => {
    try {
      const response = await Geocode.fromLatLng(lat.toString(), lng.toString())
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /** Geocode API lookup place from string */
  lookupPlaceFromAddress = async (address_string: string) => {
    try {
      const response = await Geocode.fromAddress(address_string)
      if (response.results.length === 0) return null
      return response.results[0]
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /** Set component state from the Geocode API place object */
  setStateFromPlace = (place: ReactGeocodePlace | null, isInitialLookup = false) => {
    let newState: { [key: string]: any } = {
      address: place?.formatted_address || '',
      place_object: place,
    }
    if (place !== null) {
      const { lat, lng } = place.geometry.location
      newState = {
        ...newState,
        mapCenter: { lat, lng },
        markerPosition: { lat, lng },
      }
      newState = {
        ...newState,
        zoom: AddressMap.zoomedIn,
      }
    }
    this.setState(newState)
    this.propagateChanges(place, isInitialLookup)
  }

  /** Lookup current position via browser geolocation */
  lookupFromCurrentPosition = () => {
    if (!window.navigator.geolocation) return
    window.navigator.geolocation.getCurrentPosition(async (GeolocationPosition: GeolocationPosition) => {
      const { latitude, longitude } = GeolocationPosition.coords
      try {
        const response = await this.lookupPlaceLatLng(latitude, longitude)
        this.setStateFromPlace(response, true)
      } catch (error) {
        console.error(error)
        this.setStateFromPlace(null, true)
      }
    })
  }

  /** compare props and call API for initial address or coordinate search if necessary */
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: Props, prevState: State) {
    const newState: any = {}
    if (this.props.initialAddress && this.props.initialAddress !== prevProps.initialAddress) {
      newState.address = this.props.initialAddress
    }
    if (this.props.initialMarker && this.props.initialMarker !== prevProps.initialMarker) {
      newState.markerPosition = this.props.initialMarker
      newState.mapCenter = this.props.initialMarker
      newState.zoom = AddressMap.zoomedIn
    }

    if (Object.keys(newState).length === 0) return

    setTimeout(() => {
      this.setState(newState, () => {
        this.setInitialPlace()
      })
    }, this.props.timeoutAfterPropsChange || 0)

    if (this.props.language && this.props.language !== prevProps.language) Geocode.setLanguage(this.props.language)
    if (this.props.countryCode && this.props.countryCode !== prevProps.countryCode) Geocode.setRegion(this.props.countryCode)
  }

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = async (event: MarkerEvent) => {
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()

    this.setState({
      markerPosition: { lat, lng },
      mapCenter: { lat, lng },
    })

    try {
      const response = await Geocode.fromLatLng(lat.toString(), lng.toString())
      let place_object: ReactGeocodePlace | null = null
      if (response.results.length > 0) {
        place_object = response.results[0]
      }
      this.setStateFromPlace(place_object)
    } catch (error) {
      console.error(error)
      this.setStateFromPlace(null)
    }
  }

  /**
   * When the user selects an address in the search box
   * @param place
   */
  onPlaceSelected = async (place: AutocompletePlace & { name: string }) => {
    if (place.name) {
      try {
        const lookupPlace = await this.lookupPlaceFromAddress(place.name)
        return this.setStateFromPlace(lookupPlace)
      } catch (error) {
        console.error(error)
        return this.setStateFromPlace(null)
      }
    }

    if (!place || !place.geometry || !place.formatted_address) {
      return this.setStateFromPlace(null)
    }

    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    const place_object: ReactGeocodePlace = {
      ...place,
      geometry: {
        location: {
          lat,
          lng,
        }
      },
    }

    this.setStateFromPlace(place_object)
  }

  /** Propagate changes to the outer world */
  propagateChanges = (newPlace: ReactGeocodePlace | null, isInitialLookup = false) => {
    this.props.handleChange(newPlace, isInitialLookup)
  }

  static libraries: Libraries = ['places']

  render() {
    const map = (
      <GoogleMap
        mapContainerStyle={{ height: this.props.height }}
        zoom={this.state.zoom}
        center={{ lat: this.state.mapCenter.lat, lng: this.state.mapCenter.lng }}
      >
        {this.state.markerPosition &&
          <Marker
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
          />
        }
      </GoogleMap>
    )

    const autocomplete = (
      <Translation ns="address_map">
        {
          //eslint-disable-next-line @typescript-eslint/no-unused-vars
          (t, { i18n }) => (
            <div className={styles.AutoComplete}>
              {this.props.error && this.props.inputPosition === 'above' &&
                <span className="error-message">{this.props.error}</span>
              }
              <Autocomplete
                inputAutocompleteValue={this.state.address}
                defaultValue={this.state.address}
                onChange={e => {
                  this.handleAutocompleteChange(e)
                  this.props.onInputChange && this.props.onInputChange(e)
                }}
                placeholder={t('placeholder')}
                onPlaceSelected={this.onPlaceSelected}
                options={{
                  types: ['address'],
                  componentRestrictions: this.props.restrictToCountries ? { country: this.props.restrictToCountries } : undefined,
                }}
                className={this.props.error ? 'error-input' : ''}
              />
              {this.props.error && this.props.inputPosition === 'below' &&
                <span className="error-message">{this.props.error}</span>
              }
            </div>
          )
        }
      </Translation>
    )

    let display = (
      <Fragment>
        {map}
        {autocomplete}
      </Fragment>
    )

    if (this.props.inputPosition === 'above') {
      display = (
        <Fragment>
          {autocomplete}
          {map}
        </Fragment>
      )
    }

    return (
      <div className={`${styles.AddressMap} ${this.props.className || ''}`.trim()}>
        <LoadScriptNext
          googleMapsApiKey={GOOGLE_API_KEY}
          libraries={AddressMap.libraries}
          language={this.props.language}
          region={this.props.countryCode}
        >
          {display}
        </LoadScriptNext>
      </div>
    )
  }
}

export default AddressMap