import { Language } from 'constants/Language'

/**
 * Coercion function, that takes string representation of language (ISO 639-1) and coerces it to match our definition of Languages in Language enum.  
 * If language derived from string does not match any of our languages, fallback language (EN by default) will be used.   
 * If passed string does not contain any usable value, fallback language will be used as well.   
 * 
 * @param lang - ISO 639-1 language name
 * @param fallbackLanguage Language enum value, that will be used in case no language can be derived from provided string or such language is not present in Language enum
 * @returns Language in format of Language enum
 * @example coerceLanguage('en-GB') => 'EN' // Language.EN
 */
export const coerceLanguage = (lang: string, fallbackLanguage = Language.EN): Language => {
  if (typeof lang !== 'string') return fallbackLanguage

  const isPlainLang = /^[a-z]{2}$/.test(lang.toLowerCase())
  const isFullLang = /^[a-z]{2}-[a-z]{2}$/.test(lang.toLowerCase())

  if (!isPlainLang && !isFullLang) return fallbackLanguage

  if (isPlainLang) return Language[(lang.toUpperCase() as Language)] || fallbackLanguage
  return Language[(lang.split('-')[0].toUpperCase() as Language)] || fallbackLanguage
}
