import { Color } from 'constants/Colors'
import { FC } from 'react'
import ReactLoading from 'react-loading'
import styles from './Loading.module.sass'

const Loading: FC<{
  fullPage?: boolean
}> = ({
  fullPage = false,
  children,
}) => {
    return (
      <div className={`${styles.Loading} ${fullPage ? styles.FullPage : ''}`}>
        <div className={`${styles.LoadingContent} ${fullPage ? styles.FullPage : ''}`}>
          <ReactLoading color={Color.GRAY_TEXT} type="cylon" className="spinner loading infinite" />
          {children}
        </div>
      </div>
    )
  }

export default Loading