import { Currency, isValidCurrency } from 'constants/Currency'

import Big from 'big.js'
import { DecimalDTO } from 'types/Decimal'
import { FeeDTO } from 'types/FeeDTO'
import { getUserLocale } from 'get-user-locale'

/** Amount of fixed decimal places to format numbers into when displaying */
export const FIXED_DECIMAL_PLACES = 2

type possiblePriceType = number | string | DecimalDTO | FeeDTO | Big

/** Typeguard */
function priceIsFeeDTO(price: possiblePriceType): price is FeeDTO {
  return (
    (price as FeeDTO).value !== undefined
  )
}

export function formatPrice(price: possiblePriceType = 0, currency?: Currency | string | null, locale?: string) {
  if (priceIsFeeDTO(price)) {
    if (isValidCurrency(price.currency)) currency = price.currency
    price = price.value
  }

  if (!isValidCurrency(currency)) {
    if (typeof price === 'number') return price.toString().replace(/\s/g, ' ')
    else if (typeof price === 'string') return price.replace(/\s/g, ' ')
    else if (price instanceof Big) return price.toFixed(FIXED_DECIMAL_PLACES)
    else return price.value.replace(/\s/g, ' ')
  }

  let priceNumber: number
  if (typeof price === 'number') priceNumber = price
  else if (typeof price === 'string') priceNumber = Number(price)
  else if (price instanceof Big) priceNumber = price.round(2).toNumber()
  else priceNumber = Number(price.value)

  return new Intl.NumberFormat(locale || getUserLocale(), { style: 'currency', currency }).format(priceNumber).replace(/\s/g, ' ')
}

export function formatCurrency(currency: Currency, locale?: string) {
  const price = 0
  const numberFormatArray = new Intl.NumberFormat(locale || getUserLocale(), { style: 'currency', currency }).formatToParts(price)
  const currencyObject = numberFormatArray.find(currency => currency.type === 'currency')

  if (currencyObject?.value) return currencyObject.value
  return currency
}