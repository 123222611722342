import { FC, Fragment, useMemo } from 'react'

import { APIRequestState } from 'constants/APIState'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import InstructionListing from './InstructionListing'
import Loading from 'components/Loading/Loading'
import styles from './InstructionSelection.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInstructionConfigurationContext } from 'contexts/InstructionConfigurationContext'
import { useInstructionSelectionContext } from 'contexts/InstructionSelectionContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useTranslation } from 'react-i18next'
import { useUserContext } from 'contexts/UserContext'

const InstructionSelection: FC = ({
  children,
}) => {
  const { t } = useTranslation(['order', 'onoffice'])
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const { productSelectionList } = useProductSelectionContext()
  const { availableInstructionTypes, selectedOrganizationCount, selectedPrimaryBillingCount, someRequiredMissing } = useInstructionSelectionContext()
  const { instructionConfigurationLoaded, instructionConfiguration, instructionConfigurationAPIRequestState } = useInstructionConfigurationContext()

  const displayedWarning = useMemo(() => {
    if (selectedOrganizationCount === 0) return <span>{t('step_instruction.no_organization')}</span>
    if (selectedOrganizationCount > 1) return <span>{t('step_instruction.too_many_organization')}</span>
    if (selectedPrimaryBillingCount === 0) return <span>{t('step_instruction.no_billing')}</span>
    if (someRequiredMissing) return <span>{t('step_instruction.required_missing')}</span>
    else return null
  }, [t, selectedOrganizationCount, selectedPrimaryBillingCount, someRequiredMissing])

  const whenInstructionConfigurationNotLoaded = useMemo(() => {
    switch (instructionConfigurationAPIRequestState) {
      case APIRequestState.RUNNING:
        return (
          <Loading>
            <span>{t('onoffice:loading_state_RUNNING', { subject: t('onoffice:selection_instruction') })}</span>
          </Loading>
        )
      case APIRequestState.ERROR:
        return (
          <AlertMessage>
            <span>{t('onoffice:loading_state_ERROR', { subject: t('onoffice:selection_instruction') })}</span>
          </AlertMessage>
        )
      case APIRequestState.BEFORE_START:
      case APIRequestState.COMPLETE:
      default:
        return <Fragment></Fragment>
    }
  }, [t, instructionConfigurationAPIRequestState])

  if (!instructionConfigurationLoaded || !instructionConfiguration) return (
    <Fragment>
      {whenInstructionConfigurationNotLoaded}
    </Fragment>
  )

  if (!userProfile) return (
    <AlertMessage fullPage>
      <h1>{t('onoffice:missing_user')}</h1>
    </AlertMessage>
  )

  if (!platformPlace || !addressIsValid) return <Fragment></Fragment>
  if (productSelectionList.length === 0) return <Fragment></Fragment>

  return (
    <div className={styles.InstructionSelection}>
      <hr />
      <Fragment>
        <h2>3. {t('step_instruction.title')}</h2>
        <p>{t('step_instruction.text')}</p>
      </Fragment>
      {availableInstructionTypes.length === 0 ?
        <h2 className="gray-text empty-title">{t('step_product.none_for_country')}</h2>
        :
        <InstructionListing />
      }
      {!!displayedWarning &&
        <h2 className="red-text empty-title">{displayedWarning}</h2>
      }
      {children}
    </div>
  )
}

export default InstructionSelection