import AddressMap, { ReactGeocodePlace } from 'components/AddressMap/AddressMap'
import { FC, Fragment, useCallback, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getDateString, getTimeString, isSameTimezone } from 'lib/timeUtils'
import { getDatesBetweenDateAndNumberOfBusinessDays, isBeforeEndOfWorkingHours } from 'lib/businessDays'
import { getUserTimezone, timezoneNames } from 'lib/timezoneUtils'

import BlockInfo from 'components/BlockInfo/BlockInfo'
import CheckCard from 'components/CheckCard/CheckCard'
import { ChooseOneInstructionTypeKeys } from 'constants/ChooseOneInstructionTypeKeys'
import DatePicker from 'react-datepicker'
import { IconType } from 'constants/IconType'
import { InstructionOptionFieldType } from 'types/InstructionOptionFieldType'
import { InstructionOptionFieldValueTypeIsReactGeocodePlace } from 'types/InstructionConfiguration'
import { InstructionType } from 'types/InstructionType'
import { InstructionTypeSortingOrder } from 'constants/InstructionTypeSortingOrder'
import { NullableNumberIndexSignature } from 'types/HelperTypes'
import { ProductKind } from 'types/ProductKind'
import ReactTooltip from 'react-tooltip'
import TelephoneInput from 'components/TelephoneInput/TelephoneInput'
import { bigFromFee } from 'lib/decimals'
import cloneDeep from 'lodash/cloneDeep'
import { formatPrice } from 'lib/priceFormatter'
import { getUserDatePickerLocale } from 'lib/localeUtils'
import i18n from 'lib/i18n'
import { isAddressValid } from 'lib/addressValidator'
import moment from 'moment-timezone'
import styles from './InstructionSelection.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInstructionConfigurationContext } from 'contexts/InstructionConfigurationContext'
import { useInstructionSelectionContext } from 'contexts/InstructionSelectionContext'
import { useProductConfigurationContext } from 'contexts/ProductConfigurationContext'
import { useUserContext } from 'contexts/UserContext'
import { valueAfterDiscount } from 'lib/priceCalculations'

const gridOptionKinds = new Set([ProductKind.MEETING_ON_SITE, ProductKind.KEYS_PICKUP, ProductKind.ORGANIZATION_THIRD_PARTY])
const fullWidthOptionFieldKeys = new Set<InstructionOptionFieldType>([InstructionOptionFieldType.COMMENT, InstructionOptionFieldType.ADDRESS])
/** Maximum amount of characters allowed for reference input */
const maxReferenceCharacters = 50
const minDaysBeforeShooting = 2

/**
 * @component Graphical component with an icon in a triangle
 * @example
 * <InstructionListing />
 */
const InstructionListing: FC<{
  /** The additional classes to append */
  className?: string
  /** Whether to display type heading element */
  displayTypeHeading?: boolean
  /** Whether to display only selected instruction */
  onlySelected?: boolean
  /** Whether to disable selecting/deselecting */
  disabledSelection?: boolean
  /** Whether to disable filling in the option fields */
  disabledFields?: boolean
}> = ({
  displayTypeHeading = true,
  onlySelected = false,
  disabledSelection = false,
  disabledFields = false,
}) => {
    const { t } = useTranslation(['order', 'product', 'instruction_type', 'instruction_option_field', 'product_kind_description'])
    const { userProfile } = useUserContext()
    const { productConfiguration, discount } = useProductConfigurationContext()
    const { instructionConfiguration, currency, locationTimezone } = useInstructionConfigurationContext()
    const { platformPlace, addressIsValid } = useAddressSelectionContext()
    const { instructionSelection, setInstructions, selectedOrganizationCount, selectedPrimaryBillingCount, instructionTypesWithMissingRequiredField } = useInstructionSelectionContext()

    const userTimezone = useMemo(() => getUserTimezone(userProfile), [userProfile])
    const isEqualTimezone = useMemo(() => !!userTimezone && !!locationTimezone && isSameTimezone(userTimezone, locationTimezone), [userTimezone, locationTimezone])

    const datePickerTimeRef = useRef<NullableNumberIndexSignature<HTMLDivElement>>({})

    const handleTimeScroll = useCallback((instructionOptionId: number) => {
      const datePickerTimeDiv = datePickerTimeRef.current[instructionOptionId]
      if (!datePickerTimeDiv) return
      setTimeout(() => {
        const timeListItems = datePickerTimeDiv.querySelectorAll('li.react-datepicker__time-list-item')
        const inputValue = datePickerTimeDiv.querySelector('input')?.value
        if (inputValue) {
          const targetTime = Array.from(timeListItems as NodeListOf<HTMLElement>).find(el => el.innerText.startsWith(inputValue))
          if (targetTime && targetTime.parentElement) return targetTime.parentElement.scrollTop = targetTime.offsetTop - targetTime.parentElement.offsetTop
        }
      }, 500)
    }, [])

    const getFieldProps = useCallback((key: InstructionOptionFieldType | string): { className: string, type: string } => {
      switch (key) {
        case InstructionOptionFieldType.DATE:
          return {
            className: `withicon right ${IconType.DATE}`,
            type: 'date',
          }
        case InstructionOptionFieldType.TIME:
          return {
            className: `withicon right ${IconType.CLOCK}`,
            type: 'time',
          }
        case InstructionOptionFieldType.EMAIL:
          return {
            className: `withicon right ${IconType.ENVELOPE}`,
            type: 'email',
          }
        case InstructionOptionFieldType.PHONE:
          return {
            className: `withicon right ${IconType.PHONE}`,
            type: 'tel',
          }
        case InstructionOptionFieldType.NAME:
          return {
            className: `withicon right ${IconType.PROFILE}`,
            type: 'text',
          }
        case InstructionOptionFieldType.COMMENT:
          return {
            className: '',
            type: 'text',
          }
        default:
          return {
            className: '',
            type: 'text',
          }
      }
    }, [])

    if (!userProfile || !platformPlace || !addressIsValid || !productConfiguration || !instructionConfiguration || !instructionSelection) return null

    return (
      <Fragment>
        {
          Object.entries(instructionSelection)
            .sort((typeA, typeB) => InstructionTypeSortingOrder.indexOf(typeA[0] as InstructionType) - InstructionTypeSortingOrder.indexOf(typeB[0] as InstructionType))
            .map(([typeKey, type]) => {
              const typeKeyIncluded = Object.keys(instructionSelection).includes(typeKey)
              const typeNotEmpty = !!type && type.instructionOptions.length > 0
              const includeType = typeKeyIncluded && typeNotEmpty
              if (onlySelected && !type?.instructionOptions.reduce((accumulator, option) => accumulator || !!option.selected, false)) return (<Fragment key={typeKey}></Fragment>)
              const isRed = ((type.key === InstructionType.ORGANIZATION && selectedOrganizationCount !== 1) || (type.key === InstructionType.BILLING_PRIMARY && selectedPrimaryBillingCount !== 1) || instructionTypesWithMissingRequiredField.has(InstructionType[type.key]))
              return (
                <Fragment key={typeKey}>
                  {displayTypeHeading &&
                    <Fragment>
                      {includeType &&
                        <Fragment>
                          <h3 className={`${styles.typeHeading} ${isRed ? styles.red : ''}`}>
                            {t(`instruction_type:${typeKey}:title`)}
                            {!!type && ChooseOneInstructionTypeKeys.has(type.key) && type.instructionOptions.length > 1 &&
                              <Fragment>
                                {` (${t('order:step_instruction:choose_one')})`}
                              </Fragment>
                            }
                          </h3>
                        </Fragment>
                      }
                    </Fragment>
                  }
                  {
                    type?.instructionOptions
                      .filter(instructionOption => (onlySelected && instructionOption.selected) || !onlySelected)
                      .sort((instrA, instrB) => bigFromFee(instrA.feePrice).minus(bigFromFee(instrB.feePrice)).toNumber())
                      .map(instructionOption => {
                        const instructionOptionPriceAfterDiscount = valueAfterDiscount(instructionOption.feePrice, discount)

                        return (
                          <Fragment key={`${typeKey}_${instructionOption.id}`}>
                            {includeType &&
                              <Fragment>
                                <CheckCard
                                  className={styles.checkcard}
                                  checkbox={disabledSelection || instructionOption.isAlwaysSelected ? undefined : ChooseOneInstructionTypeKeys.has(type.key) ? 'circle' : 'square'}
                                  onCheck={disabledSelection && !instructionOption.isAlwaysSelected ? () => { return } : checked => {
                                    const newInstructionsState = cloneDeep(instructionSelection)
                                    const instructionType = newInstructionsState?.[typeKey]
                                    if (!instructionType) throw new Error(`Instruction type with instruction type key: ${typeKey} is missing. (instructionType=${instructionType})`)
                                    for (const option of instructionType.instructionOptions) {
                                      if (option.id === instructionOption.id) option.selected = checked
                                      else if (checked && ChooseOneInstructionTypeKeys.has(type.key)) option.selected = false
                                    }
                                    setInstructions(newInstructionsState)
                                  }}
                                  checked={!!instructionOption.selected}
                                  innerClickable={!disabledSelection && !instructionOption.isAlwaysSelected}
                                  right={bigFromFee(instructionOption.feePrice).eq(0) ? <hr /> : (
                                    <Fragment>
                                      {discount.gt(0) &&
                                        <span className="line-through gray-text">{formatPrice(instructionOption.feePrice, currency)}</span>
                                      }
                                      <strong>{formatPrice(instructionOptionPriceAfterDiscount, currency)}</strong>
                                    </Fragment>
                                  )}
                                  expandable={instructionOption.fields?.length === 0 ? undefined : (
                                    <div className={gridOptionKinds.has(ProductKind[instructionOption.kind]) ? 'grid' : ''}>
                                      {instructionOption.fields?.map(field => {
                                        const composedFieldKey = `${typeKey}_${instructionOption.id}_${field.key}`
                                        const emptyRequired = field.required && (field.value as string)?.slice && (field.value as string).length === 0
                                        const changeHandler = (value: any) => {
                                          const newInstructionsState = cloneDeep(instructionSelection)
                                          const instructionType = newInstructionsState?.[typeKey]
                                          if (!instructionType) throw new Error(`Instruction type with instruction type key: ${typeKey} is missing. (instructionType=${instructionType})`)
                                          for (const option of instructionType.instructionOptions) {
                                            if (option.id !== instructionOption.id) continue
                                            for (const f of option.fields || []) {
                                              if (f.key === field.key) f.value = value
                                            }
                                          }
                                          setInstructions(newInstructionsState)
                                        }
                                        const eventChangeHandler = (e: any) => changeHandler(e.target.value)
                                        const datePickerChangeHandler = (date: Date | null) => changeHandler(date)
                                        const telephoneInputChangeHandler = (value: string) => changeHandler(value)
                                        const addressChangeHandler = (value: ReactGeocodePlace | null) => changeHandler(value)
                                        const referenceChangeHandler = (e: any) => changeHandler(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxReferenceCharacters))
                                        const defaultProps = getFieldProps(field.key)
                                        const props: {
                                          name: string
                                          id: string
                                          onChange?: (e: any) => void
                                          value?: string
                                          disabled: boolean
                                          required: boolean
                                          placeholder?: string
                                          className: string
                                          type?: string
                                        } = {
                                          ...defaultProps,
                                          name: composedFieldKey,
                                          id: composedFieldKey,
                                          onChange: eventChangeHandler,
                                          value: typeof (field.value) === 'string' ? field.value : '',
                                          disabled: disabledFields,
                                          required: !!field.required,
                                          placeholder: t(`order:step_instruction:fields:${instructionOption.kind}:${field.key}:placeholder`, '') || undefined,
                                          className: `${defaultProps.className} ${emptyRequired ? 'error-input' : ''}`.trim(),
                                        }
                                        if (field.key === InstructionOptionFieldType.COMMENT && instructionOption.kind === ProductKind.REFERENCE) props.onChange = referenceChangeHandler
                                        if (field.key === InstructionOptionFieldType.COMMENT && instructionOption.kind === ProductKind.REFERENCE) props.className = 'reference'
                                        if (field.key === InstructionOptionFieldType.COMMENT) delete props.type
                                        if (field.key === InstructionOptionFieldType.ADDRESS) {
                                          delete props.value
                                          delete props.onChange
                                        }

                                        if (field.key === InstructionOptionFieldType.USER_TIMEZONE_DATETIME_INSTRUCTIONS) return (
                                          <Fragment key={composedFieldKey}>
                                            {!!locationTimezone && !isEqualTimezone &&
                                              <div className="full-width">
                                                <p className="text-instruction">
                                                  <span className="line">{t('order:step_instruction:timezone_instruction_differs', { userTimezone, locationTimezone })}</span>
                                                  <span className="line">
                                                    <Trans t={t} i18nKey="order:step_instruction:timezone_instruction_location" values={locationTimezone}>
                                                      <strong className="normal-size"></strong>
                                                    </Trans>
                                                  </span>
                                                </p>
                                              </div>
                                            }
                                          </Fragment>
                                        )

                                        if (field.key === InstructionOptionFieldType.USER_TIMEZONE_DATETIME) return (
                                          <Fragment key={composedFieldKey}>
                                            {(() => {
                                              const date = instructionOption.fieldsMap?.get(InstructionOptionFieldType.DATE)?.value
                                              const time = instructionOption.fieldsMap?.get(InstructionOptionFieldType.TIME)?.value
                                              return (
                                                <Fragment>
                                                  {!!date && !!time && date instanceof Date && time instanceof Date && !!locationTimezone && !isEqualTimezone &&
                                                    <Fragment>
                                                      {(() => {
                                                        const startTime = moment(time).utc(true).tz(locationTimezone, true)
                                                        const dateTime = moment(date).utc(true).tz(locationTimezone, true).add(startTime.hours(), 'hours').add(startTime.minutes(), 'minutes')
                                                        return (
                                                          <div className="full-width">
                                                            <p className="text-instruction padding-bottom">
                                                              <span className="line">{getDateString(dateTime.toISOString(), { timezone: locationTimezone })} - {getTimeString(dateTime.toISOString(), { timezone: locationTimezone })} ({locationTimezone} {t('order:step_instruction:timezone')})</span>
                                                              <span className="line">{getDateString(dateTime.tz(userTimezone).toISOString(), { timezone: userTimezone })} - {getTimeString(dateTime.tz(userTimezone).toISOString(), { timezone: userTimezone })} ({userTimezone} {t('order:step_instruction:timezone')})</span>
                                                            </p>
                                                          </div>
                                                        )
                                                      })()}
                                                    </Fragment>
                                                  }
                                                </Fragment>
                                              )
                                            })()}
                                          </Fragment>
                                        )

                                        return (
                                          <Fragment key={composedFieldKey}>
                                            <div className={`input-group ${fullWidthOptionFieldKeys.has(InstructionOptionFieldType[field.key]) ? 'full-width' : ''}`.trim()}>
                                              <label htmlFor={composedFieldKey} className={fullWidthOptionFieldKeys.has(InstructionOptionFieldType[field.key]) ? 'flex' : ''}>
                                                {t(`order:step_instruction:fields:${instructionOption.kind}:${field.key}:label`, t(`instruction_option_field:${field.key}`))}
                                                {field.required &&
                                                  <span className={`nowrap ${emptyRequired ? 'red' : ''}`}>
                                                    &nbsp;({t('order:step_instruction:required_field')})
                                                  </span>
                                                }
                                                {instructionOption.kind === ProductKind.REFERENCE && typeof (field.value) === 'string' && field.key === InstructionOptionFieldType.COMMENT &&
                                                  <span className="end smaller">{t('order:step_instruction:characters_length', { current: field.value.length, total: maxReferenceCharacters })}</span>
                                                }
                                              </label>
                                              {field.key === InstructionOptionFieldType.COMMENT &&
                                                <textarea
                                                  {...props}
                                                ></textarea>
                                              }
                                              {field.key === InstructionOptionFieldType.DATE &&
                                                <DatePicker
                                                  {...{
                                                    ...props,
                                                    value: undefined,
                                                    selected: (field.value instanceof Date) ? field.value : null,
                                                    renderDayContents: (day, date) => {
                                                      if (!date) return null
                                                      const today = moment()
                                                      const momentDate = moment(date)
                                                      const datesBetweenTodayAndMinDateArray = getDatesBetweenDateAndNumberOfBusinessDays(today, isBeforeEndOfWorkingHours(today), 2)
                                                      const isBetweenTodayAndMinDate = momentDate.isBetween(moment(datesBetweenTodayAndMinDateArray[0]), moment(datesBetweenTodayAndMinDateArray[datesBetweenTodayAndMinDateArray.length - 1]), 'day', '[]')
                                                      const hasToolTip = isBetweenTodayAndMinDate
                                                      const renderDayContentsComponent = () => (
                                                        <Fragment>
                                                          {hasToolTip &&
                                                            <ReactTooltip
                                                              id={`${composedFieldKey}_date_${date.toISOString()}_${day}`}
                                                              type="light"
                                                              place="right"
                                                              effect="solid"
                                                            >
                                                              {isBetweenTodayAndMinDate &&
                                                                <div className="tooltip-content date-has-tooltip">
                                                                  <BlockInfo>
                                                                    <h4>{t('order:step_instruction:less_than_mindate', { days: minDaysBeforeShooting })}</h4>
                                                                  </BlockInfo>
                                                                </div>
                                                              }
                                                            </ReactTooltip>
                                                          }
                                                          <span
                                                            className={hasToolTip ? 'has-tooltip' : ''}
                                                            data-tip={hasToolTip ? true : undefined}
                                                            data-for={hasToolTip ? `${composedFieldKey}_date_${date.toISOString()}_${day}` : undefined}
                                                          >
                                                            {day}
                                                          </span>
                                                        </Fragment>
                                                      )
                                                      return renderDayContentsComponent()
                                                    },
                                                    onChange: datePickerChangeHandler,
                                                  }}
                                                  autoComplete="off"
                                                  dateFormat="P"
                                                  minDate={moment().startOf('day').toDate()}
                                                  locale={getUserDatePickerLocale()}
                                                />
                                              }
                                              {field.key === InstructionOptionFieldType.TIME &&
                                                <div ref={ref => datePickerTimeRef.current[instructionOption.id] = ref}>
                                                  <DatePicker
                                                    {...{
                                                      ...props,
                                                      value: undefined,
                                                      selected: (field.value instanceof Date) ? field.value : null,
                                                      onChange: datePickerChangeHandler
                                                    }}
                                                    onChangeRaw={() => handleTimeScroll(instructionOption.id)}
                                                    autoComplete="off"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    dateFormat="p"
                                                    locale={getUserDatePickerLocale()}
                                                  />
                                                </div>
                                              }
                                              {field.key === InstructionOptionFieldType.PHONE &&
                                                <TelephoneInput
                                                  {...{
                                                    ...props,
                                                    value: undefined,
                                                    defaultValue: typeof (field.value) === 'string' ? field.value : '',
                                                    onChange: undefined,
                                                    onValueChange: telephoneInputChangeHandler,
                                                    initialCountry: field.value ? undefined : platformPlace.countryCode,
                                                    withInvalidIndicator: true,
                                                    withInvalidErrorMessages: true,
                                                  }}
                                                />
                                              }
                                              {field.key === InstructionOptionFieldType.ADDRESS &&
                                                <Fragment>
                                                  <AddressMap
                                                    {...{
                                                      ...props,
                                                      className: `${props.className} right`,
                                                      height: '30rem',
                                                      handleChange: (newGooglePlace, isInitialLookup) => {
                                                        if (!isInitialLookup) addressChangeHandler(newGooglePlace)
                                                      },
                                                      initialAddress: InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value) ? field.value.formatted_address : undefined,
                                                      initialMarker: InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value) ? field.value.geometry.location : undefined,
                                                      inputPosition: 'above',
                                                      error: !InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value) ?
                                                        t('step_address.no_valid_address') :
                                                        InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value) && !isAddressValid(field.value) ?
                                                          t('step_address.address_not_selected') :
                                                          undefined,
                                                      language: i18n.language,
                                                      countryCode: platformPlace.countryCode,
                                                    }}
                                                  />
                                                  <span className="selected-address">
                                                    <strong>{t('step_address.selected_address')}</strong>
                                                    {': '}
                                                    {InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value) ?
                                                      <Fragment>{field.value.formatted_address}</Fragment>
                                                      :
                                                      <span className="red-text">{t('step_address.none')}</span>
                                                    }
                                                  </span>
                                                </Fragment>
                                              }
                                              {field.key === InstructionOptionFieldType.TIMEZONE &&
                                                <select
                                                  {...props}
                                                >
                                                  {timezoneNames.map(tzn => (
                                                    <option value={tzn} key={tzn}>{tzn}</option>
                                                  ))}
                                                </select>
                                              }
                                              {
                                                field.key !== InstructionOptionFieldType.COMMENT &&
                                                field.key !== InstructionOptionFieldType.DATE &&
                                                field.key !== InstructionOptionFieldType.TIME &&
                                                field.key !== InstructionOptionFieldType.PHONE &&
                                                field.key !== InstructionOptionFieldType.ADDRESS &&
                                                field.key !== InstructionOptionFieldType.TIMEZONE &&
                                                <input
                                                  {...props}
                                                />
                                              }
                                            </div>
                                            {field.key === InstructionOptionFieldType.TIMEZONE &&
                                              <div className="empty"></div>
                                            }
                                          </Fragment>
                                        )
                                      })}
                                    </div>
                                  )}
                                >
                                  <strong className="title">{t(`product:p_${instructionOption.id}`)}</strong>
                                  {!!t(`product_kind_description:${instructionOption.kind}`, '') &&
                                    <p>
                                      <Trans t={t} i18nKey={`product_kind_description:${instructionOption.kind}`} defaults={''}>
                                        <span className="newline"></span>
                                      </Trans>
                                    </p>
                                  }
                                </CheckCard>
                              </Fragment>
                            }
                          </Fragment>
                        )
                      })}
                </Fragment>
              )
            })}
      </Fragment>
    )
  }

export default InstructionListing
