import { FC } from 'react'
import styles from './BlockInfo.module.sass'

/**
 * @component Display aditional information in a styled orange box
 * @example
 * <BlockInfo color="orange" className="someClass">
 *    <h4>Some Heading</h4>
 *    <p>Some text</p>
 * </BlockInfo>
 */
const BlockInfo: FC<{
  /** The additional classes to append */
  className?: string
  /** Color class of the box */
  color?: 'orange' | 'blue' | 'green' | 'red'
}> = ({
  className = '',
  color = 'orange',
  children,
}) => (
    <div className={`${styles.blockinfo} blockinfo ${styles[color]} ${styles[className]} ${className}`.replace(/\s+/igm, ' ').trim()}>{children}</div>
  )

export default BlockInfo