import { FC, createContext, useContext, useMemo } from 'react'
import { percentageValue, valueAfterDiscount } from 'lib/priceCalculations'

import Big from 'big.js'
import { bigFromFee } from 'lib/decimals'
import { useInstructionSelectionContext } from './InstructionSelectionContext'
import { useProductConfigurationContext } from './ProductConfigurationContext'
import { useProductSelectionContext } from './ProductSelectionContext'

/** Type describing the shape of context value */
export interface SummaryContextType {
  /** total price of all products, options and instructions marked as selected */
  totalPrice: Big
  totalBeforeDiscount: Big
  totalAfterDiscount: Big
  totalVat: Big
  totalAfterVat: Big
}

/** The default context value */
export const defaultSummaryContext: SummaryContextType = {
  totalPrice: new Big(0),
  totalBeforeDiscount: new Big(0),
  totalAfterDiscount: new Big(0),
  totalVat: new Big(0),
  totalAfterVat: new Big(0),
}

/** Context containing all summary data */
export const SummaryContext = createContext<SummaryContextType>(defaultSummaryContext)

/** Hook for user context */
export const useSummaryContext = (): SummaryContextType => useContext(SummaryContext)

/** Provider for SummaryContext validates query params and passes them down */
export const SummaryContextProvider: FC = ({
  children,
}) => {
  const { discount, vat } = useProductConfigurationContext()
  const { productSelectionList } = useProductSelectionContext()
  const { instructionSelectionList } = useInstructionSelectionContext()

  const totalPrice = useMemo(() => {
    const productPrice = productSelectionList.reduce((accumulator, product) => {
      const optionsPrice = product.options.reduce((optionsAccumulator, option) => optionsAccumulator.plus(bigFromFee(option.feePrice).times(option.value)), Big(0))
      return accumulator.plus(bigFromFee(product.feePrice)).plus(optionsPrice)
    }, Big(0))
    const instructionPrice = instructionSelectionList.reduce((accumulator, instructionOption) => accumulator.plus(bigFromFee(instructionOption.feePrice)), Big(0))
    return productPrice.plus(instructionPrice)
  }, [productSelectionList, instructionSelectionList])

  const totalBeforeDiscount = useMemo(() => totalPrice, [totalPrice])
  const totalAfterDiscount = useMemo(() => valueAfterDiscount(totalBeforeDiscount, discount), [totalBeforeDiscount, discount])
  const totalVat = useMemo(() => percentageValue(totalAfterDiscount, vat), [totalAfterDiscount, vat])
  const totalAfterVat = useMemo(() => totalAfterDiscount.plus(totalVat), [totalAfterDiscount, totalVat])

  return (
    <SummaryContext.Provider
      value={{
        totalPrice,
        totalBeforeDiscount,
        totalAfterDiscount,
        totalVat,
        totalAfterVat,
      }}
    >
      {children}
    </SummaryContext.Provider>
  )
}