import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { ProductConfigurationDTO, ProductConfigurationRequestBody } from 'types/ProductConfiguration'

import { APIRequestState } from 'constants/APIState'
import Big from 'big.js'
import { Currency } from 'constants/Currency'
import { Endpoints } from 'constants/Endpoints'
import { ProductSegment } from 'types/ProductSegment'
import { ShootingCategory } from 'types/ProductCategory'
import axios from 'axios'
import { bigFromDecimal } from 'lib/decimals'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from 'lib/API'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useUserContext } from 'contexts/UserContext'

/** Type describing the shape of context value */
export interface ProductConfigurationContextType {
  /** Indicates the state of product configuration request */
  productConfigurationAPIRequestState: APIRequestState
  /** Contains the whole platform product configuration DTO */
  productConfiguration: ProductConfigurationDTO | undefined
  /** Indicates if product configuration is being loaded */
  productConfigurationLoading: boolean
  /** Indicates if product configuration was successfully loaded */
  productConfigurationLoaded: boolean
  /** Indicates if product configuration failed to load */
  productConfigurationFailed: boolean
  /** List of all available product segments */
  availableProductSegments: ProductSegment[]
  /** Currency of the products */
  currency?: Currency
  /** Percentage discount */
  discount: Big
  /** Percentage VAT */
  vat: Big
}

/** The default context value */
export const defaultProductConfigurationContext: ProductConfigurationContextType = {
  productConfigurationAPIRequestState: APIRequestState.BEFORE_START,
  productConfiguration: undefined,
  productConfigurationLoading: false,
  productConfigurationLoaded: false,
  productConfigurationFailed: false,
  availableProductSegments: [],
  discount: new Big(0),
  vat: new Big(0),
}

/** Context containing all product configuration related data */
export const ProductConfigurationContext = createContext<ProductConfigurationContextType>(defaultProductConfigurationContext)

/** Hook for product configuration context */
export const useProductConfigurationContext = (): ProductConfigurationContextType => useContext(ProductConfigurationContext)

/** Provider for ProductConfigurationContext which calls product configuration endpoint upon mounting */
export const ProductConfigurationContextProvider: FC = ({
  children,
}) => {
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const [productConfigurationAPIRequestState, setProductConfigurationAPIRequestState] = useState<ProductConfigurationContextType['productConfigurationAPIRequestState']>(APIRequestState.BEFORE_START)
  const [productConfiguration, setProductConfiguration] = useState<ProductConfigurationContextType['productConfiguration']>(undefined)

  const productConfigurationLoading = useMemo(() => productConfigurationAPIRequestState === APIRequestState.RUNNING, [productConfigurationAPIRequestState])
  const productConfigurationLoaded = useMemo(() => productConfigurationAPIRequestState === APIRequestState.COMPLETE && !!productConfiguration, [productConfigurationAPIRequestState, productConfiguration])
  const productConfigurationFailed = useMemo(() => productConfigurationAPIRequestState === APIRequestState.ERROR, [productConfigurationAPIRequestState])
  const availableProductSegments = useMemo(() => productConfigurationLoaded && productConfiguration ? Object.values(productConfiguration.segments).map(segment => ProductSegment[segment.key]).sort() : [], [productConfigurationLoaded, productConfiguration])

  /** Fetch product configuration */
  useEffect(() => {
    const fetchProductConfiguration = async () => {
      if (!userProfile) return
      if (!platformPlace) return
      if (!addressIsValid) return

      setProductConfigurationAPIRequestState(APIRequestState.BEFORE_START)

      const { email } = userProfile
      const body: ProductConfigurationRequestBody = {
        email,
        category: ShootingCategory.REAL_ESTATE,
        countryCode: platformPlace.countryCode,
      }

      // Validate type of request body
      const decodedBody = ProductConfigurationRequestBody.decode(body)
      pipe(
        decodedBody,
        fold(
          errors => {
            const report = reporter.report(decodedBody)
            console.error(report, errors)
            setProductConfiguration(undefined)
            setProductConfigurationAPIRequestState(APIRequestState.ERROR)
          },
          // Call API with validated request body
          async validatedBody => {
            try {
              setProductConfigurationAPIRequestState(APIRequestState.RUNNING)
              const URL = getRemoteAPIURL(Endpoints.ORDER_CONFIGURATION_PRODUCT)
              const response = await axios.post<ProductConfigurationDTO>(URL, validatedBody)

              /** Validate type of response data */
              const decodedResponseData = ProductConfigurationDTO.decode(response.data)
              pipe(
                decodedResponseData,
                fold(
                  errors => {
                    const report = reporter.report(decodedResponseData)
                    console.error(report, errors)
                    setProductConfiguration(undefined)
                    setProductConfigurationAPIRequestState(APIRequestState.ERROR)
                  },
                  // Set result
                  validatedResponseData => {
                    setProductConfiguration(validatedResponseData)
                    setProductConfigurationAPIRequestState(APIRequestState.COMPLETE)
                  }
                )
              )
            } catch (error) {
              console.error(error)
              setProductConfiguration(undefined)
              setProductConfigurationAPIRequestState(APIRequestState.ERROR)
            }
          }
        )
      )
    }
    fetchProductConfiguration()
  }, [userProfile, platformPlace, addressIsValid])

  const currency = useMemo(() => productConfiguration ? Currency[productConfiguration.currency] : undefined, [productConfiguration])
  const discount = useMemo(() => bigFromDecimal(productConfiguration?.decimalDiscount.value || 0), [productConfiguration])
  const vat = useMemo(() => bigFromDecimal(productConfiguration?.decimalVat.value || 0), [productConfiguration])

  return (
    <ProductConfigurationContext.Provider
      value={{
        productConfigurationAPIRequestState,
        productConfiguration,
        productConfigurationLoading,
        productConfigurationLoaded,
        productConfigurationFailed,
        availableProductSegments,
        currency,
        discount,
        vat,
      }}
    >
      {children}
    </ProductConfigurationContext.Provider>
  )
}