import { ExtendedInstructionOptionSelectionDTO, InstructionOptionFieldValueTypeIsReactGeocodePlace } from 'types/InstructionConfiguration'
import { FC, createContext, useCallback, useContext, useMemo, useState } from 'react'
import { KeysPickupDTO, MeetingOnSiteDTO, OrganizationThirdPartyDTO } from 'types/PurchaseOrganization'
import { OrderCustomerIndetifierDTO, OrderDTO, PlatformOrderDTO, PrepareOrderDTO } from 'types/Order'

import { APIRequestState } from 'constants/APIState'
import { Endpoints } from 'constants/Endpoints'
import { InstructionOptionFieldType } from 'types/InstructionOptionFieldType'
import { ProductKind } from 'types/ProductKind'
import { ProductQuantityDTO } from 'types/Product'
import { ReactGeocodePlace } from 'components/AddressMap/AddressMap'
import { ShootingCategory } from 'types/ProductCategory'
import axios from 'axios'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from '../lib/API'
import { getUserTimezone } from 'lib/timezoneUtils'
import moment from 'moment-timezone'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useAddressSelectionContext } from './AddressSelectionContext'
import { useInitialQueryContext } from './InitialQueryContext'
import { useInstructionConfigurationContext } from './InstructionConfigurationContext'
import { useInstructionSelectionContext } from './InstructionSelectionContext'
import { useProductSelectionContext } from './ProductSelectionContext'
import { useUserContext } from './UserContext'

/** Type describing the shape of context value */
export interface OrderPlacementContextType {
  /** A method which places an order from the contexted selection */
  placeOrder: () => void
  /** Indicates the state of order placement request */
  orderPlacementAPIRequestState: APIRequestState
  /** Contains the whole platform order placement DTO */
  orderPlacement?: OrderDTO
  /** Indicates if order placement is being loaded */
  orderPlacementLoading: boolean
  /** Indicates if order placement was successfully loaded */
  orderPlacementLoaded: boolean
  /** Indicates if order placement failed to load */
  orderPlacementFailed: boolean
}

/** The default context value */
export const defaultOrderPlacementContext: OrderPlacementContextType = {
  placeOrder: () => { throw new Error('placeOrder is undefined') },
  orderPlacementAPIRequestState: APIRequestState.BEFORE_START,
  orderPlacementLoading: false,
  orderPlacementLoaded: false,
  orderPlacementFailed: false,
}

/** Context containing all orderPlacement data */
export const OrderPlacementContext = createContext<OrderPlacementContextType>(defaultOrderPlacementContext)

/** Hook for user context */
export const useOrderPlacementContext = (): OrderPlacementContextType => useContext(OrderPlacementContext)

/** Provider for OrderPlacementContext validates query params and passes them down */
export const OrderPlacementContextProvider: FC = ({
  children,
}) => {
  const { initialQueryParams } = useInitialQueryContext()
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const { locationTimezone } = useInstructionConfigurationContext()
  const { productSelectionList, selectedProductSegmentKey } = useProductSelectionContext()
  const { instructionSelectionList, allRequiredInstructionsFilled } = useInstructionSelectionContext()

  const [orderPlacementAPIRequestState, setOrderPlacementAPIRequestState] = useState<OrderPlacementContextType['orderPlacementAPIRequestState']>(APIRequestState.BEFORE_START)
  const [orderPlacement, setOrderPlacement] = useState<OrderPlacementContextType['orderPlacement']>(undefined)

  const orderPlacementLoading = useMemo(() => orderPlacementAPIRequestState === APIRequestState.RUNNING, [orderPlacementAPIRequestState])
  const orderPlacementLoaded = useMemo(() => orderPlacementAPIRequestState === APIRequestState.COMPLETE && !!orderPlacement, [orderPlacementAPIRequestState, orderPlacement])
  const orderPlacementFailed = useMemo(() => orderPlacementAPIRequestState === APIRequestState.ERROR, [orderPlacementAPIRequestState])

  const prepareOrder = useCallback((): PlatformOrderDTO => {
    if (!userProfile) throw new Error('userProfile is falsy')
    if (!platformPlace) throw new Error('platformPlace is falsy')
    if (!addressIsValid) throw new Error('addressIsValid is falsy')
    if (!selectedProductSegmentKey) throw new Error('selectedProductSegmentKey is falsy')
    if (productSelectionList.length === 0) throw new Error('productSelectionList is empty')
    if (!allRequiredInstructionsFilled) throw new Error('allRequiredInstructionsFilled is falsy')

    const getProductsList = (): ProductQuantityDTO[] => {
      const quantityList: ProductQuantityDTO[] = []
      for (const product of productSelectionList) {
        const productItem = {
          id: product.id,
          count: 1,
          isOption: false,
        }
        quantityList.push(productItem)
        for (const option of product.options) {
          if (option.value < 1) continue
          const optionItem = {
            id: option.id,
            count: option.value,
            isOption: true,
          }
          quantityList.push(optionItem)
        }
      }

      return quantityList
    }

    const getInstructionsList = (): ProductQuantityDTO[] => {
      const quantityList: ProductQuantityDTO[] = []
      for (const instruction of instructionSelectionList) {
        const instructionItem = {
          id: instruction.id,
          count: 1,
          isOption: false,
        }
        quantityList.push(instructionItem)
      }

      return quantityList
    }

    const instructionOptionsToMap = () => {
      const map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO> = new Map()

      for (const instruction of instructionSelectionList) {
        const structuredFields: Map<InstructionOptionFieldType, string> = new Map()
        for (const field of instruction.fields || []) {
          let optionStringValue = ''
          if (InstructionOptionFieldValueTypeIsReactGeocodePlace(field.value)) optionStringValue = JSON.stringify(field.value)
          else if (typeof field.value === 'string') optionStringValue = field.value
          else if (field.value instanceof Date) optionStringValue = field.value.toISOString()
          else optionStringValue = ''
          structuredFields.set(InstructionOptionFieldType[field.key], optionStringValue)
        }
        const extendedOption: ExtendedInstructionOptionSelectionDTO = {
          ...instruction,
          structuredFields,
        }
        map.set(ProductKind[instruction.kind], extendedOption)
      }

      return map
    }

    const getBillingAddress = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>) => {
      return map ? '' : ''
      // TODO: Enable BillingAddress
      // const comment = map.get(ProductKind.BILLING_ADDRESS)?.structuredFields.get(InstructionOptionFieldType.COMMENT)

      // if (!comment) throw new Error('BillingAddress not specified')

      // return comment || ''
    }

    const getExternalReporting = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>) => {
      if (!map.get(ProductKind.EXTERNAL_REPORTING)?.selected) return undefined

      const comment = map.get(ProductKind.EXTERNAL_REPORTING)?.structuredFields.get(InstructionOptionFieldType.COMMENT)
      if (!comment) throw new Error('ExternalReporting not specified')

      return comment || ''
    }

    const getProductionComments = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>): string | undefined => {
      if (!map.get(ProductKind.PRODUCTION_COMMENTS)?.selected) return undefined

      const comment = map.get(ProductKind.PRODUCTION_COMMENTS)?.structuredFields.get(InstructionOptionFieldType.COMMENT)

      if (!comment) return undefined

      return comment
    }

    const getReference = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>): string | undefined => {
      if (!map.get(ProductKind.REFERENCE)?.selected) return undefined

      const comment = map.get(ProductKind.REFERENCE)?.structuredFields.get(InstructionOptionFieldType.COMMENT)

      if (!comment) return undefined

      return comment
    }

    const getMeetingOnSite = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>): MeetingOnSiteDTO | undefined => {
      if (!map.get(ProductKind.MEETING_ON_SITE)?.selected) return undefined

      const startDate = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.DATE)
      const startTime = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.TIME)
      const name = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.NAME)
      const phone = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.PHONE)
      const email = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.EMAIL)
      const comment = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.COMMENT)
      const timezoneInput = map.get(ProductKind.MEETING_ON_SITE)?.structuredFields.get(InstructionOptionFieldType.TIMEZONE)

      if (!startDate) throw new Error('MeetingOnSite date not specified')
      if (!startTime) throw new Error('MeetingOnSite time not specified')
      if (!name) throw new Error('MeetingOnSite name not specified')
      if (!phone) throw new Error('MeetingOnSite phone not specified')

      const timezone = locationTimezone || timezoneInput || getUserTimezone(userProfile)
      const startTimeMoment = moment(startTime).utc(true).tz(timezone, true)
      const dateTime = moment(startDate).utc(true).tz(timezone, true).add(startTimeMoment.hours(), 'hours').add(startTimeMoment.minutes(), 'minutes')
      return {
        name: name || '',
        phone: phone || '',
        email,
        comment,
        date: dateTime.toISOString(),
        timezone,
      }
    }

    const getOrganizationThirdParty = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>): OrganizationThirdPartyDTO | undefined => {
      if (!map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.selected) return undefined

      const name = map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.structuredFields.get(InstructionOptionFieldType.NAME)
      const phone = map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.structuredFields.get(InstructionOptionFieldType.PHONE)
      const email = map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.structuredFields.get(InstructionOptionFieldType.EMAIL)
      const comment = map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.structuredFields.get(InstructionOptionFieldType.COMMENT)
      const timezoneInput = map.get(ProductKind.ORGANIZATION_THIRD_PARTY)?.structuredFields.get(InstructionOptionFieldType.TIMEZONE)

      if (!name) throw new Error('OrganizationThirdParty name not specified')
      if (!phone) throw new Error('OrganizationThirdParty phone not specified')

      const timezone = locationTimezone || timezoneInput || getUserTimezone(userProfile)

      return {
        name: name || '',
        phone: phone || '',
        email,
        comment,
        timezone,
      }
    }

    const getKeysPickup = (map: Map<ProductKind, ExtendedInstructionOptionSelectionDTO>): KeysPickupDTO | undefined => {
      if (!map.get(ProductKind.KEYS_PICKUP)?.selected) return undefined

      const startDate = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.DATE)
      const startTime = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.TIME)
      const name = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.NAME)
      const phone = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.PHONE)
      const email = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.EMAIL)
      const address = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.ADDRESS)
      const comment = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.COMMENT)
      const timezoneInput = map.get(ProductKind.KEYS_PICKUP)?.structuredFields.get(InstructionOptionFieldType.TIMEZONE)

      if (!startDate) throw new Error('KeysPickup date not specified')
      if (!startTime) throw new Error('KeysPickup time not specified')
      if (!name) throw new Error('KeysPickup name not specified')
      if (!phone) throw new Error('KeysPickup phone not specified')
      if (!address) throw new Error('KeysPickup address not specified')

      const timezone = locationTimezone || timezoneInput || getUserTimezone(userProfile)
      const startTimeMoment = moment(startTime).utc(true).tz(timezone, true)
      const dateTime = moment(startDate).utc(true).tz(timezone, true).add(startTimeMoment.hours(), 'hours').add(startTimeMoment.minutes(), 'minutes')
      const returnObject = {
        name: name || '',
        phone: phone || '',
        email,
        comment,
        date: dateTime.toISOString(),
        address: '',
        coordinates: { lat: 0, lng: 0 },
        timezone,
      }

      try {
        const parsedAddress: ReactGeocodePlace | null = JSON.parse(address)
        if (!parsedAddress) throw new Error('KeysPickup address is empty')
        returnObject.address = parsedAddress.formatted_address
        returnObject.coordinates = parsedAddress.geometry.location
      } catch (e) {
        throw new Error('KeysPickup address is invalid')
      }

      return returnObject
    }

    const instructionMap = instructionOptionsToMap()

    const platformOrder: PlatformOrderDTO = {
      email: userProfile.email,
      countryCode: platformPlace.countryCode,
      address: platformPlace.address,
      coordinates: platformPlace.coordinate,
      category: ShootingCategory.REAL_ESTATE,
      segment: selectedProductSegmentKey,
      products: [
        ...getProductsList(),
        ...getInstructionsList(),
      ],
      // Instructions
      comments: getProductionComments(instructionMap),
      meetingOnSite: getMeetingOnSite(instructionMap),
      organizationThirdParty: getOrganizationThirdParty(instructionMap),
      keysPickup: getKeysPickup(instructionMap),
      // Billing
      billingAddress: getBillingAddress(instructionMap),
      externalReporting: getExternalReporting(instructionMap),
      // Other
      reference: getReference(instructionMap),
    }

    return platformOrder
  }, [userProfile, platformPlace, addressIsValid, selectedProductSegmentKey, productSelectionList, allRequiredInstructionsFilled, instructionSelectionList, locationTimezone])

  const placeOrder = useCallback(async () => {
    if (!initialQueryParams) throw new Error('initialQueryParams is undefined')
    const { userId, customerWebId, apiClaim, parameterCacheId } = initialQueryParams
    const platformOrderDTO = prepareOrder()
    const orderCustomerIndetifierDTO: OrderCustomerIndetifierDTO = {
      userId,
      customerWebId,
      apiClaim,
      parameterCacheId,
    }
    const body: PrepareOrderDTO = {
      platformOrderDTO,
      ...orderCustomerIndetifierDTO,
    }

    /** Validate request body */
    const decodedBody = PrepareOrderDTO.decode(body)
    pipe(
      decodedBody,
      fold(
        errors => {
          const report = reporter.report(decodedBody)
          console.error(report, errors)
          setOrderPlacementAPIRequestState(APIRequestState.ERROR)
        },
        // Call API
        async bodyValidated => {
          try {
            setOrderPlacementAPIRequestState(APIRequestState.RUNNING)
            const URL = getRemoteAPIURL(Endpoints.ORDER_PERSIST)
            const response = await axios.post<OrderDTO>(URL, bodyValidated)

            /** Validate response */
            const responseDecoded = OrderDTO.decode(response.data)
            pipe(
              responseDecoded,
              fold(
                errors => {
                  const report = reporter.report(responseDecoded)
                  console.error(report, errors)
                  setOrderPlacementAPIRequestState(APIRequestState.ERROR)
                },
                // Call API
                responseValidated => {
                  // Set result
                  setOrderPlacement(responseValidated)
                  // Post message to parent
                  window.parent.postMessage(JSON.stringify(responseValidated), '*')
                  setOrderPlacementAPIRequestState(APIRequestState.COMPLETE)
                }
              )
            )
          } catch (error) {
            console.error(error)
            setOrderPlacementAPIRequestState(APIRequestState.ERROR)
          }
        }
      )
    )
  }, [initialQueryParams, prepareOrder])

  return (
    <OrderPlacementContext.Provider
      value={{
        placeOrder,
        orderPlacementAPIRequestState,
        orderPlacementLoading,
        orderPlacementLoaded,
        orderPlacementFailed,
      }}
    >
      {children}
    </OrderPlacementContext.Provider>
  )
}