import { FC, Fragment } from 'react'

import { AddressConfigurationContextProvider } from 'contexts/AddressConfigurationContext'
import { AddressSelectionContextProvider } from 'contexts/AddressSelectionContext'
import { ConfirmationQueryContextProvider } from 'contexts/ConfirmationQueryContext'
import { ErrorQueryContextProvider } from 'contexts/ErrorQueryContext'
import { InitialQueryContextProvider } from 'contexts/InitialQueryContext'
import { InstructionConfigurationContextProvider } from 'contexts/InstructionConfigurationContext'
import { InstructionSelectionContextProvider } from 'contexts/InstructionSelectionContext'
import { OrderPlacementContextProvider } from 'contexts/OrderPlacementContext'
import { ParsedUrlQuery } from 'node:querystring'
import { ProductConfigurationContextProvider } from 'contexts/ProductConfigurationContext'
import { ProductSelectionContextProvider } from 'contexts/ProductSelectionContext'
import { QueryContextProvider } from 'contexts/QueryContext'
import { SummaryContextProvider } from 'contexts/SummaryContext'
import { TranslationContextProvider } from 'contexts/TranslationContext'
import { UserContextProvider } from 'contexts/UserContext'

/** A higher-order wrapper component containign all contexts as a stack */
const ContextStack: FC<{
  query: ParsedUrlQuery
}> = ({
  query,
  children,
}) => {
    return (
      <Fragment>
        <TranslationContextProvider>
          <QueryContextProvider query={query}>
            <ErrorQueryContextProvider>
              <ConfirmationQueryContextProvider>
                <InitialQueryContextProvider>
                  <UserContextProvider>
                    <AddressConfigurationContextProvider>
                      <AddressSelectionContextProvider>
                        <ProductConfigurationContextProvider>
                          <ProductSelectionContextProvider>
                            <InstructionConfigurationContextProvider>
                              <InstructionSelectionContextProvider>
                                <SummaryContextProvider>
                                  <OrderPlacementContextProvider>
                                    {children}
                                  </OrderPlacementContextProvider>
                                </SummaryContextProvider>
                              </InstructionSelectionContextProvider>
                            </InstructionConfigurationContextProvider>
                          </ProductSelectionContextProvider>
                        </ProductConfigurationContextProvider>
                      </AddressSelectionContextProvider>
                    </AddressConfigurationContextProvider>
                  </UserContextProvider>
                </InitialQueryContextProvider>
              </ConfirmationQueryContextProvider>
            </ErrorQueryContextProvider>
          </QueryContextProvider>
        </TranslationContextProvider>
      </Fragment>
    )
  }
export default ContextStack