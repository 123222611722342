import * as t from 'io-ts'

import { Coordinates } from './Coordinates'

export const OrganizationThirdPartyDTO = t.intersection([
  t.type({
    name: t.string,
    phone: t.string,
    timezone: t.string,
  }),
  t.partial({
    email: t.string,
    comment: t.string,
  })
])
export type OrganizationThirdPartyDTO = t.TypeOf<typeof OrganizationThirdPartyDTO>

export const KeysPickupDTO = t.intersection([
  OrganizationThirdPartyDTO,
  t.type({
    date: t.string, // ISO-8061 t.string,
    address: t.string,
    coordinates: Coordinates,
  })
])
export type KeysPickupDTO = t.TypeOf<typeof KeysPickupDTO>

export const MeetingOnSiteDTO = t.intersection([
  OrganizationThirdPartyDTO,
  t.type({
    date: t.string, // ISO-8061 String
  })
])
export type MeetingOnSiteDTO = t.TypeOf<typeof MeetingOnSiteDTO>