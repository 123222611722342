import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ProductKindDTO, ProductSegmentDTO, ProductSegmentDTODictionary } from 'types/ProductConfiguration'
import { cloneDeep, isEqual } from 'lodash'

import Immutable from 'immutable'
import { ProductSegment } from '../types/ProductSegment'
import { ProductType } from '../types/ProductType'
import { bigFromFee } from 'lib/decimals'
import usePrevious from 'lib/hooks/usePrevious'
import { useProductConfigurationContext } from './ProductConfigurationContext'

/** Type describing the shape of context value */
export interface ProductSelectionContextType {
  /** Selected product segment key */
  selectedProductSegmentKey?: ProductSegment
  /** Selected product segment key */
  selectedProductSegment?: ProductSegmentDTO
  /** A method for selecting product segment */
  selectProductSegment: (segment?: ProductSegment) => void
  /** An immutable set containing all selected product types */
  selectedProductTypes: Immutable.Set<ProductType>
  /** A method for selecting/unselecting product types */
  toggleProductType: (type: ProductType) => void
  /** A list of all available product types (for given segment) */
  availableProductTypes: ProductType[]
  /** Selected products Dictionary object */
  productSelection?: ProductSegmentDTODictionary
  /** A list of products and options marked as selected */
  productSelectionList: ProductKindDTO[]
  /** A number representing a sum duration of minutes spent by creative on site */
  productSelectionDuration: number
  /** A method for setting product selection */
  setProducts: (products?: ProductSegmentDTODictionary) => void
  /** A method which resets product selection to original state */
  resetProductSelection: () => void
}

/** The default context value */
export const defaultProductSelectionContext: ProductSelectionContextType = {
  selectProductSegment: () => { throw new Error('selectProductSegment is undefined') },
  selectedProductTypes: Immutable.Set(),
  toggleProductType: () => { throw new Error('toggleProductType is undefined') },
  availableProductTypes: [],
  productSelectionList: [],
  productSelectionDuration: 0,
  setProducts: () => { throw new Error('setProducts is undefined') },
  resetProductSelection: () => { throw new Error('resetProductSelection is undefined') },
}

/** Context containing all product selection related data */
export const ProductSelectionContext = createContext<ProductSelectionContextType>(defaultProductSelectionContext)

/** Hook for product selection context */
export const useProductSelectionContext = (): ProductSelectionContextType => useContext(ProductSelectionContext)

/** Provider for ProductSelectionContext */
export const ProductSelectionContextProvider: FC = ({
  children,
}) => {
  const { productConfigurationLoaded, productConfiguration } = useProductConfigurationContext()
  const previousProductConfiguration = usePrevious(productConfiguration)
  const [selectedProductSegmentKey, setSelectedProductSegmentKey] = useState<ProductSelectionContextType['selectedProductSegmentKey']>(undefined)
  const [selectedProductTypes, setSelectedProductTypes] = useState<ProductSelectionContextType['selectedProductTypes']>(Immutable.Set())
  const [productSelection, setProductSelection] = useState<ProductSelectionContextType['productSelection']>(undefined)

  const selectProductSegment = useCallback((segment?: ProductSegment) => {
    setSelectedProductSegmentKey(segment)
  }, [])

  const toggleProductType = useCallback((type: ProductType) => {
    setSelectedProductTypes(oldTypes => {
      if (oldTypes.has(type)) return oldTypes.remove(type)
      else return oldTypes.add(type)
    })
  }, [])

  const setProducts = useCallback((products?: ProductSegmentDTODictionary) => {
    setProductSelection(products)
  }, [])

  const resetProductSelection = useCallback(() => {
    const onlySegment = Object.keys(productConfiguration?.segments || {}).length === 1 ? productConfiguration?.segments[0]?.key : undefined
    setSelectedProductSegmentKey(onlySegment ? ProductSegment[onlySegment] : undefined)
    setSelectedProductTypes(Immutable.Set())
    setProductSelection(productConfiguration ? cloneDeep(productConfiguration.segments) : undefined)
  }, [productConfiguration])

  /** Reset product selection upon product configuration change */
  useEffect(() => {
    if (isEqual(productConfiguration, previousProductConfiguration)) return
    resetProductSelection()
  }, [resetProductSelection, productConfiguration, previousProductConfiguration])

  const selectedProductSegment = useMemo(() => productConfigurationLoaded && productSelection && selectedProductSegmentKey ? productSelection[selectedProductSegmentKey] : undefined, [productConfigurationLoaded, productSelection, selectedProductSegmentKey])
  const availableProductTypes = useMemo(() => selectedProductSegment ? Object.values(selectedProductSegment.productTypes).map(type => ProductType[type.key]) : [], [selectedProductSegment])

  const productSelectionList = useMemo(() => {
    const list: ProductKindDTO[] = []
    if (!selectedProductSegment) return list
    for (const productType of Object.values(selectedProductSegment.productTypes)) {
      if (!selectedProductTypes.has(ProductType[productType.key])) continue
      const sortedProductObjects = Object.values(productType.products).sort((prodA, prodB) => bigFromFee(prodA.feePrice).minus(bigFromFee(prodB.feePrice)).toNumber())
      for (const productObject of sortedProductObjects) {
        if (!productObject.selected) continue
        const product = cloneDeep(productObject)
        product.options = product.options.sort((optionA, optionB) => bigFromFee(optionA.feePrice).minus(bigFromFee(optionB.feePrice)).toNumber())
        list.push(product)
      }
    }
    return list
  }, [selectedProductSegment, selectedProductTypes])

  const productSelectionDuration = useMemo(() => productSelectionList.reduce((amount, product) => amount + product.shootingDuration + product.options.filter(option => !!option.value).reduce((optionAmount, option) => optionAmount + (option.value * (option.duration || 0)), 0), 0), [productSelectionList])

  return (
    <ProductSelectionContext.Provider
      value={{
        selectedProductSegmentKey,
        selectedProductSegment,
        selectProductSegment,
        selectedProductTypes,
        toggleProductType,
        availableProductTypes,
        productSelection,
        productSelectionList,
        productSelectionDuration,
        setProducts,
        resetProductSelection,
      }}
    >
      {children}
    </ProductSelectionContext.Provider>
  )
}