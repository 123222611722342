import { defaultTimezone } from './timezoneUtils'
import moment from 'moment-timezone'

/** Utility function to return boolean value whether the day is business day (Monday-Friday) */
export const isBusinessDay = (date: moment.Moment, timezone = defaultTimezone) => {
  const selectedDate = date.tz(timezone)
  return selectedDate.isoWeekday() <= 5
}

/** Utility function to return an array of dates between date and number of business days, defaults to parameter timezone = defaultTimezone = 'Europe/Zurich'
 * @example getDatesBetweenDateAndNumberOfBusinessDays(new Date('2021-05-17T17:00:00+0200'), true, 5)
 */
export const getDatesBetweenDateAndNumberOfBusinessDays = (date: Date | moment.Moment = moment(), isStartingDayIncluded: boolean, businessDaysNumber: number, timezone = defaultTimezone): Date[] => {
  if (businessDaysNumber < 1) throw new Error('Invalid number of business days')

  let selectedDate = date instanceof Date ? moment(date).tz(timezone) : date.tz(timezone)
  if (!isStartingDayIncluded && isBusinessDay(selectedDate)) businessDaysNumber++

  const dates = []
  let count = 0

  while (count < businessDaysNumber) {
    dates.push(selectedDate.toDate())
    if (isBusinessDay(selectedDate)) count++
    selectedDate = selectedDate.clone().add(1, 'd')
  }

  return dates
}

/** Utility function to return boolean value whether the time is before end of working hours (regardless of particular day), defaults to parameter timezone = defaultTimezone = 'Europe/Zurich'
 * @example isBeforeEndOfWorkingHours(new Date('2021-05-17T17:00:00+0200'))
 */
export const isBeforeEndOfWorkingHours = (time: Date | moment.Moment, timezone = defaultTimezone) => {
  const endOfWorkingHoursHour = 18
  const endOfWorkingHoursMinutes = 0

  const selectedTime = time instanceof Date ? moment(time).tz(timezone) : time.tz(timezone)
  const selectedHour = selectedTime.get('hours')
  const selectedMinutes = selectedTime.get('minutes')

  if (selectedHour < endOfWorkingHoursHour) return true
  if (selectedHour === endOfWorkingHoursHour && selectedMinutes < endOfWorkingHoursMinutes) return true
  else return false
}