import * as Sentry from '@sentry/nextjs'

import { FC, createContext, useContext, useEffect } from 'react'

import { ParsedUrlQuery } from 'node:querystring'

/** Type describing the shape of context value */
export interface QueryContextType {
  /** Unvalidated query params */
  query: ParsedUrlQuery
}

/** The default context value */
export const defaultQueryContext: QueryContextType = {
  query: {}
}

/** Context containing all initial query data */
export const QueryContext = createContext<QueryContextType>(defaultQueryContext)

/** Hook for query context */
export const useQueryContext = (): QueryContextType => useContext(QueryContext)

/** Provider for QueryContext provides query params */
export const QueryContextProvider: FC<{
  query: ParsedUrlQuery,
}> = ({
  query,
  children,
}) => {
    useEffect(() => {
      // Track QueryContext in Sentry
      Sentry.setContext('QueryContext', {
        ...query,
      })
    }, [query])

    return (
      <QueryContext.Provider
        value={{
          query,
        }}
      >
        {children}
      </QueryContext.Provider>
    )
  }