import * as t from 'io-ts'

/** Product Option Type enum - Describes available types of product options */
export enum ProductOptionType {
  /** a certain amount of the option can be ordered */
  INTEGER = 'INTEGER',
  /** the options is included or not */
  BOOLEAN = 'BOOLEAN',
}
export const ProductOptionTypeType = t.keyof(ProductOptionType)
export type ProductOptionTypeType = t.TypeOf<typeof ProductOptionTypeType>