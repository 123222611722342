import { FC, Fragment } from 'react'

import AlertMessage from 'components/AlertMessage/AlertMessage'
import { useErrorQueryContext } from 'contexts/ErrorQueryContext'
import { useTranslation } from 'react-i18next'
import utilStyles from 'styles/utils.module.sass'

const ErrorPage: FC = ({
  children
}) => {
  const { t } = useTranslation('onoffice')
  const { errorQueryParams } = useErrorQueryContext()

  return (
    <AlertMessage fullPage>
      <h1>{t('error')}</h1>
      <div className={utilStyles.queryGrid}>
        {Object.entries(errorQueryParams || {}).map(([key, value]) => (
          <Fragment key={key}>
            <strong className={utilStyles.queryGridElement}>{key}</strong>
            <span className={utilStyles.queryGridElement}>{value}</span>
          </Fragment>
        ))}
        {children}
      </div>
    </AlertMessage>
  )
}

export default ErrorPage