import * as t from 'io-ts'

import { Coordinates } from './Coordinates'
import { ProductCategory } from './ProductCategory'

/** A body sent in the address configuration request */
export const AddressConfigurationRequestBody = t.type({
  email: t.string,
  estateId: t.string,
  customerWebId: t.string,
  apiClaim: t.string,
  category: ProductCategory,
})
export type AddressConfigurationRequestBody = t.TypeOf<typeof AddressConfigurationRequestBody>

/** A DTO received for address configuration */
export const AddressConfigurationDTO = t.type({
  visible: t.boolean,
  defaultAddress: t.string,
  defaultCountryCode: t.string,
  defaultCoordinates: Coordinates,
})
export type AddressConfigurationDTO = t.TypeOf<typeof AddressConfigurationDTO>