import { FC, Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import AlertMessage from 'components/AlertMessage/AlertMessage'
import supportEmail from 'constants/supportEmail'
import { useInitialQueryContext } from 'contexts/InitialQueryContext'

const InvalidQueryParamsPage: FC = ({
  children
}) => {
  const { t } = useTranslation('onoffice')
  const { initialQueryParams, invalidInitialQueryParamsReport } = useInitialQueryContext()

  return (
    <Fragment>
      {!initialQueryParams &&
        <AlertMessage fullPage>
          <h1>{t('invalid_params')}</h1>
          <p>
            <Trans t={t} i18nKey="onoffice:report_error" values={{ supportEmail }}>
              <a href={`mailto:${supportEmail}`} rel="noopener noreferrer" target="_blank">&nbsp;</a>
            </Trans>
          </p>
          {invalidInitialQueryParamsReport?.map(line => (
            <Fragment key={line}>
              <br />
              <p>{line}</p>
            </Fragment>
          ))}
          {children}
        </AlertMessage>
      }
    </Fragment>
  )
}

export default InvalidQueryParamsPage