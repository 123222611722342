import { FC } from 'react'
import Head from 'next/head'
import styles from './Layout.module.sass'

export const siteTitle = 'BKBN OnOffice'

const Layout: FC = ({
  children
}) => {
  return (
    <div className={styles.container}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content={siteTitle}
        />
        <meta
          name="og:title"
          content={siteTitle}
        />
      </Head>
      <main>{children}</main>
    </div>
  )
}

export default Layout