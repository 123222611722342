import * as Sentry from '@sentry/nextjs'

import { FC, createContext, useContext, useEffect, useState } from 'react'

import { ErrorQueryParams } from 'types/QueryParams'
import { fold } from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useQueryContext } from 'contexts/QueryContext'

/** Type describing the shape of context value */
export interface ErrorQueryContextType {
  /** Object containing all error query params */
  errorQueryParams?: ErrorQueryParams
  /** Whether all error query params were successfuly validated */
  errorQueryParamsValidated: boolean
  /** Array of string containg the report from invalid error query params */
  invalidErrorQueryParamsReport?: string[]
}

/** Object containing all error query params set to empty string */
export const defaultErrorQueryParams: ErrorQueryParams = {
  errorCodes: '',
  message: '',
  status: '',
  timestamp: '',
  signature: '',
}

/** The default context value */
export const defaultErrorQueryContext: ErrorQueryContextType = {
  errorQueryParamsValidated: false,
}

/** Context containing all error query data */
export const ErrorQueryContext = createContext<ErrorQueryContextType>(defaultErrorQueryContext)

/** Hook for user context */
export const useErrorQueryContext = (): ErrorQueryContextType => useContext(ErrorQueryContext)

/** Provider for ErrorQueryContext validates query params and passes them down */
export const ErrorQueryContextProvider: FC = ({
  children,
}) => {
  const { query } = useQueryContext()
  const [errorQueryParams, setErrorQueryParams] = useState<ErrorQueryContextType['errorQueryParams']>(undefined)
  const [errorQueryParamsValidated, setErrorQueryParamsValidated] = useState<ErrorQueryContextType['errorQueryParamsValidated']>(false)
  const [invalidErrorQueryParamsReport, setInvalidErrorQueryParamsReport] = useState<ErrorQueryContextType['invalidErrorQueryParamsReport']>(undefined)

  /** Validate error query params */
  useEffect(() => {
    const { errorCodes, message, status, timestamp, signature } = query
    const queryParams = {
      errorCodes,
      message,
      status,
      timestamp,
      signature,
    }

    const decodedQueryParams = ErrorQueryParams.decode(queryParams)
    pipe(
      decodedQueryParams,
      fold(
        () => {
          const report = reporter.report(decodedQueryParams)
          setInvalidErrorQueryParamsReport(report)
          setErrorQueryParamsValidated(false)
        },
        // Set state with validated query params
        validatedQueryParams => {
          setErrorQueryParams(validatedQueryParams)
          setErrorQueryParamsValidated(true)
          setInvalidErrorQueryParamsReport(undefined)

          // Track ErrorQueryContext in Sentry
          Sentry.setContext('ErrorQueryContext', {
            ...validatedQueryParams,
          })
        }
      )
    )
  }, [query])

  return (
    <ErrorQueryContext.Provider
      value={{
        errorQueryParams: errorQueryParams,
        errorQueryParamsValidated: errorQueryParamsValidated,
        invalidErrorQueryParamsReport: invalidErrorQueryParamsReport,
      }}
    >
      {children}
    </ErrorQueryContext.Provider>
  )
}