import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { InstructionOptionFieldSelectionDTO, InstructionOptionSelectionDTO, InstructionTypeDTODictionary, InstructionTypeSelectionDTODictionary } from 'types/InstructionConfiguration'
import { InstructionOptionFieldType, InstructionOptionFieldTypeType } from 'types/InstructionOptionFieldType'
import { cloneDeep, isEqual } from 'lodash'

import { AlwaysSelectedInstructionKinds } from 'constants/AlwaysSelectedInstructionKinds'
import { ChooseOneInstructionTypeKeys } from 'constants/ChooseOneInstructionTypeKeys'
import Immutable from 'immutable'
import { InstructionType } from 'types/InstructionType'
import { InstructionTypeSortingOrder } from 'constants/InstructionTypeSortingOrder'
import { ProductKind } from 'types/ProductKind'
import { ZERO_DECIMAL } from 'types/Decimal'
import { bigFromFee } from 'lib/decimals'
import { getUserTimezone } from 'lib/timezoneUtils'
import { useInstructionConfigurationContext } from './InstructionConfigurationContext'
import usePrevious from 'lib/hooks/usePrevious'
import { useUserContext } from './UserContext'

/** Type describing the shape of context value */
export interface InstructionSelectionContextType {
  /** A list of all available instruction types (for given segment) */
  availableInstructionTypes: InstructionType[]
  /** Selected instructions Dictionary object */
  instructionSelection?: InstructionTypeSelectionDTODictionary
  /** A list of instructions and options marked as selected */
  instructionSelectionList: InstructionOptionSelectionDTO[]
  /** Indicates how many organization instructions had been selected (only 1 must be selected to proceed) */
  selectedOrganizationCount: number
  /** Indicates how many primary billing instructions had been selected (only 1 must be selected to proceed) */
  selectedPrimaryBillingCount: number
  /** A set containing all instruction types with missing field */
  instructionTypesWithMissingRequiredField: Immutable.Set<InstructionType>
  /** Indicates if some required instruction was not selected or filled in */
  someRequiredMissing: boolean
  /** Indicates if all required information were filled in and client can proceed with the purchase flow */
  allRequiredInstructionsFilled: boolean
  /** A method for setting instruction selection */
  setInstructions: (instructions?: InstructionTypeSelectionDTODictionary) => void
  /** A method which resets instruction selection to original state */
  resetInstructionSelection: () => void
}

/** The default context value */
export const defaultInstructionSelectionContext: InstructionSelectionContextType = {
  availableInstructionTypes: [],
  instructionSelectionList: [],
  selectedOrganizationCount: 0,
  selectedPrimaryBillingCount: 0,
  instructionTypesWithMissingRequiredField: Immutable.Set(),
  someRequiredMissing: true,
  allRequiredInstructionsFilled: false,
  setInstructions: () => { throw new Error('setInstructions is undefined') },
  resetInstructionSelection: () => { throw new Error('resetInstructionSelection is undefined') },
}

/** Context containing all instruction selection related data */
export const InstructionSelectionContext = createContext<InstructionSelectionContextType>(defaultInstructionSelectionContext)

/** Hook for instruction selection context */
export const useInstructionSelectionContext = (): InstructionSelectionContextType => useContext(InstructionSelectionContext)

/** Provider for InstructionSelectionContext */
export const InstructionSelectionContextProvider: FC = ({
  children,
}) => {
  const { userProfile } = useUserContext()
  const { instructionConfigurationLoaded, instructionConfiguration } = useInstructionConfigurationContext()
  const previousInstructionConfiguration = usePrevious(instructionConfiguration)
  const [instructionSelection, setInstructionSelection] = useState<InstructionSelectionContextType['instructionSelection']>(undefined)

  const setInstructions = useCallback((instructions?: InstructionTypeSelectionDTODictionary) => {
    setInstructionSelection(instructions)
  }, [])

  const processInstructionConfigurationToSelection = useCallback((originalInstructionsState: InstructionTypeDTODictionary) => {
    if (!userProfile) throw new Error(`userProfile is undefined`)
    if (!instructionConfiguration) throw new Error(`instructionConfiguration is undefined`)
    const newInstructionsState: InstructionTypeSelectionDTODictionary = cloneDeep(originalInstructionsState)

    /** Creates instruciton option fields */
    const createField = (key: InstructionOptionFieldType, required = true, value = '') => {
      return {
        key,
        required,
        value,
      }
    }

    // Delete CT booking instructions
    delete newInstructionsState[InstructionType.CT_BOOKING]

    const { currency, timezone, billingAddress } = instructionConfiguration
    // Insert Reference
    newInstructionsState[InstructionType.REFERENCE] = {
      key: InstructionType.REFERENCE,
      instructionOptions: [{
        id: 9999002,
        kind: ProductKind.REFERENCE,
        isPrimary: false,
        feePrice: {
          currency,
          value: ZERO_DECIMAL,
          discountPercentage: ZERO_DECIMAL,
        },
        fields: [],
        selected: true,
      }],
    }

    const instructionBillingType = newInstructionsState[InstructionType.BILLING]
    if (!instructionBillingType) throw new Error(`Instruction type: ${InstructionType.BILLING} is missing in instructions configuration.`)

    // TODO: Enable BillingAddress
    // Insert Billing address
    // instructionBillingType.instructionOptions.unshift({
    //   id: 9999000,
    //   kind: ProductKind.BILLING_ADDRESS,
    //   isPrimary: false,
    //   feePrice: {
    //     currency,
    //     value: ZERO_DECIMAL,
    //     discountPercentage: ZERO_DECIMAL,
    //   },
    //   fields: [],
    //   selected: true,
    // })

    // Move billing primary to a separate type
    newInstructionsState[InstructionType.BILLING_PRIMARY] = {
      key: InstructionType.BILLING_PRIMARY,
      instructionOptions: instructionBillingType.instructionOptions.filter(option => option.isPrimary),
    }

    // Remove billing primary from billing type
    instructionBillingType.instructionOptions = instructionBillingType.instructionOptions.filter(option => !option.isPrimary)

    // check for only instruction option on primary types
    for (const instructionTypeKey in newInstructionsState) {
      const instructionType = newInstructionsState[instructionTypeKey]
      if (!instructionType) throw new Error(`Instruction type with instruction type key: ${instructionTypeKey} is missing. (instructionType=${instructionType})`)
      if (!ChooseOneInstructionTypeKeys.has(instructionType.key)) continue

      const instructionOptionsArray = instructionType.instructionOptions
      if (instructionOptionsArray.length === 1) instructionOptionsArray[0].isAlwaysSelected = true
    }

    for (const instructionTypeKey in newInstructionsState) {
      const instructionType = newInstructionsState[instructionTypeKey]
      if (!instructionType) throw new Error(`Instruction type with instruction type key: ${instructionTypeKey} is missing. (instructionType=${instructionType})`)
      for (const instructionOption of instructionType.instructionOptions) {
        let selected = false
        for (const oldOption of newInstructionsState[instructionTypeKey]?.instructionOptions || []) {
          if (oldOption.id === instructionOption.id) {
            selected = !!oldOption.selected
            if (oldOption.fields && oldOption.fields.length > 0) instructionOption.fields = [...oldOption.fields]
          }
        }
        if (!instructionOption.fields || instructionOption.fields.length === 0) {
          instructionOption.fields = []
          switch (instructionOption.kind) {
            case ProductKind.MEETING_ON_SITE:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.USER_TIMEZONE_DATETIME_INSTRUCTIONS, false),
                createField(InstructionOptionFieldType.DATE),
                createField(InstructionOptionFieldType.TIME),
                createField(InstructionOptionFieldType.USER_TIMEZONE_DATETIME, false),
                createField(InstructionOptionFieldType.NAME),
                createField(InstructionOptionFieldType.PHONE),
                createField(InstructionOptionFieldType.EMAIL, false),
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              if (!timezone) {
                instructionOption.fields.unshift(createField(InstructionOptionFieldType.TIMEZONE, true, getUserTimezone(userProfile)))
              }
              break
            case ProductKind.ORGANIZATION_THIRD_PARTY:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.NAME),
                createField(InstructionOptionFieldType.PHONE),
                createField(InstructionOptionFieldType.EMAIL, false),
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              if (!timezone) {
                instructionOption.fields.unshift(createField(InstructionOptionFieldType.TIMEZONE, true, getUserTimezone(userProfile)))
              }
              break
            case ProductKind.KEYS_PICKUP:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.USER_TIMEZONE_DATETIME_INSTRUCTIONS, false),
                createField(InstructionOptionFieldType.DATE),
                createField(InstructionOptionFieldType.TIME),
                createField(InstructionOptionFieldType.USER_TIMEZONE_DATETIME, false),
                createField(InstructionOptionFieldType.NAME),
                createField(InstructionOptionFieldType.PHONE),
                createField(InstructionOptionFieldType.EMAIL, false),
                createField(InstructionOptionFieldType.ADDRESS),
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              if (!timezone) {
                instructionOption.fields.unshift(createField(InstructionOptionFieldType.TIMEZONE, true, getUserTimezone(userProfile)))
              }
              break
            case ProductKind.BILLING_ADDRESS:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.COMMENT, true, billingAddress || ''),
              ]
              break
            case ProductKind.REFERENCE:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              break
            case ProductKind.PRODUCTION_COMMENTS:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              break
            case ProductKind.EXTERNAL_REPORTING:
              instructionOption.fields = [
                createField(InstructionOptionFieldType.COMMENT, false),
              ]
              break
          }
        }

        if (AlwaysSelectedInstructionKinds.has(instructionOption.kind)) instructionOption.isAlwaysSelected = true

        if (instructionOption.isAlwaysSelected) instructionOption.selected = true
        else instructionOption.selected = selected

        const fieldsMap = new Map<InstructionOptionFieldTypeType, InstructionOptionFieldSelectionDTO>()
        for (const field of instructionOption.fields) fieldsMap.set(field.key, field)
        instructionOption.fieldsMap = fieldsMap
      }
    }
    return newInstructionsState
  }, [instructionConfiguration, userProfile])

  const resetInstructionSelection = useCallback(() => {
    if (!instructionConfiguration) return setInstructionSelection(undefined)
    const newInstructionsState = processInstructionConfigurationToSelection(instructionConfiguration.instructionTypes)
    setInstructionSelection(newInstructionsState)
  }, [instructionConfiguration, processInstructionConfigurationToSelection])

  /** Reset instruction selection upon instruction configuration change */
  useEffect(() => {
    if (isEqual(instructionConfiguration, previousInstructionConfiguration)) return
    resetInstructionSelection()
  }, [resetInstructionSelection, instructionConfiguration, previousInstructionConfiguration])

  const availableInstructionTypes = useMemo(() => instructionConfigurationLoaded && instructionSelection ? Object.values(instructionSelection).map(type => InstructionType[type.key]) : [], [instructionConfigurationLoaded, instructionSelection])

  const instructionSelectionList = useMemo(() => {
    if (!instructionSelection) return []
    const list: InstructionOptionSelectionDTO[] = []
    const sortedInstructionTypes = Object.keys(instructionSelection).sort((typeA, typeB) => InstructionTypeSortingOrder.indexOf(typeA as InstructionType) - InstructionTypeSortingOrder.indexOf(typeB as InstructionType))
    for (const instructionTypeKey of sortedInstructionTypes) {
      const instructionType = instructionSelection?.[instructionTypeKey]
      if (!instructionType) throw new Error(`Instruction type with instruction type key: ${instructionTypeKey} is missing. (instructionType=${instructionType})`)
      const sortedInstructionOptions = instructionType.instructionOptions.sort((instrA, instrB) => bigFromFee(instrA.feePrice).minus(bigFromFee(instrB.feePrice)).toNumber())
      for (const instructionOption of sortedInstructionOptions) {
        if (instructionOption.selected || instructionOption.isAlwaysSelected) list.push(instructionOption)
      }
    }
    return list
  }, [instructionSelection])

  const selectedOrganizationCount = useMemo(() => !instructionSelection ? 0 : instructionSelection[InstructionType.ORGANIZATION]?.instructionOptions.reduce((accumulator, option) => accumulator + (option.selected ? 1 : 0), 0), [instructionSelection])
  const selectedPrimaryBillingCount = useMemo(() => !instructionSelection ? 0 : instructionSelection[InstructionType.BILLING_PRIMARY]?.instructionOptions.reduce((accumulator, option) => accumulator + (option.isPrimary && option.selected ? 1 : 0), 0), [instructionSelection])
  const instructionTypesWithMissingRequiredField = useMemo(() => {
    if (!instructionSelection) return Immutable.Set<InstructionType>()
    const missingTypes = new Set<InstructionType>()
    for (const instructionTypeKey in instructionSelection) {
      const instructionType = instructionSelection?.[instructionTypeKey]
      if (!instructionType) throw new Error(`Instruction type with instruction type key: ${instructionTypeKey} is missing. (instructionType=${instructionType})`)
      for (const option of instructionType.instructionOptions) {
        if (!option.selected) continue
        for (const field of option.fields || []) {
          if (field.required && !field.value) missingTypes.add(InstructionType[instructionType.key])
        }
      }
    }
    return Immutable.Set<InstructionType>(missingTypes)
  }, [instructionSelection])
  const someRequiredMissing = useMemo(() => instructionTypesWithMissingRequiredField.size > 0, [instructionTypesWithMissingRequiredField])

  const allRequiredInstructionsFilled = useMemo(() => {
    if (!instructionSelection) return false
    if (selectedOrganizationCount !== 1) return false
    if (selectedPrimaryBillingCount !== 1) return false
    if (someRequiredMissing) return false
    return true
  }, [instructionSelection, selectedOrganizationCount, selectedPrimaryBillingCount, someRequiredMissing])

  return (
    <InstructionSelectionContext.Provider
      value={{
        availableInstructionTypes,
        instructionSelection,
        instructionSelectionList,
        selectedOrganizationCount,
        selectedPrimaryBillingCount,
        instructionTypesWithMissingRequiredField,
        someRequiredMissing,
        allRequiredInstructionsFilled,
        setInstructions,
        resetInstructionSelection,
      }}
    >
      {children}
    </InstructionSelectionContext.Provider>
  )
}