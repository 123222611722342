import * as t from 'io-ts'

import { Currency } from 'constants/Currency'
import { DecimalDTO } from 'types/Decimal'
import { FeeDTO } from './FeeDTO'
import { ProductCategory } from 'types/ProductCategory'
import { ProductKindType } from './ProductKind'
import { ProductOptionTypeType } from './ProductOptionType'
import { ProductSegmentType } from './ProductSegment'
import { ProductTypeType } from './ProductType'

/** Available option for individual product */
export const ProductOptionDTO = t.type({
  /** unique key or ID */
  id: t.number,
  /** product kind */
  kind: ProductKindType,
  /** price of the option */
  feePrice: FeeDTO,
  /** type of the option */
  type: ProductOptionTypeType,
  /** value of the option, 0 means not included, 1 is included exactly once, n applies only to OptionType.NUMBER  */
  value: t.number,
  /** shooting duration the option takes in addition to the parent product */
  duration: t.number,
})
export type ProductOptionDTO = t.TypeOf<typeof ProductOptionDTO>

/** Available product (of a kind) */
export const ProductKindDTO = t.intersection([
  t.type({
    /** price of the product */
    feePrice: FeeDTO,
    /** unique key or ID */
    id: t.number,
    /** product kind */
    kind: ProductKindType,
    /** product options */
    options: t.array(ProductOptionDTO),
    /** duration of the shooting in minutes */
    shootingDuration: t.number,
    /** pipedrive version of the product */
    version: t.string,
  }),
  t.partial({
    /** whether the product is selected (added on FE side) */
    selected: t.boolean,
  }),
])
export type ProductKindDTO = t.TypeOf<typeof ProductKindDTO>

/** A DTO received for product type */
export const ProductTypeDTO = t.type({
  key: ProductTypeType,
  products: t.array(ProductKindDTO),
})
export type ProductTypeDTO = t.TypeOf<typeof ProductTypeDTO>

/** A type used for a dictionary of ProductTypeDTOs */
export const ProductTypeDTODictionary = t.record(t.string, ProductTypeDTO)
export type ProductTypeDTODictionary = t.TypeOf<typeof ProductTypeDTODictionary>

/** A DTO received for product segment */
export const ProductSegmentDTO = t.type({
  key: ProductSegmentType,
  productTypes: ProductTypeDTODictionary,
})
export type ProductSegmentDTO = t.TypeOf<typeof ProductSegmentDTO>

/** A type used for a dictionary of ProductSegmentDTOs */
export const ProductSegmentDTODictionary = t.record(t.string, ProductSegmentDTO)
export type ProductSegmentDTODictionary = t.TypeOf<typeof ProductSegmentDTODictionary>

/** A DTO received for product configuration */
export const ProductConfigurationDTO = t.type({
  visible: t.boolean,
  currency: t.keyof(Currency),
  decimalVat: DecimalDTO,
  decimalDiscount: DecimalDTO,
  segments: ProductSegmentDTODictionary,
})
export type ProductConfigurationDTO = t.TypeOf<typeof ProductConfigurationDTO>

/** A body sent in the product configuration request */
export const ProductConfigurationRequestBody = t.type({
  email: t.string,
  category: ProductCategory,
  countryCode: t.string,
})
export type ProductConfigurationRequestBody = t.TypeOf<typeof ProductConfigurationRequestBody>