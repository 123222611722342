import Big, { BigSource } from 'big.js'

import { DecimalDTO } from 'types/Decimal'
import { FeeDTO } from 'types/FeeDTO'

/** Typeguard */
export function decimalOrBigSourceIsDecimal(decimal: DecimalDTO | BigSource): decimal is DecimalDTO {
  return (
    (decimal as DecimalDTO).value !== undefined
  )
}

/** Typeguard */
export function feeOrBigSourceIsFee(fee: FeeDTO | BigSource): fee is FeeDTO {
  return (
    (fee as FeeDTO).value !== undefined &&
    (fee as FeeDTO).value.value !== undefined
  )
}

/** Decides if DecimalDTO is zero */
export const decimalIsZero = (decimal: DecimalDTO) => {
  return bigFromDecimal(decimal).eq(0)
}

/** Returns Big.js representation of number from DecimalDTO */
export const bigFromDecimal = (decimal: DecimalDTO | BigSource) => {
  if (decimalOrBigSourceIsDecimal(decimal)) return Big(decimal.value)
  else return Big(decimal)
}

/** Returns Big.js representation of number from FeeDTO */
export const bigFromFee = (fee: FeeDTO | BigSource) => {
  if (feeOrBigSourceIsFee(fee)) return bigFromDecimal(fee.value)
  else return Big(fee)
}

/** Returns DecimalDTO representation of number from Big.js */
export const decimalFromBig = (big: Big | BigSource): DecimalDTO => {
  if (!(big instanceof Big)) big = new Big(big)
  const value = big.toString().replace(/\s/gim, '')

  const isPointDecimal = value.includes('.')
  const isCommaDecimal = value.includes(',')
  const isDecimal = isPointDecimal || isCommaDecimal

  let digits = value
  let decimals = ''
  let splitter = ','
  if (isDecimal) {
    if (isPointDecimal) splitter = '.'
    const splitted = value.split(splitter)
    digits = splitted[0]
    decimals = splitted[1]
  }

  const scale = decimals.length
  const precision = digits.length + decimals.length
  const result: DecimalDTO = {
    value,
    scale,
    precision,
  }
  return result
}