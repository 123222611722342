import { cs, de, enGB, enUS, es, fr, it, nl } from 'date-fns/locale'

import { getUserLocale } from 'get-user-locale'
import { registerLocale } from 'react-datepicker'

registerLocale('en-us', enUS)
registerLocale('en-gb', enGB)
registerLocale('cs-cz', cs)
registerLocale('en', enUS)
registerLocale('fr', fr)
registerLocale('de', de)
registerLocale('es', es)
registerLocale('nl', nl)
registerLocale('it', it)

export const getDatePickerLocale = (localeString: string) => {
  localeString = localeString.toLocaleLowerCase()

  switch (localeString) {
    case 'en-us':
    case 'en-gb':
    case 'cs-cz':
      return localeString
    default:
      return localeString.replace(/-[a-zA-Z]{2}/igm, '')
  }
}

export const getUserDatePickerLocale = () => getDatePickerLocale(getUserLocale())