/** Currency to display prices in */
export enum Currency {
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
}

export function isValidCurrency(currency?: Currency | string | null): currency is Currency {
  if (!currency) return false
  return !!Currency[currency as Currency]
}