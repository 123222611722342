import * as t from 'io-ts'

/** Instruction Option Field Type - Describes the type of the instruction option field (TIME, DATE, PHONE, EMAIL, COMMENT, etc...) */
export enum InstructionOptionFieldType {
  TIME = 'TIME',
  DATE = 'DATE',
  NAME = 'NAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  COMMENT = 'COMMENT',
  ADDRESS = 'ADDRESS',
  TIMEZONE = 'TIMEZONE',
  USER_TIMEZONE_DATETIME = 'USER_TIMEZONE_DATETIME',
  USER_TIMEZONE_DATETIME_INSTRUCTIONS = 'USER_TIMEZONE_DATETIME_INSTRUCTIONS',
}
export const InstructionOptionFieldTypeType = t.keyof(InstructionOptionFieldType)
export type InstructionOptionFieldTypeType = t.TypeOf<typeof InstructionOptionFieldTypeType>