import { GoogleAPIPlace } from 'types/AddressSelection'
import { Nullable } from 'types/HelperTypes'

/** checks if address is valid (is set and has route, and street number) */
export const isAddressValid = (googlePlace: Nullable<GoogleAPIPlace>) => {
  if (!googlePlace) return false
  if (!googlePlace.address_components) return false
  let foundRoute = false
  let foundStreetNumber = false
  for (const component of googlePlace.address_components) {
    for (const type of component.types) {
      switch (type) {
        case 'route':
          foundRoute = true
          break
        case 'street_number':
          foundStreetNumber = true
          break
      }
    }
  }
  return (foundRoute && foundStreetNumber)
}