import React, { FC, Fragment, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import AlertMessage from 'components/AlertMessage/AlertMessage'
import BlockInfo from 'components/BlockInfo/BlockInfo'
import Loading from 'components/Loading/Loading'
import { getTermsLink } from 'constants/Terms'
import i18n from 'lib/i18n'
import styles from './OrderPlacement.module.sass'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInstructionSelectionContext } from 'contexts/InstructionSelectionContext'
import { useOrderPlacementContext } from 'contexts/OrderPlacementContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useUserContext } from 'contexts/UserContext'

const OrderPlacement: FC = () => {
  const { t } = useTranslation(['order', 'onoffice'])
  const { userProfile } = useUserContext()
  const { platformPlace, addressIsValid } = useAddressSelectionContext()
  const { productSelectionList } = useProductSelectionContext()
  const { placeOrder, orderPlacementLoaded, orderPlacementLoading, orderPlacementFailed } = useOrderPlacementContext()
  const { allRequiredInstructionsFilled } = useInstructionSelectionContext()

  const isDisabled = useMemo(() => orderPlacementLoaded || orderPlacementLoading || orderPlacementFailed, [orderPlacementLoaded, orderPlacementLoading, orderPlacementFailed])
  const displayedWarning = useMemo(() => orderPlacementLoading ? <span>{t('step_validation.placing_order')}</span> : null, [orderPlacementLoading, t])

  const termsLink = getTermsLink(i18n.language)

  if (!userProfile) return (
    <AlertMessage fullPage>
      <h1>{t('onoffice:missing_user')}</h1>
    </AlertMessage>
  )

  if (!platformPlace || !addressIsValid) return <Fragment></Fragment>
  if (productSelectionList.length === 0) return <Fragment></Fragment>
  if (!allRequiredInstructionsFilled) return <Fragment></Fragment>

  return (
    <div className={styles.OrderPlacement}>
      {orderPlacementFailed &&
        <div className="message-block">
          <BlockInfo color="red">
            <h4>{t('step_validation.order_error.title')}</h4>
            <p>{t('step_validation.order_error.text')}</p>
          </BlockInfo>
        </div>
      }
      {orderPlacementLoaded &&
        <div className="message-block">
          <BlockInfo color="green">
            <h4>{t('step_validation.order_placed.title')}</h4>
          </BlockInfo>
        </div>
      }
      {orderPlacementLoading &&
        <Loading>
          <span>{t('step_validation.placing_order')}</span>
        </Loading>
      }
      <div className="button-row">
        <div className="button-wrap">
          <button className="button primary" onClick={() => placeOrder()} disabled={isDisabled}>{t('step_validation.agree_and_order')}</button>
          {displayedWarning ?
            <Fragment>
              {displayedWarning}
            </Fragment>
            :
            <span className="darker-warning agreement-warning">
              <Trans t={t} i18nKey="order:step_validation:agree_tos">
                <a href={termsLink} rel="noopener noreferrer" target="_blank">&nbsp;</a>
              </Trans>
            </span>
          }
        </div>
      </div>
    </div>
  )
}

export default OrderPlacement