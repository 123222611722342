/** An Enumeration of all available icons */
export enum IconType {
  ARROW = 'arrow',
  CAMERA = 'camera',
  CAR = 'car',
  CARD = 'card',
  CARET_DOWN = 'caret_down',
  CARET_UP = 'caret_up',
  CARET_RIGHT = 'caret_right',
  CARET_LEFT = 'caret_left',
  CLOCK = 'clock',
  CROSS = 'cross',
  DATE = 'date',
  ENVELOPE = 'envelope',
  EXIT = 'exit',
  FILE = 'file',
  CHECK = 'check',
  LOADING = 'loading',
  MAP = 'map',
  PHONE = 'phone',
  PROFILE = 'profile',
  SALARY = 'salary',
  STAR = 'star',
  STAR_EMPTY = 'star_empty',
  STOPWATCH = 'stopwatch',
  THREEDOTS = 'threedots',
  TRIANGLE = 'triangle',
  BURGERMENU = 'burgermenu',
  PHOTO = 'photo',
  EYE = 'eye',
  MARKER = 'marker',
  PACK = 'pack',
  TRASH = 'trash',
  QUESTION = 'question',
  INFO = 'info',
  HOME = 'home',
  LIST = 'list',
  REFRESH = 'refresh',
  HANDSHAKE = 'handshake',
  EXTERNALLINK = 'externallink',
  LIGHTBULB = 'lightbulb',
  EDIT = 'edit',
  PLUS = 'plus',
  PAINT_BRUSH = 'paint_brush',
  LANGUAGE = 'language',
  LINK = 'link',
}

/** An Enumeration of all available icon colors */
export enum ColorClass {
  BLACK = 'black',
  WHITE = 'white',
  GRAY = 'gray',
  RED = 'red',
  PRIMARY_ORANGE = 'primary-orange',
  PRIMARY_BLUE = 'primary-blue',
  PRIMARY_GREEN = 'primary-green',
  SECONDARY_ORANGE_DARK = 'secondary-orange-dark',
  SECONDARY_BLUE_DARK = 'secondary-blue-dark',
  SECONDARY_GREEN_DARK = 'secondary-green-dark',
  SECONDARY_ORANGE_LIGHT = 'secondary-orange-light',
  SECONDARY_BLUE_LIGHT = 'secondary-blue-light',
  SECONDARY_GREEN_LIGHT = 'secondary-green-light',
}