import * as Sentry from '@sentry/nextjs'

import { FC, createContext, useContext, useEffect, useState } from 'react'

import { InitialQueryParams } from 'types/QueryParams'
import { fold } from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useQueryContext } from 'contexts/QueryContext'

/** Type describing the shape of context value */
export interface InitialQueryContextType {
  /** Object containing all initial query params */
  initialQueryParams?: InitialQueryParams
  /** Whether all initial query params were successfuly validated */
  initialQueryParamsValidated: boolean
  /** Array of string containg the report from invalid initial query params */
  invalidInitialQueryParamsReport?: string[]
}

/** Object containing all initial query params set to empty string */
export const defaultInitialQueryParams: InitialQueryParams = {
  apiClaim: '',
  company: '',
  country: '',
  customerName: '',
  customerWebId: '',
  estateid: '',
  parameterCacheId: '',
  timestamp: '',
  userId: '',
  signature: '',
}

/** The default context value */
export const defaultInitialQueryContext: InitialQueryContextType = {
  initialQueryParamsValidated: false,
}

/** Context containing all initial query data */
export const InitialQueryContext = createContext<InitialQueryContextType>(defaultInitialQueryContext)

/** Hook for user context */
export const useInitialQueryContext = (): InitialQueryContextType => useContext(InitialQueryContext)

/** Provider for InitialQueryContext validates query params and passes them down */
export const InitialQueryContextProvider: FC = ({
  children,
}) => {
  const { query } = useQueryContext()
  const [initialQueryParams, setInitialQueryParams] = useState<InitialQueryContextType['initialQueryParams']>(undefined)
  const [initialQueryParamsValidated, setInitialQueryParamsValidated] = useState<InitialQueryContextType['initialQueryParamsValidated']>(false)
  const [invalidInitialQueryParamsReport, setInvalidInitialQueryParamsReport] = useState<InitialQueryContextType['invalidInitialQueryParamsReport']>(undefined)

  /** Validate initial query params */
  useEffect(() => {
    const { apiClaim, company, country, customerName, customerWebId, estateid, parameterCacheId, timestamp, userId, signature } = query
    const queryParams = {
      apiClaim,
      company,
      country,
      customerName,
      customerWebId,
      estateid,
      parameterCacheId,
      timestamp,
      userId,
      signature,
    }

    const decodedQueryParams = InitialQueryParams.decode(queryParams)
    pipe(
      decodedQueryParams,
      fold(
        errors => {
          const report = reporter.report(decodedQueryParams)
          setInvalidInitialQueryParamsReport(report)
          console.error(report, errors)
          setInitialQueryParamsValidated(false)
        },
        // Set state with validated query params
        validatedQueryParams => {
          setInitialQueryParams(validatedQueryParams)
          setInitialQueryParamsValidated(true)
          setInvalidInitialQueryParamsReport(undefined)

          // Track InitialQueryContext in Sentry
          Sentry.setContext('InitialQueryContext', {
            ...validatedQueryParams,
          })
        }
      )
    )
  }, [query])

  return (
    <InitialQueryContext.Provider
      value={{
        initialQueryParams,
        initialQueryParamsValidated,
        invalidInitialQueryParamsReport,
      }}
    >
      {children}
    </InitialQueryContext.Provider>
  )
}