import * as t from 'io-ts'

/** Product Type enum - Describes product type (PHOTO, VIDEO, VIRTUAL_VISIT, FLOOR_PLAN, NONE) */
export enum ProductType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  VIRTUAL_VISIT = 'VIRTUAL_VISIT',
  FLOOR_PLAN = 'FLOOR_PLAN',

  /** Special type for purchase flow. */
  NONE = 'NONE',
}
export const ProductTypeType = t.keyof(ProductType)
export type ProductTypeType = t.TypeOf<typeof ProductTypeType>