import * as t from 'io-ts'

import { Currency } from 'constants/Currency'
import { DecimalDTO } from './Decimal'

/** DTO describing a fee/price/cost or any other financial value */
export const FeeDTO = t.type({
  /** An actual BigDecimal value of the fee/price/cost */
  value: DecimalDTO,
  /** A currency of the fee/price/cost */
  currency: t.keyof(Currency),
  /** A percentage (value between 0 and 100 inclusive) discount from the price */
  discountPercentage: DecimalDTO,
})
export type FeeDTO = t.TypeOf<typeof FeeDTO>