import { FC, Fragment } from 'react'

import ConfirmationPage from 'components/ConfirmationPage/ConfirmationPage'
import ErrorPage from 'components/ErrorPage/ErrorPage'
import InvalidQueryParamsPage from 'components/InvalidQueryParamsPage/InvalidQueryParamsPage'
import Loading from 'components/Loading/Loading'
import PurchaseFlow from 'components/Purchase/PurchaseFlow/PurchaseFlow'
import { useConfirmationQueryContext } from 'contexts/ConfirmationQueryContext'
import { useErrorQueryContext } from 'contexts/ErrorQueryContext'
import { useInitialQueryContext } from 'contexts/InitialQueryContext'
import { useTranslationContext } from 'contexts/TranslationContext'

const PageRouter: FC = () => {
  const { resources } = useTranslationContext()
  const { errorQueryParams } = useErrorQueryContext()
  const { confirmationQueryParams } = useConfirmationQueryContext()
  const { initialQueryParams } = useInitialQueryContext()

  return (
    <Fragment>
      {resources === undefined ?
        <Loading fullPage />
        :
        <Fragment>
          {errorQueryParams ?
            <ErrorPage />
            :
            <Fragment>
              {confirmationQueryParams ?
                <ConfirmationPage />
                :
                <Fragment>
                  {initialQueryParams ?
                    <PurchaseFlow />
                    :
                    <InvalidQueryParamsPage />
                  }
                </Fragment>
              }
            </Fragment>
          }
        </Fragment>
      }
    </Fragment>
  )
}

export default PageRouter