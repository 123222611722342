import * as t from 'io-ts'

/** Query params which should be immediately present upon opening the iframe popup */
export const InitialQueryParams = t.type({
  apiClaim: t.string,
  company: t.string,
  country: t.string,
  customerName: t.string,
  customerWebId: t.string,
  estateid: t.string,
  parameterCacheId: t.string,
  timestamp: t.string,
  userId: t.string,
  signature: t.string,
})
export type InitialQueryParams = t.TypeOf<typeof InitialQueryParams>

/** Query params received when error occurs */
export const ErrorQueryParams = t.type({
  errorCodes: t.string,
  message: t.string,
  status: t.string,
  timestamp: t.string,
  signature: t.string,
})
export type ErrorQueryParams = t.TypeOf<typeof ErrorQueryParams>

/** Query params received when confirmation page is opened */
export const ConfirmationQueryParams = t.type({
  message: t.string,
  referenceid: t.string,
  status: t.string,
  timestamp: t.string,
  transactionid: t.string,
  signature: t.string,
  customerWebId: t.string,
  apiClaim: t.string,
  userId: t.string,
  orderId: t.string,
})
export type ConfirmationQueryParams = t.TypeOf<typeof ConfirmationQueryParams>