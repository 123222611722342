import EmptyResultsCardsSVG from 'public/images/components/EmptyResultsCards.svg'
import { FC } from 'react'
import Image from 'next/image'
import styles from './EmptyResultCards.module.sass'

/**
 * @component Graphical component to display when there are empty results of list from a fetch or search action
 * @example
 * <EmptyResultCards className="class" />
 */
const EmptyResultCards: FC<{
  /** The additional classes to append */
  className?: string
}> = ({
  className = '',
}) => (
    <Image src={EmptyResultsCardsSVG} alt="" className={`${styles.EmptyResultCards} ${className}`.replace(/\s+/igm, ' ').trim()} />
  )

export default EmptyResultCards