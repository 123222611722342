import * as t from 'io-ts'

/** Categories of shooting orders */
export enum ShootingCategory {
  REAL_ESTATE = 'REAL_ESTATE',
  EVENT = 'EVENT',
  CORPORATE = 'CORPORATE',
  FOOD = 'FOOD',
  PRODUCT = 'PRODUCT',
}
/** Categories of shooting orders */
export const ShootingCategoryType = t.keyof(ShootingCategory)
export type ShootingCategoryType = t.TypeOf<typeof ShootingCategoryType>

/** Categories of editing orders */
export enum EditingCategory {
  EDITING = 'EDITING',
}
export const EditingCategoryType = t.keyof(EditingCategory)
export type EditingCategoryType = t.TypeOf<typeof EditingCategoryType>

/** A category of product including shooting and editing categories (real estate, event, advanced editing, etc...) */
export const ProductCategory = t.union([ShootingCategoryType, EditingCategoryType])
export type ProductCategory = t.TypeOf<typeof ProductCategory>