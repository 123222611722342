import { AddressConfigurationDTO, AddressConfigurationRequestBody } from 'types/AddressConfiguration'
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'

import { APIRequestState } from 'constants/APIState'
import { Endpoints } from 'constants/Endpoints'
import { ShootingCategory } from 'types/ProductCategory'
import axios from 'axios'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from 'lib/API'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useInitialQueryContext } from './InitialQueryContext'
import { useUserContext } from 'contexts/UserContext'

/** Type describing the shape of context value */
export interface AddressConfigurationContextType {
  /** Indicates the state of address configuration request */
  addressConfigurationAPIRequestState: APIRequestState
  /** Contains the whole platform address configuration DTO */
  addressConfiguration: AddressConfigurationDTO | undefined
  /** Indicates if address configuration is being loaded */
  addressConfigurationLoading: boolean
  /** Indicates if address configuration was successfully loaded */
  addressConfigurationLoaded: boolean
  /** Indicates if address configuration failed to load */
  addressConfigurationFailed: boolean
}

/** The default context value */
export const defaultAddressConfigurationContext: AddressConfigurationContextType = {
  addressConfigurationAPIRequestState: APIRequestState.BEFORE_START,
  addressConfiguration: undefined,
  addressConfigurationLoading: false,
  addressConfigurationLoaded: false,
  addressConfigurationFailed: false,
}

/** Context containing all address configuration related data */
export const AddressConfigurationContext = createContext<AddressConfigurationContextType>(defaultAddressConfigurationContext)

/** Hook for address configuration context */
export const useAddressConfigurationContext = (): AddressConfigurationContextType => useContext(AddressConfigurationContext)

/** Provider for AddressConfigurationContext which calls address configuration endpoint upon mounting */
export const AddressConfigurationContextProvider: FC = ({
  children,
}) => {
  const { initialQueryParams } = useInitialQueryContext()
  const { userProfile } = useUserContext()
  const [addressConfigurationAPIRequestState, setAddressConfigurationAPIRequestState] = useState<AddressConfigurationContextType['addressConfigurationAPIRequestState']>(APIRequestState.BEFORE_START)
  const [addressConfiguration, setAddressConfiguration] = useState<AddressConfigurationContextType['addressConfiguration']>(undefined)

  const addressConfigurationLoading = useMemo(() => addressConfigurationAPIRequestState === APIRequestState.RUNNING, [addressConfigurationAPIRequestState])
  const addressConfigurationLoaded = useMemo(() => addressConfigurationAPIRequestState === APIRequestState.COMPLETE && !!addressConfiguration, [addressConfigurationAPIRequestState, addressConfiguration])
  const addressConfigurationFailed = useMemo(() => addressConfigurationAPIRequestState === APIRequestState.ERROR, [addressConfigurationAPIRequestState])

  /** Fetch address configuration */
  useEffect(() => {
    const fetchAddressConfiguration = async () => {
      if (!initialQueryParams) return
      if (!userProfile) return

      /** Reset address configuration upon profile change */
      setAddressConfigurationAPIRequestState(APIRequestState.BEFORE_START)
      setAddressConfiguration(undefined)

      const { email } = userProfile
      const body: AddressConfigurationRequestBody = {
        email,
        estateId: initialQueryParams.estateid,
        customerWebId: initialQueryParams.customerWebId,
        apiClaim: initialQueryParams.apiClaim,
        category: ShootingCategory.REAL_ESTATE,
      }

      // Validate type of request body
      const decodedBody = AddressConfigurationRequestBody.decode(body)
      pipe(
        decodedBody,
        fold(
          errors => {
            const report = reporter.report(decodedBody)
            console.error(report, errors)
            setAddressConfigurationAPIRequestState(APIRequestState.ERROR)
          },
          // Call API with validated request body
          async validatedBody => {
            try {
              setAddressConfigurationAPIRequestState(APIRequestState.RUNNING)
              const URL = getRemoteAPIURL(Endpoints.ORDER_CONFIGURATION_ADDRESS)
              const response = await axios.post<AddressConfigurationDTO>(URL, validatedBody)

              /** Validate type of response data */
              const decodedResponseData = AddressConfigurationDTO.decode(response.data)
              pipe(
                decodedResponseData,
                fold(
                  errors => {
                    const report = reporter.report(decodedResponseData)
                    console.error(report, errors)
                    setAddressConfigurationAPIRequestState(APIRequestState.ERROR)
                  },
                  // Set result
                  validatedResponseData => {
                    setAddressConfiguration(validatedResponseData)
                    setAddressConfigurationAPIRequestState(APIRequestState.COMPLETE)
                  }
                )
              )
            } catch (error) {
              console.error(error)
              setAddressConfigurationAPIRequestState(APIRequestState.ERROR)
            }
          }
        )
      )
    }
    fetchAddressConfiguration()
  }, [initialQueryParams, userProfile])

  return (
    <AddressConfigurationContext.Provider
      value={{
        addressConfigurationAPIRequestState,
        addressConfiguration,
        addressConfigurationLoading,
        addressConfigurationLoaded,
        addressConfigurationFailed,
      }}
    >
      {children}
    </AddressConfigurationContext.Provider>
  )
}