import React, { FC, Fragment, useMemo } from 'react'

import { APIRequestState } from 'constants/APIState'
import AddressMap from 'components/AddressMap/AddressMap'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import Loading from 'components/Loading/Loading'
import i18n from 'lib/i18n'
import styles from './AddressSelection.module.sass'
import { useAddressConfigurationContext } from 'contexts/AddressConfigurationContext'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInitialQueryContext } from 'contexts/InitialQueryContext'
import { useTranslation } from 'react-i18next'
import { useUserContext } from 'contexts/UserContext'

const AddressSelection: FC = ({
  children,
}) => {
  const { t } = useTranslation(['order', 'onoffice'])
  const { initialQueryParams } = useInitialQueryContext()
  const { userProfile } = useUserContext()
  const { setPlace, googlePlace, platformPlace, addressIsValid } = useAddressSelectionContext()
  const { addressConfigurationLoaded, addressConfiguration, addressConfigurationAPIRequestState } = useAddressConfigurationContext()

  const whenAddressConfigurationNotLoaded = useMemo(() => {
    switch (addressConfigurationAPIRequestState) {
      case APIRequestState.BEFORE_START:
        return (
          <Loading>
            <span>{t('onoffice:loading_state_BEFORE_START', { subject: t('onoffice:selection_address') })}</span>
          </Loading>
        )
      case APIRequestState.RUNNING:
        return (
          <Loading>
            <span>{t('onoffice:loading_state_RUNNING', { subject: t('onoffice:selection_address') })}</span>
          </Loading>
        )
      case APIRequestState.ERROR:
        return (
          <AlertMessage>
            <span>{t('onoffice:loading_state_ERROR', { subject: t('onoffice:selection_address') })}</span>
          </AlertMessage>
        )
      case APIRequestState.COMPLETE:
      default:
        return <Fragment></Fragment>
    }
  }, [t, addressConfigurationAPIRequestState])

  if (!userProfile) return (
    <AlertMessage fullPage>
      <h1>{t('onoffice:missing_user')}</h1>
    </AlertMessage>
  )

  if (!addressConfigurationLoaded || !addressConfiguration) return (
    <Fragment>
      {whenAddressConfigurationNotLoaded}
    </Fragment>
  )

  return (
    <div className={styles.AddressSelection}>
      <Fragment>
        <h2>1. {t('step_address.title')}</h2>
        <p>{t('step_address.text')}</p>
      </Fragment>
      <AddressMap
        className={styles.AddressMap}
        height={'40rem'}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleChange={(newGooglePlace, isInitialLookup) => {
          setPlace(newGooglePlace)
        }}
        onInputChange={() => platformPlace && setPlace(null)}
        initialAddress={addressConfiguration.defaultAddress}
        initialMarker={addressConfiguration.defaultCoordinates}
        inputPosition="above"
        error={googlePlace && !addressIsValid ? t('step_address.address_not_selected') : undefined}
        language={i18n.language}
        countryCode={addressConfiguration.defaultCountryCode || initialQueryParams?.country}
      />
      <p className={styles.SelectedAddress}>
        {t('step_address.selected_address')}
        {': '}
        <strong>{platformPlace?.address || t('step_address.none')}</strong>
      </p>
      {children}
    </div>
  )
}

export default AddressSelection