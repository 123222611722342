import * as Sentry from '@sentry/nextjs'

import { FC, createContext, useContext, useEffect, useState } from 'react'

import { ConfirmationQueryParams } from 'types/QueryParams'
import { fold } from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import reporter from 'io-ts-reporters'
import { useQueryContext } from 'contexts/QueryContext'

/** Type describing the shape of context value */
export interface ConfirmationQueryContextType {
  /** Object containing all confirmation query params */
  confirmationQueryParams?: ConfirmationQueryParams
  /** Whether all confirmation query params were successfuly validated */
  confirmationQueryParamsValidated: boolean
  /** Array of string containg the report from invalid confirmation query params */
  invalidConfirmationQueryParamsReport?: string[]
}

/** Object containing all confirmation query params set to empty string */
export const defaultConfirmationQueryParams: ConfirmationQueryParams = {
  message: '',
  referenceid: '',
  status: '',
  timestamp: '',
  transactionid: '',
  signature: '',
  customerWebId: '',
  apiClaim: '',
  userId: '',
  orderId: '',
}

/** The default context value */
export const defaultConfirmationQueryContext: ConfirmationQueryContextType = {
  confirmationQueryParamsValidated: false,
}

/** Context containing all confirmation query data */
export const ConfirmationQueryContext = createContext<ConfirmationQueryContextType>(defaultConfirmationQueryContext)

/** Hook for user context */
export const useConfirmationQueryContext = (): ConfirmationQueryContextType => useContext(ConfirmationQueryContext)

/** Provider for ConfirmationQueryContext validates query params and passes them down */
export const ConfirmationQueryContextProvider: FC = ({
  children,
}) => {
  const { query } = useQueryContext()
  const [confirmationQueryParams, setConfirmationQueryParams] = useState<ConfirmationQueryContextType['confirmationQueryParams']>(undefined)
  const [confirmationQueryParamsValidated, setConfirmationQueryParamsValidated] = useState<ConfirmationQueryContextType['confirmationQueryParamsValidated']>(false)
  const [invalidConfirmationQueryParamsReport, setInvalidConfirmationQueryParamsReport] = useState<ConfirmationQueryContextType['invalidConfirmationQueryParamsReport']>(undefined)

  /** Validate confirmation query params */
  useEffect(() => {
    const { message, referenceid, status, timestamp, transactionid, signature, customerWebId, apiClaim, userId, orderId } = query
    const queryParams = {
      message,
      referenceid,
      status,
      timestamp,
      transactionid,
      signature,
      customerWebId,
      apiClaim,
      userId,
      orderId,
    }

    const decodedQueryParams = ConfirmationQueryParams.decode(queryParams)
    pipe(
      decodedQueryParams,
      fold(
        () => {
          const report = reporter.report(decodedQueryParams)
          setInvalidConfirmationQueryParamsReport(report)
          setConfirmationQueryParamsValidated(false)
        },
        // Set state with validated query params
        validatedQueryParams => {
          setConfirmationQueryParams(validatedQueryParams)
          setConfirmationQueryParamsValidated(true)
          setInvalidConfirmationQueryParamsReport(undefined)

          // Track ConfirmationQueryContext in Sentry
          Sentry.setContext('ConfirmationQueryContext', {
            ...validatedQueryParams,
          })
        }
      )
    )
  }, [query])

  return (
    <ConfirmationQueryContext.Provider
      value={{
        confirmationQueryParams: confirmationQueryParams,
        confirmationQueryParamsValidated: confirmationQueryParamsValidated,
        invalidConfirmationQueryParamsReport: invalidConfirmationQueryParamsReport,
      }}
    >
      {children}
    </ConfirmationQueryContext.Provider>
  )
}