// TODO add links for ES and NL
export const getTermsLink = (language: string) => {
  switch (language) {
    case 'en':
      return 'https://bkbn.com/en/terms-conditions'
    case 'fr':
      return 'https://bkbn.com/fr/terms-conditions'
    case 'de':
      return 'https://bkbn.com/de/terms-conditions'
    default:
      return 'https://bkbn.com/terms-conditions'
  }
}