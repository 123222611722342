import { FC, createContext, useContext, useEffect, useState } from 'react'
import i18n, { i18nAlreadyInitialized, initI18n } from 'lib/i18n'

import { coerceLanguage } from 'lib/coerceLanguage'

/** Type describing the shape of context value */
export interface TranslationContextType {
  /** Translation resources loaded from the BE */
  resources: any
}

/** The default context value */
export const defaultTranslationContext: TranslationContextType = {
  resources: undefined
}

/** Context containing all loaded language resources */
export const TranslationContext = createContext<TranslationContextType>(defaultTranslationContext)

/** Hook for translation context */
export const useTranslationContext = (): TranslationContextType => useContext(TranslationContext)

/** Provider for TranslationContext provides language resources */
export const TranslationContextProvider: FC = ({
  children,
}) => {

  const [resources, setResources] = useState<any>(undefined)

  /** Load translations from the API */
  useEffect(() => {
    if (i18nAlreadyInitialized) return

    const init = async () => {
      try {
        await initI18n()
      }
      catch (e) {
        console.warn(e)
      }
      finally {
        setResources(i18n.getDataByLanguage(coerceLanguage(i18n.language).toLowerCase()))
      }
    }

    init()
  }, [])

  return (
    <TranslationContext.Provider
      value={{ resources }}
    >
      {children}
    </TranslationContext.Provider>
  )
}