import * as t from 'io-ts'

import { KeysPickupDTO, MeetingOnSiteDTO, OrganizationThirdPartyDTO } from './PurchaseOrganization'

import { Coordinates } from './Coordinates'
import { ProductCategory } from './ProductCategory'
import { ProductQuantityDTO } from './Product'
import { ProductSegmentType } from './ProductSegment'

export const PlatformOrderDTO = t.intersection([
  t.type({
    category: ProductCategory,
    segment: ProductSegmentType,
    products: t.array(ProductQuantityDTO),

    // Billing
    billingAddress: t.string,
  }),
  t.partial({
    countryCode: t.string,
    address: t.string,
    coordinates: Coordinates,

    // Purchase on behalf of a client
    email: t.string,

    // Instructions
    comments: t.string,
    meetingOnSite: MeetingOnSiteDTO,
    organizationThirdParty: OrganizationThirdPartyDTO,
    keysPickup: KeysPickupDTO,

    // Billing
    externalReporting: t.string,

    // Other
    reference: t.string,
  })
])
export type PlatformOrderDTO = t.TypeOf<typeof PlatformOrderDTO>

export const OrderCustomerIndetifierDTO = t.type({
  userId: t.string,
  customerWebId: t.string,
  parameterCacheId: t.string,
  apiClaim: t.string,
})
export type OrderCustomerIndetifierDTO = t.TypeOf<typeof OrderCustomerIndetifierDTO>

export const PrepareOrderDTO = t.intersection([
  t.type({
    platformOrderDTO: PlatformOrderDTO,
  }),
  OrderCustomerIndetifierDTO
])
export type PrepareOrderDTO = t.TypeOf<typeof PrepareOrderDTO>

export const OnOfficeProductDTO = t.type({
  name: t.string,
  price: t.string,
  quantity: t.string,
  circleofusers: t.string
})
export type OnOfficeProductDTO = t.TypeOf<typeof OnOfficeProductDTO>

export const OrderDTO = t.type({
  callbackurl: t.string,
  parametercacheid: t.string,
  products: t.array(OnOfficeProductDTO),
  timestamp: t.number,
  totalprice: t.string,
  signature: t.string
})
export type OrderDTO = t.TypeOf<typeof OrderDTO>

export const BillingTransactionDTO = t.type({
  transactionId: t.string,
  customerWebId: t.string,
  apiClaim: t.string,
  referenceId: t.string,
  userId: t.string,
  orderId: t.string,
})
export type BillingTransactionDTO = t.TypeOf<typeof BillingTransactionDTO>

export const IdentityDTO = t.type({
  id: t.number,
})
export type IdentityDTO = t.TypeOf<typeof IdentityDTO>