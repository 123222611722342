import * as t from 'io-ts'
import * as tt from 'io-ts-types'

import { Coordinates } from './Coordinates'
import { Currency } from 'constants/Currency'
import { FeeDTO } from './FeeDTO'
import { InstructionOptionFieldTypeType } from './InstructionOptionFieldType'
import { InstructionTypeType } from './InstructionType'
import { ProductCategory } from './ProductCategory'
import { ProductKindType } from './ProductKind'
import { ReactGeocodePlace } from 'components/AddressMap/AddressMap'

/** Type of the instruction option value */
export const InstructionOptionFieldValueType = t.union([t.string, tt.date, ReactGeocodePlace, t.null, t.undefined])
export type InstructionOptionFieldValueType = t.TypeOf<typeof InstructionOptionFieldValueType>

/** input field of the instruction option */
export const InstructionOptionFieldDTO = t.type({
  /** unique key or ID */
  key: InstructionOptionFieldTypeType,
  /** whether the field is mandatory */
  required: t.boolean,
  /** values of the field */
  value: t.string,
})
export type InstructionOptionFieldDTO = t.TypeOf<typeof InstructionOptionFieldDTO>

/** input field of the instruction option (used in purchase flow instruction selection step) */
export type InstructionOptionFieldSelectionDTO = Omit<InstructionOptionFieldDTO, 'value'> & {
  /** values of the field */
  value: InstructionOptionFieldValueType
}

/** Available option for individual instruction */
export const InstructionOptionDTO = t.intersection([
  t.type({
    /** unique key or ID */
    id: t.number,
    /** product kind */
    kind: ProductKindType,
    /** price of the instruction option */
    feePrice: FeeDTO,
    /** atleast one primary instruction option must be selected */
    isPrimary: t.boolean,
    /** available fields */
    fields: t.array(InstructionOptionFieldDTO),
  }),
  t.partial({
    /** whether the instruction option is selected */
    selected: t.boolean,
    /** whether the option must be always selected */
    isAlwaysSelected: t.boolean,
  }),
])
export type InstructionOptionDTO = t.TypeOf<typeof InstructionOptionDTO>

/** Available option for individual instruction (used in purchase flow instruction selection step) */
export type InstructionOptionSelectionDTO = Omit<InstructionOptionDTO, 'fields'> & {
  /** available fields */
  fields: InstructionOptionFieldSelectionDTO[],
  /** available fields mapped */
  fieldsMap?: Map<InstructionOptionFieldTypeType, InstructionOptionFieldSelectionDTO>
}

/** Available option type in order preparation */
export interface ExtendedInstructionOptionSelectionDTO extends InstructionOptionSelectionDTO {
  structuredFields: Map<InstructionOptionFieldTypeType, string>
}

/** A DTO received for instruction type */
export const InstructionTypeDTO = t.type({
  key: InstructionTypeType,
  instructionOptions: t.array(InstructionOptionDTO),
})
export type InstructionTypeDTO = t.TypeOf<typeof InstructionTypeDTO>

/** A type used for a dictionary of InstructionTypeDTOs */
export const InstructionTypeDTODictionary = t.record(t.string, InstructionTypeDTO)
export type InstructionTypeDTODictionary = t.TypeOf<typeof InstructionTypeDTODictionary>

/** A DTO received for instruction type (used in purchase flow instruction selection step) */
export type InstructionTypeSelectionDTO = {
  key: InstructionTypeType,
  instructionOptions: InstructionOptionSelectionDTO[],
}

/** A type used for a dictionary of InstructionTypeSelectionDTOs (used in purchase flow instruction selection step) */
export type InstructionTypeSelectionDTODictionary = Record<string, InstructionTypeSelectionDTO>

/** A DTO received for instruction configuration */
export const InstructionConfigurationDTO = t.type({
  visible: t.boolean,
  currency: t.keyof(Currency),
  billingAddress: t.union([t.string, t.null]),
  timezone: t.string,
  instructionTypes: InstructionTypeDTODictionary,
})
export type InstructionConfigurationDTO = t.TypeOf<typeof InstructionConfigurationDTO>

/** A body sent in the instruction configuration request */
export const InstructionConfigurationRequestBody = t.type({
  email: t.string,
  countryCode: t.string,
  category: ProductCategory,
  coordinates: Coordinates,
})
export type InstructionConfigurationRequestBody = t.TypeOf<typeof InstructionConfigurationRequestBody>

/** Typeguard */
export function InstructionOptionFieldValueTypeIsReactGeocodePlace(value: InstructionOptionFieldValueType): value is ReactGeocodePlace {
  return (
    typeof (value) === 'object' &&
    value !== null &&
    value !== undefined &&
    (value as ReactGeocodePlace)?.formatted_address !== undefined &&
    (value as ReactGeocodePlace)?.address_components !== undefined &&
    (value as ReactGeocodePlace)?.geometry !== undefined
  )
}