type StringIndexableObject = {
  [key: string]: any
}

/** Flattens nested object into flatmap */
export const flattenObject = function (data: StringIndexableObject) {
  const result: StringIndexableObject = {}

  function recurse(cur: StringIndexableObject, prop: string) {
    if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      // eslint-disable-next-line no-var
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], `${prop}[${i}]`)
      if (l === 0) result[prop] = []
    } else {
      let isEmpty = true
      for (const p in cur) {
        isEmpty = false
        recurse(cur[p], prop ? `${prop}.${p}` : p)
      }
      if (isEmpty && prop) result[prop] = {}
    }
  }
  recurse(data, '')
  return result
}

/** Unflattens object flatmap into nested object */
export const unflattenObject = function (data: StringIndexableObject) {
  if (Object(data) !== data || Array.isArray(data)) return data
  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g
  const resultholder: StringIndexableObject = {}
  for (const p in data) {
    let cur = resultholder
    let prop = ''
    let m
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}))
      prop = m[2] || m[1]
    }
    cur[prop] = data[p]
  }
  return resultholder[''] || resultholder
}

/** Sorts flat object alphabetically by keys */
export const sortflatObject = function (data: StringIndexableObject) {
  const ordered: any = {}
  for (const key of Object.keys(data).sort()) {
    ordered[key] = data[key]
  }
  return ordered
}

/** Sorts (and unflattens) object alphabetically by keys */
export const sortAndUnflattenObject = function (data: StringIndexableObject) {
  const flat = flattenObject(data)
  const ordered: any = {}
  for (const key of Object.keys(flat).sort()) {
    ordered[key] = flat[key]
  }
  return unflattenObject(ordered)
}