import * as t from 'io-ts'

/** DTO for decimal numbers to keep floating point decisions */
export const DecimalDTO = t.type({
  /** Value represented as string - a number with no delimiters and a dot separating the floating point. */
  value: t.string,
  /**
   * Refers to the number of digits in the unscaled value.
   * @example 123.456 has a precision of 6.
  */
  precision: t.number,
  /**
   * For zero or positive value, the scale is the number of digits to the right of the decimal point.
   * For negative value, the unscaled value of the number is multiplied by ten to the power of the negation of the scale.
   * @example 123.456 has a scale of 3.
  */
  scale: t.number,
})
export type DecimalDTO = t.TypeOf<typeof DecimalDTO>

/** DecimalDTO with a value of zero */
export const ZERO_DECIMAL: DecimalDTO = {
  value: '0',
  precision: 1,
  scale: 0,
}