import Big, { BigSource } from 'big.js'
import { DecimalDTO, ZERO_DECIMAL } from 'types/Decimal'
import { bigFromDecimal, bigFromFee, decimalFromBig, decimalIsZero } from './decimals'

import { FeeDTO } from 'types/FeeDTO'

type inputValue = Big | BigSource | DecimalDTO | FeeDTO

/** Typeguard */
// function isBig(value: inputValue): value is Big {
//   return value instanceof Big
// }

/** Typeguard */
function isBigSource(value: inputValue): value is BigSource {
  return (
    (value as DecimalDTO).value === undefined
  )
}

/** Typeguard */
function isDecimal(value: inputValue): value is DecimalDTO {
  return (
    (value as DecimalDTO).value !== undefined &&
    (value as FeeDTO).value.value === undefined
  )
}

/** Typeguard */
function isFee(value: inputValue): value is FeeDTO {
  return (
    (value as FeeDTO).value !== undefined &&
    (value as FeeDTO).value.value !== undefined
  )
}

/** Decides if FeeDTO has non-zero discount */
export const feeHasDiscount = (fee: FeeDTO) => {
  return !decimalIsZero(fee.discountPercentage)
}

/** Takes in FeeDTO and applies percentage discount to it. Returns FeeDTO with adjusted value and percentage discount of zero. */
export const applyFeeDiscount = (originalFee: FeeDTO): FeeDTO => {
  const discountedBig = valueAfterDiscount(originalFee, originalFee.discountPercentage)
  return {
    currency: originalFee.currency,
    value: decimalFromBig(discountedBig),
    discountPercentage: ZERO_DECIMAL,
  }
}

/**
 * Computes percentage value from value and a percentage (value between 0 and 100 inclusive) with a formula (percentage / 100) * price
 * @example percentageValue(1000, 25) => 250
*/
export const percentageValue = (value: inputValue, percentage: inputValue) => {
  // make sure value is Big.js
  if (isFee(value)) value = bigFromFee(value)
  else if (isDecimal(value)) value = bigFromDecimal(value)
  else if (isBigSource(value)) value = Big(value)

  // make sure percentageDiscount is Big.js
  if (isFee(percentage)) percentage = bigFromFee(percentage)
  else if (isDecimal(percentage)) percentage = bigFromDecimal(percentage)
  else if (isBigSource(percentage)) percentage = Big(percentage)


  return percentage.div(100).times(value)
}

/**
 * Computes discounted price from value and a percentage (value between 0 and 100 inclusive) discount with a formula price - ((discount / 100) * price)
 * @example valueAfterDiscount(1000, 25) => 750
*/
export const valueAfterDiscount = (value: inputValue, percentageDiscount: inputValue) => {
  // make sure value is Big.js
  if (isFee(value)) value = bigFromFee(value)
  else if (isDecimal(value)) value = bigFromDecimal(value)
  else if (isBigSource(value)) value = Big(value)

  // make sure percentageDiscount is Big.js
  if (isFee(percentageDiscount)) percentageDiscount = bigFromFee(percentageDiscount)
  else if (isDecimal(percentageDiscount)) percentageDiscount = bigFromDecimal(percentageDiscount)
  else if (isBigSource(percentageDiscount)) percentageDiscount = Big(percentageDiscount)


  return value.minus(percentageValue(value, percentageDiscount))
}

/**
 * Computes price after VAT from value and a percentage (value between 0 and 100 inclusive) VAT with a formula price + ((VAT / 100) * price)
 * @example valueWithVat(1000, 25) => 1250
*/
export const valueWithVat = (value: inputValue, percentageVat: inputValue) => {
  // make sure value is Big.js
  if (isFee(value)) value = bigFromFee(value)
  else if (isDecimal(value)) value = bigFromDecimal(value)
  else if (isBigSource(value)) value = Big(value)

  // make sure percentageDiscount is Big.js
  if (isFee(percentageVat)) percentageVat = bigFromFee(percentageVat)
  else if (isDecimal(percentageVat)) percentageVat = bigFromDecimal(percentageVat)
  else if (isBigSource(percentageVat)) percentageVat = Big(percentageVat)


  return value.plus(percentageValue(value, percentageVat))
}