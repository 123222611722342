import * as t from 'io-ts'

/** Type consisting of a product ID, count and isOption boolean */
export const ProductQuantityDTO = t.type({
  /** Product ID */
  id: t.number,
  /** Product count / quantity */
  count: t.number,
  /** Whether the product is an option */
  isOption: t.boolean,
})
export type ProductQuantityDTO = t.TypeOf<typeof ProductQuantityDTO>