import * as t from 'io-ts'

/** Instruction Type enum - Describes type of instruction (ORGANIZATION, BILLING, BILLING_PRIMARY, COMMENTS, REFERENCE) */
export enum InstructionType {
  ORGANIZATION = 'ORGANIZATION',
  BILLING = 'BILLING',
  BILLING_PRIMARY = 'BILLING_PRIMARY',
  VAT_NUMBER = 'VAT_NUMBER',
  COMMENTS = 'COMMENTS',
  REFERENCE = 'REFERENCE',
  CT_BOOKING = 'CT_BOOKING',
  DELIVERY = 'DELIVERY',
}
export const InstructionTypeType = t.keyof(InstructionType)
export type InstructionTypeType = t.TypeOf<typeof InstructionTypeType>