import { ChangeEvent, FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'

import styles from './CheckCard.module.sass'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
  /** Optional content to display on the right side */
  right?: ReactNode
  /** Optional content to display as expandable when checkbox checked */
  expandable?: ReactNode
  /** Checkbox appearance if undefined, there is no checkbox */
  checkbox?: 'circle' | 'square'
  /** Checkbox input name */
  inputName?: string
  /** Checkbox input ID */
  inputID?: string
  /** Checkbox input value */
  inputValue?: string
  /** Whether the checkbox is radio instead */
  isRadio?: boolean
  /** Whether the card should be highlighted */
  highlighted?: boolean
  /** OnCheck action to be called */
  onCheck?: (checked: boolean) => void
  /** Whether the input should be checked */
  checked?: boolean
  /** Whether the input should be checked by default */
  defaultChecked?: boolean
  /** Whether the card is disabled */
  disabled?: boolean
  /** Whether click inside the element triggers the checkbox */
  innerClickable?: boolean
}

/**
 * @component Check card with an image on the left side and title + text on the right
 */
const CheckCard: FC<Props> = ({
  className = '',
  right,
  expandable,
  checkbox,
  inputName,
  inputID,
  inputValue,
  isRadio = false,
  highlighted = false,
  onCheck,
  checked,
  defaultChecked,
  disabled = false,
  innerClickable = false,
  children,
}) => {
  let shouldBeChecked = false
  if (defaultChecked !== undefined) shouldBeChecked = defaultChecked
  if (checked !== undefined) shouldBeChecked = checked
  const [isChecked, setChecked] = useState<boolean>(shouldBeChecked)
  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (!onCheck) setChecked(e.target.checked)
    onCheck && onCheck(e.target.checked)
  }
  const checkboxRef = useRef<HTMLLabelElement>(null)
  const checkboxInputRef = useRef<HTMLInputElement>(null)
  const innerClick = (e: MouseEvent<HTMLDivElement> | any) => {
    if (e.target === checkboxRef.current || checkboxRef.current?.contains(e.target)) return
    checkboxRef.current?.click()
  }

  useEffect(() => {
    if (checked !== undefined) setChecked(checked)
  }, [checked])

  return (
    <div className={`${styles.checkcard} ${highlighted ? styles.highlighted : ''} ${disabled ? styles.disabled : ''} ${className}`.replace(/\s+/igm, ' ').trim()}>
      <div className={`inner ${innerClickable ? 'clickable' : ''}`.replace(/\s+/igm, ' ').trim()} onClick={innerClickable ? innerClick : undefined}>
        {checkbox &&
          <label
            className={`checkbox ${checkbox === 'square' ? checkbox : ''}`.replace(/\s+/igm, ' ').trim()}
            htmlFor={inputID ? inputID : undefined}
            ref={checkboxRef}>
            <input
              type={isRadio ? 'radio' : 'checkbox'}
              name={inputName ? inputName : undefined}
              id={inputID ? inputID : undefined}
              value={inputValue ? inputValue : undefined}
              // defaultChecked={defaultChecked}
              checked={isChecked}
              onChange={handleCheck}
              disabled={disabled}
              ref={checkboxInputRef} />
            <span className="checkmark"></span>
          </label>
        }
        <div className="content">
          {children}
        </div>
        {right &&
          <div className="right">{right}</div>
        }
      </div>
      {expandable && isChecked &&
        <div className={`expandable ${isChecked ? 'expanded' : ''}`.replace(/\s+/igm, ' ').trim()}>{expandable}</div>
      }
    </div>
  )
}
export default CheckCard