import React, { FC, Fragment } from 'react'

import ContextStack from '../contexts/ContextStack'
import { GetServerSideProps } from 'next'
import Head from 'next/head'
import PageRouter from 'components/PageRouter/PageRouter'
import { ParsedUrlQuery } from 'node:querystring'
import { siteTitle } from '../components/Layout/Layout'
import { useRouter } from 'next/router'

interface Props {
  query: ParsedUrlQuery
}

const Index: FC<Props> = ({ query }) => {
  // eslint-disable-next-line
  const router = useRouter()

  return (
    <Fragment>
      <Head>
        <title>{siteTitle}</title>
      </Head>
      <ContextStack query={query}>
        <PageRouter />
      </ContextStack>
    </Fragment>
  )
}
export default Index

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query } = context
  const props: Props = {
    query,
  }
  return {
    props, // will be passed to the page component as props
  }
}