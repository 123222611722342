import { BillingTransactionDTO, IdentityDTO } from 'types/Order'
import { FC, Fragment, useEffect, useState } from 'react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { Trans, useTranslation } from 'react-i18next'

import { APIRequestState } from 'constants/APIState'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import { Endpoints } from 'constants/Endpoints'
import Loading from 'components/Loading/Loading'
import axios from 'axios'
import { fold } from 'fp-ts/Either'
import { getRemoteAPIURL } from 'lib/API'
import { pipe } from 'fp-ts/lib/function'
import platformURL from 'constants/platformURL'
import reporter from 'io-ts-reporters'
import supportEmail from 'constants/supportEmail'
import { useConfirmationQueryContext } from 'contexts/ConfirmationQueryContext'
import utilStyles from 'styles/utils.module.sass'

const ConfirmationPage: FC = ({
  children
}) => {
  const { t } = useTranslation('onoffice')
  const { confirmationQueryParams } = useConfirmationQueryContext()
  const [orderPlatformPersistResponse, setOrderPlatformPersistResponse] = useState<number | undefined>(undefined)
  const [orderPlatformPersistAPIRequestState, setOrderPlatformPersistAPIRequestState] = useState<APIRequestState>(APIRequestState.BEFORE_START)

  /** Persist the order on the platform */
  useEffect(() => {
    if (!confirmationQueryParams) return setOrderPlatformPersistAPIRequestState(APIRequestState.ERROR)

    const { transactionid, customerWebId, apiClaim, referenceid, userId, orderId } = confirmationQueryParams
    const body: BillingTransactionDTO = {
      transactionId: transactionid,
      customerWebId,
      apiClaim,
      referenceId: referenceid,
      userId,
      orderId,
    }

    const decodedBody = BillingTransactionDTO.decode(body)
    pipe(
      decodedBody,
      fold(
        () => {
          const report = reporter.report(decodedBody)
          setOrderPlatformPersistAPIRequestState(APIRequestState.ERROR)
          console.error(report)
          alert(report)
        },
        // Set state with validated query params
        async validatedBody => {
          try {
            setOrderPlatformPersistAPIRequestState(APIRequestState.RUNNING)
            const URL = getRemoteAPIURL(Endpoints.ORDER_PLATFORM_PERSIST)
            const response = await axios.post<IdentityDTO>(URL, validatedBody)

            /** Validate type of response data */
            const decodedResponseData = IdentityDTO.decode(response.data)
            pipe(
              decodedResponseData,
              fold(
                () => {
                  const report = reporter.report(decodedResponseData)
                  setOrderPlatformPersistAPIRequestState(APIRequestState.ERROR)
                  console.error(report)
                  alert(report)
                },
                // Set result
                validatedResponseData => {
                  setOrderPlatformPersistResponse(validatedResponseData.id)
                  setOrderPlatformPersistAPIRequestState(APIRequestState.COMPLETE)
                }
              )
            )
          } catch (error) {
            console.error(error)
            setOrderPlatformPersistAPIRequestState(APIRequestState.ERROR)
          }
        }
      )
    )
  }, [confirmationQueryParams])

  return (
    <Fragment>
      {orderPlatformPersistAPIRequestState === APIRequestState.BEFORE_START || orderPlatformPersistAPIRequestState === APIRequestState.RUNNING ?
        <Loading fullPage></Loading>
        :
        <Fragment>
          {orderPlatformPersistAPIRequestState === APIRequestState.ERROR ?
            // TODO: Error details
            <AlertMessage fullPage IconElement={FaTimesCircle}>
              <h1>{t('order_failed')}</h1>
            </AlertMessage>
            :
            <AlertMessage fullPage IconElement={FaCheckCircle}>
              <h1>{t('order_success', { orderId: orderPlatformPersistResponse || 'N/A' })}</h1>
              <p>
                <Trans t={t} i18nKey="onoffice:order_success_text" values={{ platformURL, supportEmail }}>
                  <a href={platformURL} rel="noopener noreferrer" target="_blank">&nbsp;</a>
                  <a href={`mailto:${supportEmail}`} rel="noopener noreferrer" target="_blank">&nbsp;</a>
                </Trans>
              </p>
              <div style={{ display: 'none' }} className={utilStyles.queryGrid}>
                {Object.entries(confirmationQueryParams || {}).map(([key, value]) => (
                  <Fragment key={key}>
                    <strong className={utilStyles.queryGridElement}>{key}</strong>
                    <span className={utilStyles.queryGridElement}>{value}</span>
                  </Fragment>
                ))}
                {children}
              </div>
            </AlertMessage>
          }
        </Fragment>
      }
    </Fragment>
  )
}

export default ConfirmationPage