/** Enum containing all endpoints as strings without the baseURL starting with "/" */
export enum Endpoints {
  /** Health check endpoint */
  'HELLO' = '/hello',

  /** Translations endpoint */
  'TRANSLATIONS_ALL' = '/translation/all',

  /** User profile fetch endpoint */
  'USER_PROFILE' = '/user/profile',

  /** Endpoint called upon the end of purchase flow to persist the order before payment is made */
  'ORDER_PERSIST' = '/order/persist',
  /** Endpoint called upon the end of purchase flow to persist the order after payment is made */
  'ORDER_PLATFORM_PERSIST' = '/order/platform/persist',
  /** Order configuration endpoint returning configuration for product(s) */
  'ORDER_CONFIGURATION_PRODUCT' = '/order/configuration/product',
  /** Order configuration endpoint returning configuration for instruction(s) */
  'ORDER_CONFIGURATION_INSTRUCTION' = '/order/configuration/instruction',
  /** Order configuration endpoint returning configuration for category */
  'ORDER_CONFIGURATION_CATEGORY' = '/order/configuration/category',
  /** Order configuration endpoint returning configuration for address */
  'ORDER_CONFIGURATION_ADDRESS' = '/order/configuration/address',
}