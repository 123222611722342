import AlertMessage from 'components/AlertMessage/AlertMessage'
import { useAddressSelectionContext } from 'contexts/AddressSelectionContext'
import { useInstructionSelectionContext } from 'contexts/InstructionSelectionContext'
import { useProductConfigurationContext } from 'contexts/ProductConfigurationContext'
import { useProductSelectionContext } from 'contexts/ProductSelectionContext'
import { useSummaryContext } from 'contexts/SummaryContext'
import { useUserContext } from 'contexts/UserContext'
import { bigFromFee } from 'lib/decimals'
import { valueAfterDiscount } from 'lib/priceCalculations'
import { formatPrice } from 'lib/priceFormatter'
import { getTimeDurationStringFromMinutesAndHours } from 'lib/timeUtils'
import React, { FC, Fragment, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ShootingCategory } from 'types/ProductCategory'
import { ProductOptionType } from 'types/ProductOptionType'
import { ProductSegment } from 'types/ProductSegment'
import styles from './Summary.module.sass'


/**
 * @component Component display a summary of the order
 * @example
 * <Summary />
 */
const Summary: FC<{
  /** The additional classes to append */
  className?: string
}> = ({
  className = '',
}) => {
    const { t } = useTranslation(['order', 'product', 'segment', 'segment_title', 'onoffice'])
    const { userProfile } = useUserContext()
    const { platformPlace, addressIsValid } = useAddressSelectionContext()
    const { currency, discount, vat } = useProductConfigurationContext()
    const { productSelectionList, productSelectionDuration, selectedProductSegmentKey } = useProductSelectionContext()
    const { instructionSelectionList, allRequiredInstructionsFilled } = useInstructionSelectionContext()
    const { totalBeforeDiscount, totalAfterDiscount, totalVat, totalAfterVat } = useSummaryContext()

    const filteredInstructionSelectionList = useMemo(() => instructionSelectionList.filter(instruction => !instruction.isAlwaysSelected), [instructionSelectionList])

    if (!userProfile) return (
      <AlertMessage fullPage>
        <h1>{t('onoffice:missing_user')}</h1>
      </AlertMessage>
    )

    if (!platformPlace || !addressIsValid) return <Fragment></Fragment>
    if (productSelectionList.length === 0) return <Fragment></Fragment>
    if (!allRequiredInstructionsFilled) return <Fragment></Fragment>

    return (
      <div className={`${styles.Summary} ${className}`.trim()}>
        <hr />
        <Fragment>
          <h2>4. {t('step_validation.title')}</h2>
          <p>{t('step_validation.text')}</p>
        </Fragment>
        <div className="cart">
          {productSelectionList.length > 0 &&
            <div className={styles.part}>
              <h3>{t('cart.product_list')}</h3>
              <ul className="cart-items">
                {productSelectionList.map(product => {
                  const productPriceAfterDiscount = valueAfterDiscount(product.feePrice, discount)
                  return (
                    <li className="item" key={product.id}>
                      <span className="title">{t(`product:p_${product.id}`)}</span>
                      <strong className="price">
                        {/* {'('} */}
                        {formatPrice(productPriceAfterDiscount, currency)}
                        {discount.gt(0) &&
                          <span className="original-price">{formatPrice(product.feePrice, currency)}</span>
                        }
                        {/* {')'} */}
                      </strong>
                      {product.options.length > 0 &&
                        <ul className="options">
                          {product.options.map(option => {
                            const optionPriceAfterDiscount = valueAfterDiscount(option.feePrice, discount)
                            return (
                              <Fragment key={option.id}>
                                {option.value > 0 &&
                                  <li className="option">
                                    <span className="option-title">{t(`product:p_${option.id}`)}</span>
                                    {option.value > 0 && option.type === ProductOptionType.BOOLEAN &&
                                      <strong className="option-price">
                                        {formatPrice(optionPriceAfterDiscount, currency)}
                                        {discount.gt(0) &&
                                          <span className="original-price">{formatPrice(option.feePrice, currency)}</span>
                                        }
                                      </strong>
                                    }
                                    {option.value <= 1 && option.type === ProductOptionType.INTEGER &&
                                      <strong className="option-price">
                                        {formatPrice(optionPriceAfterDiscount, currency)}
                                        {discount.gt(0) &&
                                          <span className="original-price">{formatPrice(option.feePrice, currency)}</span>
                                        }
                                      </strong>
                                    }
                                    {option.value > 1 && option.type === ProductOptionType.INTEGER &&
                                      <Fragment>
                                        {discount.gt(0) &&
                                          <strong className="original-option-price">{formatPrice(bigFromFee(option.feePrice).times(option.value), currency)} ({option.value} x {formatPrice(option.feePrice, currency)})</strong>
                                        }
                                        <strong className="option-price">{formatPrice(optionPriceAfterDiscount.times(option.value), currency)} ({option.value} x {formatPrice(optionPriceAfterDiscount, currency)})</strong>
                                      </Fragment>
                                    }
                                  </li>
                                }
                              </Fragment>
                            )
                          })}
                        </ul>
                      }
                    </li>
                  )
                })}
              </ul>
              {selectedProductSegmentKey !== ProductSegment.all &&
                <div className="info-block orange">
                  <span className="small">{t(`segment_title:${ShootingCategory.REAL_ESTATE}`)}</span>
                  <strong>
                    <Trans t={t} i18nKey={`segment:${selectedProductSegmentKey}`}>
                      <sup></sup>
                    </Trans>
                  </strong>
                </div>
              }
              {productSelectionDuration > 0 &&
                <div className="info-block blue">
                  <span className="small">{t('cart.duration')}</span>
                  <strong>{t('cart.maximum')} {getTimeDurationStringFromMinutesAndHours(productSelectionDuration)}</strong>
                </div>
              }
            </div>
          }
          {filteredInstructionSelectionList.length > 0 &&
            <div className={styles.part}>
              <h3>{t('cart.additional')}</h3>
              <ul className="cart-items">
                {filteredInstructionSelectionList.map(instructionOption => {
                  const instructionOptionPriceAfterDiscount = valueAfterDiscount(instructionOption.feePrice, discount)
                  return (
                    <li className="item" key={instructionOption.id}>
                      <span className="title">{t(`product:p_${instructionOption.id}`)}</span>
                      {bigFromFee(instructionOption.feePrice).gt(0) &&
                        <strong className="price">
                          {/* {'('} */}
                          {formatPrice(instructionOptionPriceAfterDiscount, currency)}
                          {discount.gt(0) &&
                            <span className="original-price">{formatPrice(instructionOption.feePrice, currency)}</span>
                          }
                          {/* {')'} */}
                        </strong>
                      }
                    </li>
                  )
                })}
              </ul>
            </div>
          }
          <div className={styles.part}>
            <h3>{t('cart.total')}</h3>
            {discount.gt(0) &&
              <strong className="total-before-discount">{formatPrice(totalBeforeDiscount, currency)}</strong>
            }
            <strong className="total">{formatPrice(totalAfterDiscount, currency)}</strong>
            <div className="vat">
              <span>{t('cart.vat')} ({formatPrice(vat)} %)</span>
              <strong>{formatPrice(totalVat, currency)}</strong>
              <span>{t('cart.after_vat')}</span>
              <strong>{formatPrice(totalAfterVat, currency)}</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default Summary